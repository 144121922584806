import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import hidePasswordIcon from "../../assets/icons/icon_eye_closed.svg";
import showPasswordIcon from "../../assets/icons/icon_eye_open.svg";
import auroraLogo from "../../assets/images/aurora_logo.svg";
import trustplusLogo from "../../assets/images/trustplus_logo.svg";
import { TOAST_TYPE } from "../../constants";
import { LOCAL_STORAGE_KEY_REMEMBER_ME } from "../../constants/storage";
import { URL_DASHBOARD, URL_FORGOT_PASSWORD } from "../../constants/url";
import { useAuthContext } from "../../context/Auth";
import { useToaster } from "../../context/Toaster";
import { ReactComponent as TrustplusLogo } from "../../assets/images/trustplus_logo_login.svg";
import "./login.css";
import { ERROR_MESSAGE_DEFAULT, ERROR_MESSAGE_MAPPING } from "../../constants/errors";

export default function Login() {
  const { handleLogin, isAuthenticated } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(
    () =>
      localStorage.getItem(LOCAL_STORAGE_KEY_REMEMBER_ME) === "true" || false
  );

  const { showToast, hideToast } = useToaster();
  const location = useLocation();

  if (isAuthenticated) {
    const params = new URLSearchParams(location.search);
    const redirectPath = params.get("redirect") || URL_DASHBOARD;
    return <Navigate to={redirectPath} />;
  }

  const togglePasswordVisibility = () =>
    setShowPassword((showPassword) => !showPassword);

  const handleSubmit = (event) => {
    event.preventDefault();
    hideToast()
    const formData = new FormData(event.target);
    const email = formData.get("email");
    const password = formData.get("password");

    localStorage.setItem(LOCAL_STORAGE_KEY_REMEMBER_ME, rememberMe);

    handleLogin(email, password, rememberMe).catch((err) => {
      console.error("login error:", err);
      const errorMessage = ERROR_MESSAGE_MAPPING[err.response?.data?.data?.detail] || ERROR_MESSAGE_DEFAULT;
      showToast(errorMessage, TOAST_TYPE.ERROR);
    });
  };

  return (
    <div className="login-container">
      {/* <div className="absolute top-4 left-4">
        <img src={trustplusLogo} alt="Login Logo" className="trust-logo" />
      </div> */}
      <div className="content-container">
        <div className="flex flex-col items-center text-center">
          {/* <img src={trustplusLogo} alt="Aurora Plus" className="aurora-logo" /> */}
          <TrustplusLogo height={44} />
          <div className="w-full mt-[2.5rem]">
            <span className="title">The Future of Finance, Powered by AI</span>
            {/* <p className="sub-title">
              To be at the forefront of fostering human-centric
              <br /> innovation, to lead the Future of Finance, Powered by Al
            </p> */}
            <form
              onSubmit={handleSubmit}
              className="text-left flex flex-col w-full gap-[1.5rem]"
            >
              <div className="input-container">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="input-container flex flex-col gap-[0.75rem]">
                <label htmlFor="password">Password</label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "type" : "password"}
                    autoComplete="current-password"
                    required
                    placeholder="Enter your password"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      {showPassword ? (
                        <img src={hidePasswordIcon} alt="hide password" />
                      ) : (
                        <img src={showPasswordIcon} alt="show password" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-[4px]">
                    <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(event) => setRememberMe(event.target.checked)}
                    />
                    <label htmlFor="remember_me" className="remember-me">
                      Remember me
                    </label>
                  </div>
                  <div className="text-sm">
                    <a href={URL_FORGOT_PASSWORD} className="forgot-password">
                      Forgot password?
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-between">
                <button type="submit" className="primary w-full">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
