import { createContext, useContext, useRef, useState } from "react";
import Toaster from "../../components/Toaster";
import { TOAST_TYPE } from "../../constants";

const ToasterContext = createContext({
  showToast: (_message, _type, _title, _timeout) => {},
  hideToast: () => {},
});

export const useToaster = () => useContext(ToasterContext);

export default function ToasterProvider({ children }) {
  const [toast, setToast] = useState(null);
  const toasterTimerRef = useRef()

  const showToast = (message, type = TOAST_TYPE.SUCCESS, title = '', timeout = 10000) => {
    setToast({ message, type, title });

    if (timeout) {
      toasterTimerRef.current = setTimeout(() => {
        setToast(null);
      }, timeout);
    }
  };

  const hideToast = () => {
    setToast(null)
    clearTimeout(toasterTimerRef.current)
  }

  return (
    <ToasterContext.Provider value={{ showToast, hideToast }}>
      {toast &&  <Toaster message={toast.message} type={toast.type} title={toast.title} onClose={hideToast} />}
      {children}{" "}
    </ToasterContext.Provider>
  );
}
