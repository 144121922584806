import {
  URL_APPLICATION_CREATE,
  URL_CLIENTS_SUMMARY_PORTFOLIO,
  URL_COMPANIES,
  URL_FINANCIAL_SPREADING,
  URL_MANAGE_MEMBERS,
  URL_TEAM_ACTIVITY,
  URL_USAGE_REPORT,
  URL_USERS,
} from "../../constants/url";

import barChartIcon from "../../assets/icons/icon_bar_chart.svg";
import briefcaseIcon from "../../assets/icons/icon_briefcase.svg";
import manageUserIcon from "../../assets/icons/icon_manage_user.svg";
import notesIcon from "../../assets/icons/icon_notes.svg";
import viewActivityIcon from "../../assets/icons/icon_view_activity.svg";

export const UNDERWRITER_CARDS = ({ navigate }) => [
  {
    title: "Create New Company",
    description: "Set up a new company account to upload financial statements.",
    icon: briefcaseIcon,
    onClick: () => navigate(URL_APPLICATION_CREATE),
  },
  {
    title: "Manage Existing Companies",
    description:
      "Add new financial statements, modify existing ones, or update account details.",
    icon: notesIcon,
    onClick: () => navigate(URL_COMPANIES),
  },
  {
    title: "View Your Activity",
    description:
      "Track how many financials and which companies you've processed.",
    icon: viewActivityIcon,
    onClick: () => navigate(URL_FINANCIAL_SPREADING),
  },
  {
    title: "Explore Your Portfolio",
    description:
      "Analyse your portfolio by industry, activity, watchlist and more.",
    icon: barChartIcon,
    onClick: () => navigate(URL_CLIENTS_SUMMARY_PORTFOLIO),
  },
];

export const MANAGER_CARDS = ({ navigate }) => [
  {
    title: "Create New Company",
    description: "Set up a new company account to upload financial statements.",
    icon: briefcaseIcon,
    onClick: () => navigate(URL_APPLICATION_CREATE),
  },
  {
    title: "Manage Existing Companies",
    description:
      "Add new financial statements, modify existing ones, or update account details.",
    icon: notesIcon,
    onClick: () => navigate(URL_COMPANIES),
  },
  {
    title: "View Your Activity",
    description:
      "Track how many financials and which companies you've processed.",
    icon: viewActivityIcon,
    onClick: () => navigate(URL_TEAM_ACTIVITY),
  },
  {
    title: "Explore Your Portfolio",
    description:
      "Analyse your portfolio by industry, activity, watchlist and more.",
    icon: barChartIcon,
    onClick: () => navigate(URL_CLIENTS_SUMMARY_PORTFOLIO),
  },
  {
    title: "Manage Users",
    description:
      "Create, edit or delete Manager and Underwriter user profiles.",
    icon: manageUserIcon,
    onClick: () => navigate(URL_MANAGE_MEMBERS),
  },
];

export const ADMIN_CARDS = ({ navigate }) => [
  {
    title: "Monitor Usage Costs",
    description: "Track expenses incurred by users.",
    icon: briefcaseIcon,
    onClick: () => navigate(URL_USAGE_REPORT),
  },
  {
    title: "Manage Users",
    description:
      "Create, edit or delete Manager and Underwriter user profiles.",
    icon: manageUserIcon,
    onClick: () => navigate(URL_USERS),
  },
];

export const greeting = () => {
  let greetingTime = "";

  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    greetingTime = "Good morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    greetingTime = "Good afternoon";
  } else {
    greetingTime = "Good evening";
  }
  return greetingTime;
};
