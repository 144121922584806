import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Modal from "../Modal";
import deleteConfirmImg from "../../assets/images/delete_confirm.png";
import { createEmptyObject } from "../../utils/object/emptyObject";

export default function CommonTable({
  rowsData,
  label,
  columns,
  confirmBox,
  minWidth,
  confirmTitle,
  handleEditRecord,
  isOverflow = true,
  titleClassname = "",
  decimalSigns = <></>,
  ...dataProps
}) {
  const [isConfirm, setIsConfirm] = useState(false);
  const [editingRows, setEditingRows] = useState({});
  const [rows, setRows] = useState(rowsData);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updatedRecord, setUpdatedRecord] = useState(null);

  useEffect(() => {
    setRows(rowsData);
  }, [rowsData]);

  const handleSetEditingRows = (id, allowEditName) => {
    setSelectedRecordId(id);
    setEditingRows((prev) => ({
      ...prev,
      [id]: allowEditName,
    }));
  };

  const handleCancelEditingRow = (id) => {
    setSelectedRecordId(null);

    setEditingRows((prev) => {
      return {
        ...prev,
        [id]: undefined,
      };
    });
  };

  const handleAddMoreColumn = (indexToAdd) => {
    const newItems = {
      ...createEmptyObject(rows?.[0]),
      id: uuidv4(),
    };
    setRows((prev) => {
      const newRows = [
        ...prev.slice(0, indexToAdd),
        newItems,
        ...prev.slice(indexToAdd),
      ];
      return newRows;
    });
    handleSetEditingRows(newItems.id, true);
  };

  const handleRemoveRecord = () => {
    handleCancelEditingRow(selectedRecordId);
    handleEditRecord({ isDeleteRecord: true, id: selectedRecordId });
    setRows((prev) => {
      // const newRows = [
      //   ...prev.slice(0, deleteIndex),
      //   ...prev.slice(deleteIndex + 1),
      // ];
      const newRows = prev.filter((item) => item.id !== selectedRecordId);
      return newRows;
    });
    setSelectedRecordId(null);
    setShowDeleteModal((prev) => !prev);
  };
  const handleRemoveRw = (idToRemove) => {
    setSelectedRecordId(idToRemove);
    setShowDeleteModal((prev) => !prev);
  };

  const handleEditRecordValue = (event) => {
    const selectedRecord = rows?.find((item) => item.id === selectedRecordId);
    let editedRecord = {};
    if (event?.value && event?.label) {
      editedRecord = {
        ...selectedRecord,
        ...updatedRecord,
        account_code: event.value,
        tp_standard_account_name: event.label,
      };
    } else {
      editedRecord = {
        ...selectedRecord,
        ...updatedRecord,
        [event.target.name]: event.target.value,
      };
    }
    setUpdatedRecord(editedRecord);
  };
  return (
    <>
      <div className="bg-white 2xl:p-[1.5rem] sm:p-[20px] border-[1px] border-solid rounded-[20px] flex flex-col">
        <div className="flex w-full justify-between items-center">
          <span
            className={`leading-[1.75rem] tracking-[-0.6px] font-[700] text-[1.125rem] text-[#121212] ${titleClassname}`}
          >
            {label}
          </span>
          {decimalSigns}
        </div>
        <div className={`${isOverflow ? "overflow-x-auto" : ""}`}>
          <table
            className="table-fixed"
            style={{
              minWidth: minWidth,
            }}
          >
            <thead className="bg-primary-50">
              <tr className="border-b-[1px] border-b-card-on-surface">
                {columns.map((item) => item.renderHeader())}
              </tr>
            </thead>
            <tbody>
              {rows.map((item, index) => (
                <>
                  {editingRows[item.id] !== undefined ? (
                    <tr>
                      {columns.map((column) =>
                        column.renderEditRow({
                          ...item,
                          handleAddMoreColumn,
                          handleCancelEditingRow,
                          handleRemoveRw,
                          editingRows,
                          index,
                          [column.key]: item[column.key],
                          handleSetEditingRows,
                          handleEditRecordValue,
                          handleEditRecord: () =>
                            handleEditRecord({ record: updatedRecord }),
                          ...dataProps,
                        })
                      )}
                    </tr>
                  ) : (
                    <tr>
                      {columns.map((column) =>
                        column.renderRow({
                          ...item,
                          handleAddMoreColumn,
                          handleCancelEditingRow,
                          handleRemoveRw,
                          editingRows,
                          index,
                          [column.key]: item[column.key],
                          handleSetEditingRows,
                          ...dataProps,
                        })
                      )}
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
          {confirmBox && (
            <table
              className="border-separate table-fixed border-[1px] border-card-on-surface rounded-[8px] border-spacing-0"
              style={{
                minWidth: minWidth,
              }}
            >
              <thead>{confirmBox.header}</thead>
              <tbody className="">
                {confirmBox.content({
                  onConfirm: () => {
                    setIsConfirm((prev) => !prev);
                  },
                  isConfirm,
                  confirmTitle,
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <Modal
          title={<>Delete Confirmation!</>}
          img={deleteConfirmImg}
          confirmText="Delete"
          cancelText="Cancel"
          onConfirm={handleRemoveRecord}
          onClose={() => handleRemoveRw(null)}
          additionalContent={
            <div className="text-[#525252] text-[1.125rem] leading-[1.75rem] text-center">
              Deleting this record will remove it from your list. This action
              cannot be undone.
            </div>
          }
        />
      )}
    </>
  );
}
