import ExcelJS from 'exceljs'
import { ALL_DOCUMENT_TYPES, DOCUMENT_TYPE_GENERATED, DOCUMENT_TYPE_UPLOADED, UNAUDITED_VALUE } from '../../constants'
import extractPeriodAndDate from '../string/extractPeriodAndDate'
import groupDataByDocumentType from '../formula/groupDataByDocumentType'
import mergeNumberFormat from '../excel/mergeNumberFormat'

export default function generateWorkbook({
  group_name,
  company_name,
  doc_currency,
  out_currency,
  exchange_rate,
  unit,
  data,
  fiscalPeriodOrderMap,
  decimalPlace,
}) {
  const companyInformationData = [
    [
      'Group Name', group_name,
    ],
    [
      'Company Name', company_name,
    ],
    [
      'Document Currency', doc_currency,
    ],
    [
      'Output Currency', out_currency,
    ],
    [
      'Exchange Rate', exchange_rate.toString(),
    ],
    [
      'Unit', unit,
    ]
  ]

  const DOCUMENT_DATA_MAP = data.reduce((documentDataMap, currentData) => {
    const { document_type } = currentData
    const currentDocumentData = documentDataMap[document_type] || []
    currentDocumentData.push(currentData)

    return {
      ...documentDataMap,
      [document_type]: currentDocumentData
    }

  }, {})

  const workbook = new ExcelJS.Workbook();
  ALL_DOCUMENT_TYPES.forEach(({ key, formula_type = key, text, hidePeriod }) => {
    const worksheet = workbook.addWorksheet(text, {
      views:[ {showGridLines:false}]
    });

    companyInformationData.forEach((companyInfo) => {
      const sheetRow = worksheet.addRow(companyInfo).getCell(1)
      sheetRow.font = { bold: true }
      sheetRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E8F4FF' }, 
      }
    })
    worksheet.addRow([])
    worksheet.addRow([])
  
    const displayedFiscalPeriodOrder = fiscalPeriodOrderMap[formula_type]

    const extractedFiscalPeriods = displayedFiscalPeriodOrder.map((periodString) => extractPeriodAndDate(periodString))

    const sheetDateRow = worksheet.addRow([
      text,
      ...extractedFiscalPeriods.map(
        ({ month, year }) => `${month} ${year}`
      ),
    ]);

    sheetDateRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E8F4FF' },
    }

    sheetDateRow.font = {
      bold: true,
    }

    sheetDateRow.eachCell({}, (cell, colNo) => {
      if (colNo > 1) {
        cell.alignment = {
          horizontal: 'right',
        };
      }
    })

    if (!hidePeriod) {
      const sheetPeriodRow = worksheet.addRow([
        '',
        ...extractedFiscalPeriods.map(
          ({ period }) => `${period} months`
        ),
      ])

      sheetPeriodRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E8F4FF' },
      }

      sheetPeriodRow.font = {
        bold: true,
      }

      sheetPeriodRow.eachCell({}, (cell, colNo) => {
        if (colNo > 1) {
          cell.alignment = {
            horizontal: 'right',
          };
        }
      })
    }
   
    const sheetAuditedRow = worksheet.addRow([
      "Standard Account Name",
      ...extractedFiscalPeriods.map(
        ({ audited = UNAUDITED_VALUE }) => audited
      ),
    ]);

    sheetAuditedRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E8F4FF' },
    }

    sheetAuditedRow.font = {
      bold: true,
    }

    sheetAuditedRow.eachCell({}, (cell, colNo) => {
      if (colNo > 1) {
        cell.alignment = {
          horizontal: 'right',
        };
      }
    })
  });

  DOCUMENT_TYPE_UPLOADED.forEach(({ key, formula_type = key, text }) => {
    const worksheet = workbook.getWorksheet(text);

    const documentData = DOCUMENT_DATA_MAP[formula_type] || []
    const displayedFiscalPeriodOrder = fiscalPeriodOrderMap[formula_type]

    documentData.forEach(({ tp_standard_account_name, amount, bold }) => {
      const sheetRow = worksheet.addRow([
        tp_standard_account_name,
        ...displayedFiscalPeriodOrder.map((periodString) => (amount[periodString] || 0) / exchange_rate)
      ])

      sheetRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (colNumber >= 2) {  
          let numberFormat = '#,##0'

          if (decimalPlace > 0) {
            numberFormat += `.${'0'.repeat(decimalPlace)}`
          }

          cell.numFmt = `${numberFormat};[Red](${numberFormat});"-"`;
        }

        if (bold) {
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },  // Black thin top border
          }
        }

        if (colNumber > 1) {
          cell.alignment = {
            horizontal: 'right',
          };
        }
      });
      
      if (bold) {
        sheetRow.font = {
          bold: true,
        }

        worksheet.addRow([])
      }
    })
  })

  // Generated document types, like key ratios, are grouped
  DOCUMENT_TYPE_GENERATED.forEach(({ key, formula_type = key, text }) => {
    const worksheet = workbook.getWorksheet(text);
    const documentData = DOCUMENT_DATA_MAP[formula_type] || []
    const displayedFiscalPeriodOrder = fiscalPeriodOrderMap[formula_type] || []

    const groupedDocumentData = groupDataByDocumentType(documentData, formula_type)

    groupedDocumentData.forEach(({ groupTitle, data }, index) => {
      if (index) {
        worksheet.addRow([])
      }

      worksheet.addRow([groupTitle]).font = {
        bold: true,
      }

      data.forEach(({ tp_standard_account_name, amount, formatTypes = [] }) => {
        const amountRow = worksheet.addRow([
          tp_standard_account_name,
          ...displayedFiscalPeriodOrder.map((periodString) => amount[periodString] || 0)
        ])

        const processedNumberFormat = mergeNumberFormat('', formatTypes)

        amountRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          if (colNumber >= 2) {  
            cell.numFmt = processedNumberFormat;
              cell.alignment = {
                horizontal: 'right',
              };
          }
        });
      })  
    })

  })

  // make all sheet's width fit to content
  ALL_DOCUMENT_TYPES.forEach(({ text }) => {
    const worksheet = workbook.getWorksheet(text);

    worksheet.columns.forEach((column, columnNo) => {
      if (columnNo > 0) {
        // set fixed width to 15 for amount columns
        column.width = 15
        return
      }

      // otherwise, set it to fit content
      let maxLength = 0;
      column.eachCell({ includeEmpty: false }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2; // Add some padding to the column width
    });
  })

  return workbook

}
