import { createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { CURRENCY_OPTIONS, DOCUMENT_TYPE_ENUM } from "../../constants";
import {
  API_URL_STANDARD_ACCOUNTS,
} from "../../constants/url";
import { FORMULA_GROUPING } from "../../constants/formula";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

const ApplicationContext = createContext({
  getStandardAccountOptions: (_documentType) => { },
  data: undefined,
  metadata: undefined,
  loadStandardAccountOptions: () => { },
  currencyOptions: [],
});

export const useApplicationContext = () => useContext(ApplicationContext);

// TODO: temporary implementation until spread management
export default function ApplicationProvider({ children }) {
  const [tpIsStandardAccountOptions, setTpIsStandardAccountOptions] = useState(
    []
  );
  const [tpCfStandardAccountOptions, setTpCfStandardAccountOptions] = useState(
    []
  );
  const [tpBsStandardAccountOptions, setTpBsStandardAccountOptions] = useState(
    []
  );
  const authenticatedFetch = useAuthenticatedFetch()
  const [data, setData] = useState([]);
  const [metadata, setMetadata] = useState({});

  const groupStandardAccountOption = (options = [], documentType) => {
    options.sort((a, b) => a.account_code.localeCompare(b.account_code))
    const groups = FORMULA_GROUPING[documentType] || {}

    if (!groups?.length) {
      return options
    }

    const groupedOptions = options.map((data) => {
      const { account_code } = data

      const group = groups.find(({ name, content }) => {
        const contentList = content.split(',')

        return contentList.some((range) => {
          const [startRange, endRange = startRange] = range.split('-')

          return account_code >= startRange && account_code <= endRange;
        })
      })

      return {
        ...data,
        groupName: group?.name,
      }
    })

    return groupedOptions
  }

  const fetchingIsTpStandardAccount = async () => {
    try {
      const res = await authenticatedFetch(`${API_URL_STANDARD_ACCOUNTS}/tp-is-standard-account-options`)

      const groupedStandardAccountOptions = groupStandardAccountOption(res.data.response, DOCUMENT_TYPE_ENUM.INCOME_STATEMENT)
      
      setTpIsStandardAccountOptions(groupedStandardAccountOptions);
    } catch (error) {
      console.error(
        "Error fetching Income Statement TP Standard Account options:",
        error
      );
    }
  };

  const fetchingBsTpStandardAccount = async () => {
    try {
      const res = await authenticatedFetch(`${API_URL_STANDARD_ACCOUNTS}/tp-bs-standard-account-options`)

      const groupedStandardAccountOptions = groupStandardAccountOption(res.data.response, DOCUMENT_TYPE_ENUM.BALANCE_SHEET)
      setTpBsStandardAccountOptions(groupedStandardAccountOptions);

    } catch (error) {
      console.error(
        "Error fetching Balance Sheet TP Standard Account options:",
        error
      );
    }
  };

  const fetchingCfTpStandardAccount = async () => {
    try {
      const res = await authenticatedFetch(`${API_URL_STANDARD_ACCOUNTS}/tp-cf-standard-account-options`)
      const groupedStandardAccountOptions = groupStandardAccountOption(res.data.response, DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT)
      setTpCfStandardAccountOptions(groupedStandardAccountOptions);
      
    } catch (error) {
      console.error(
        "Error fetching Cash Flow Statement TP Standard Account options:",
        error
      );
    }
  };

  const loadStandardAccountOptions = () => {
    fetchingIsTpStandardAccount();
    fetchingBsTpStandardAccount();
    fetchingCfTpStandardAccount();
  };

  useEffect(() => {
    loadStandardAccountOptions();
  }, []);

  const getStandardAccountOptions = (documentType) => {

    const tpStandardAccountOptionsMap = {
      [DOCUMENT_TYPE_ENUM.INCOME_STATEMENT]: tpIsStandardAccountOptions,
      [DOCUMENT_TYPE_ENUM.BALANCE_SHEET]: tpBsStandardAccountOptions,
      [DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT]: tpCfStandardAccountOptions,
    };
    return tpStandardAccountOptionsMap[documentType];
  };

  return (
    <ApplicationContext.Provider
      value={{
        getStandardAccountOptions,
        loadStandardAccountOptions,
        data,
        metadata,
        currencyOptions: CURRENCY_OPTIONS,
      }}
    >
      {children || <Outlet />}
    </ApplicationContext.Provider>
  );
}
