export default function formatNumber(value, decimalPlaces) {
  const isNotANumber = isNaN(Number(value));

  if (isNotANumber) {
    return value;
  }

  return new Intl.NumberFormat(navigator.language || "en-US", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(value);
}

export const shortenNumber = (num, decimalNo = 0) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(decimalNo) + "M"; // Convert to M for millions
  } else if (num >= 1000) {
    return (num / 1000).toFixed(decimalNo) + "k"; // Convert to k for thousands
  }
  return num.toString(); // Return the number as is if less than 1000
};
