export default function Checkbox({ title, className, isChecked, onChange }) {
  const handleCheckboxChange = (event) => {
    event.stopPropagation();
    onChange?.(event.target.checked);
  };
  return (
    <div className={`flex items-center gap-[4px] select-none ${className}`}>
      <label className="cursor-pointer flex items-center gap-[0.5rem]">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        {title}
      </label>
    </div>
  );
}
