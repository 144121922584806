export const DOCUMENT_TYPE_PROPERTY_GENERATED = "generated";
export const DOCUMENT_TYPE_PROPERTY_UPLOADED = "uploaded";

export const DOCUMENT_TYPE_ENUM = {
  INCOME_STATEMENT: "INCOME_STATEMENT",
  BALANCE_SHEET: "BALANCE_SHEET",
  CASH_FLOW_STATEMENT: "CASH_FLOW_STATEMENT",
  CASH_FLOW_GENERATED: "CASH_FLOW_GENERATED",
  KEY_RATIOS: "KEY_RATIOS",
};

export const DOCUMENT_TYPE_UPLOADED = [
  {
    key: DOCUMENT_TYPE_ENUM.INCOME_STATEMENT,
    type: DOCUMENT_TYPE_PROPERTY_UPLOADED,
    text: "Income Statement",
    required: true,
    isConfirmed: false,
  },
  {
    key: DOCUMENT_TYPE_ENUM.BALANCE_SHEET,
    type: DOCUMENT_TYPE_PROPERTY_UPLOADED,
    text: "Balance Sheet",
    hidePeriod: true,
    required: true,
    isConfirmed: false,
  },
  {
    key: DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT,
    text: "Cash Flow Statement",
    isConfirmed: false,
  },
];

export const DOCUMENT_TYPE_GENERATED = [
  // {
  //   key: DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT,
  //   type: "generated",
  //   formula_type: DOCUMENT_TYPE_ENUM.CASH_FLOW_GENERATED,
  //   text: "Cash Flow Statement (Generated)",
  // },
  {
    key: DOCUMENT_TYPE_ENUM.KEY_RATIOS,
    type: DOCUMENT_TYPE_PROPERTY_GENERATED,
    text: "Key Ratios",
  },
];

export const ALL_DOCUMENT_TYPES = [
  ...DOCUMENT_TYPE_UPLOADED,
  ...DOCUMENT_TYPE_GENERATED,
];

export const TOAST_TYPE = {
  ERROR: "error",
  SUCCESS: "success",
};

export const MONTHS_OPTIONS = [
  { value: 1, label: "Jan" },
  { value: 2, label: "Feb" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Apr" },
  { value: 5, label: "May" },
  { value: 6, label: "Jun" },
  { value: 7, label: "Jul" },
  { value: 8, label: "Aug" },
  { value: 9, label: "Sep" },
  { value: 10, label: "Oct" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dec" },
];

export const AUDITED_VALUE = "Audited";
export const UNAUDITED_VALUE = "Unaudited";

export const AUDITED_OPTIONS = [
  { value: "Audited", label: "Audited" },
  { value: "Unaudited", label: "Unaudited" },
];

export const PERIOD_MONTHS_OPTIONS = Array.from({ length: 12 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString(),
}));

export const CURRENCY_OPTIONS = [
  {"value": "USD", "label": "USD - United States Dollar"},
  {"value": "EUR", "label": "EUR - Euro"},
  {"value": "GBP", "label": "GBP - British Pound"},
  {"value": "AUD", "label": "AUD - Australian Dollar"},
  {"value": "AED", "label": "AED - United Arab Emirates Dirham"},
  {"value": "AFN", "label": "AFN - Afghan Afghani"},
  {"value": "ALL", "label": "ALL - Albanian Lek"},
  {"value": "AMD", "label": "AMD - Armenian Dram"},
  {"value": "ANG", "label": "ANG - Netherlands Antillean Guilder"},
  {"value": "AOA", "label": "AOA - Angolan Kwanza"},
  {"value": "ARS", "label": "ARS - Argentine Peso"},
  {"value": "AWG", "label": "AWG - Aruban Florin"},
  {"value": "AZN", "label": "AZN - Azerbaijani Manat"},
  {"value": "BAM", "label": "BAM - Bosnia-Herzegovina Convertible Mark"},
  {"value": "BBD", "label": "BBD - Barbadian Dollar"},
  {"value": "BDT", "label": "BDT - Bangladeshi Taka"},
  {"value": "BGN", "label": "BGN - Bulgarian Lev"},
  {"value": "BHD", "label": "BHD - Bahraini Dinar"},
  {"value": "BIF", "label": "BIF - Burundian Franc"},
  {"value": "BMD", "label": "BMD - Bermudian Dollar"},
  {"value": "BND", "label": "BND - Brunei Dollar"},
  {"value": "BOB", "label": "BOB - Bolivian Boliviano"},
  {"value": "BRL", "label": "BRL - Brazilian Real"},
  {"value": "BSD", "label": "BSD - Bahamian Dollar"},
  {"value": "BTN", "label": "BTN - Bhutanese Ngultrum"},
  {"value": "BWP", "label": "BWP - Botswana Pula"},
  {"value": "BYN", "label": "BYN - Belarusian Ruble"},
  {"value": "BZD", "label": "BZD - Belize Dollar"},
  {"value": "CAD", "label": "CAD - Canadian Dollar"},
  {"value": "CDF", "label": "CDF - Congolese Franc"},
  {"value": "CHF", "label": "CHF - Swiss Franc"},
  {"value": "CLP", "label": "CLP - Chilean Peso"},
  {"value": "CNY", "label": "CNY - Chinese Yuan"},
  {"value": "COP", "label": "COP - Colombian Peso"},
  {"value": "CRC", "label": "CRC - Costa Rican Colón"},
  {"value": "CUP", "label": "CUP - Cuban Peso"},
  {"value": "CVE", "label": "CVE - Cape Verdean Escudo"},
  {"value": "CZK", "label": "CZK - Czech Koruna"},
  {"value": "DJF", "label": "DJF - Djiboutian Franc"},
  {"value": "DKK", "label": "DKK - Danish Krone"},
  {"value": "DOP", "label": "DOP - Dominican Peso"},
  {"value": "DZD", "label": "DZD - Algerian Dinar"},
  {"value": "EGP", "label": "EGP - Egyptian Pound"},
  {"value": "ERN", "label": "ERN - Eritrean Nakfa"},
  {"value": "ETB", "label": "ETB - Ethiopian Birr"},
  {"value": "FJD", "label": "FJD - Fijian Dollar"},
  {"value": "FKP", "label": "FKP - Falkland Islands Pound"},
  {"value": "FOK", "label": "FOK - Faroese Króna"},
  {"value": "GEL", "label": "GEL - Georgian Lari"},
  {"value": "GGP", "label": "GGP - Guernsey Pound"},
  {"value": "GHS", "label": "GHS - Ghanaian Cedi"},
  {"value": "GIP", "label": "GIP - Gibraltar Pound"},
  {"value": "GMD", "label": "GMD - Gambian Dalasi"},
  {"value": "GNF", "label": "GNF - Guinean Franc"},
  {"value": "GTQ", "label": "GTQ - Guatemalan Quetzal"},
  {"value": "GYD", "label": "GYD - Guyanese Dollar"},
  {"value": "HKD", "label": "HKD - Hong Kong Dollar"},
  {"value": "HNL", "label": "HNL - Honduran Lempira"},
  {"value": "HRK", "label": "HRK - Croatian Kuna"},
  {"value": "HTG", "label": "HTG - Haitian Gourde"},
  {"value": "HUF", "label": "HUF - Hungarian Forint"},
  {"value": "IDR", "label": "IDR - Indonesian Rupiah"},
  {"value": "ILS", "label": "ILS - Israeli New Shekel"},
  {"value": "IMP", "label": "IMP - Isle of Man Pound"},
  {"value": "INR", "label": "INR - Indian Rupee"},
  {"value": "IQD", "label": "IQD - Iraqi Dinar"},
  {"value": "IRR", "label": "IRR - Iranian Rial"},
  {"value": "ISK", "label": "ISK - Icelandic Króna"},
  {"value": "JEP", "label": "JEP - Jersey Pound"},
  {"value": "JMD", "label": "JMD - Jamaican Dollar"},
  {"value": "JOD", "label": "JOD - Jordanian Dinar"},
  {"value": "JPY", "label": "JPY - Japanese Yen"},
  {"value": "KES", "label": "KES - Kenyan Shilling"},
  {"value": "KGS", "label": "KGS - Kyrgyzstani Som"},
  {"value": "KHR", "label": "KHR - Cambodian Riel"},
  {"value": "KID", "label": "KID - Kiribati Dollar"},
  {"value": "KMF", "label": "KMF - Comorian Franc"},
  {"value": "KRW", "label": "KRW - South Korean Won"},
  {"value": "KWD", "label": "KWD - Kuwaiti Dinar"},
  {"value": "KYD", "label": "KYD - Cayman Islands Dollar"},
  {"value": "KZT", "label": "KZT - Kazakhstani Tenge"},
  {"value": "LAK", "label": "LAK - Lao Kip"},
  {"value": "LBP", "label": "LBP - Lebanese Pound"},
  {"value": "LKR", "label": "LKR - Sri Lankan Rupee"},
  {"value": "LRD", "label": "LRD - Liberian Dollar"},
  {"value": "LSL", "label": "LSL - Lesotho Loti"},
  {"value": "LYD", "label": "LYD - Libyan Dinar"},
  {"value": "MAD", "label": "MAD - Moroccan Dirham"},
  {"value": "MDL", "label": "MDL - Moldovan Leu"},
  {"value": "MGA", "label": "MGA - Malagasy Ariary"},
  {"value": "MKD", "label": "MKD - Macedonian Denar"},
  {"value": "MMK", "label": "MMK - Myanmar Kyat"},
  {"value": "MNT", "label": "MNT - Mongolian Tugrik"},
  {"value": "MOP", "label": "MOP - Macanese Pataca"},
  {"value": "MRU", "label": "MRU - Mauritanian Ouguiya"},
  {"value": "MUR", "label": "MUR - Mauritian Rupee"},
  {"value": "MVR", "label": "MVR - Maldivian Rufiyaa"},
  {"value": "MWK", "label": "MWK - Malawian Kwacha"},
  {"value": "MXN", "label": "MXN - Mexican Peso"},
  {"value": "MYR", "label": "MYR - Malaysian Ringgit"},
  {"value": "MZN", "label": "MZN - Mozambican Metical"},
  {"value": "NAD", "label": "NAD - Namibian Dollar"},
  {"value": "NGN", "label": "NGN - Nigerian Naira"},
  {"value": "NIO", "label": "NIO - Nicaraguan Córdoba"},
  {"value": "NOK", "label": "NOK - Norwegian Krone"},
  {"value": "NPR", "label": "NPR - Nepalese Rupee"},
  {"value": "NZD", "label": "NZD - New Zealand Dollar"},
  {"value": "OMR", "label": "OMR - Omani Rial"},
  {"value": "PAB", "label": "PAB - Panamanian Balboa"},
  {"value": "PEN", "label": "PEN - Peruvian Sol"},
  {"value": "PGK", "label": "PGK - Papua New Guinean Kina"},
  {"value": "PHP", "label": "PHP - Philippine Peso"},
  {"value": "PKR", "label": "PKR - Pakistani Rupee"},
  {"value": "PLN", "label": "PLN - Polish Złoty"},
  {"value": "PYG", "label": "PYG - Paraguayan Guaraní"},
  {"value": "QAR", "label": "QAR - Qatari Riyal"},
  {"value": "RON", "label": "RON - Romanian Leu"},
  {"value": "RSD", "label": "RSD - Serbian Dinar"},
  {"value": "RUB", "label": "RUB - Russian Ruble"},
  {"value": "RWF", "label": "RWF - Rwandan Franc"},
  {"value": "SAR", "label": "SAR - Saudi Riyal"},
  {"value": "SBD", "label": "SBD - Solomon Islands Dollar"},
  {"value": "SCR", "label": "SCR - Seychellois Rupee"},
  {"value": "SDG", "label": "SDG - Sudanese Pound"},
  {"value": "SEK", "label": "SEK - Swedish Krona"},
  {"value": "SGD", "label": "SGD - Singapore Dollar"},
  {"value": "SHP", "label": "SHP - Saint Helena Pound"},
  {"value": "SLL", "label": "SLL - Sierra Leonean Leone"},
  {"value": "SOS", "label": "SOS - Somali Shilling"},
  {"value": "SRD", "label": "SRD - Surinamese Dollar"},
  {"value": "SSP", "label": "SSP - South Sudanese Pound"},
  {"value": "STN", "label": "STN - São Tomé and Príncipe Dobra"},
  {"value": "SYP", "label": "SYP - Syrian Pound"},
  {"value": "SZL", "label": "SZL - Eswatini Lilangeni"},
  {"value": "THB", "label": "THB - Thai Baht"},
  {"value": "TJS", "label": "TJS - Tajikistani Somoni"},
  {"value": "TMT", "label": "TMT - Turkmenistani Manat"},
  {"value": "TND", "label": "TND - Tunisian Dinar"},
  {"value": "TOP", "label": "TOP - Tongan Paʻanga"},
  {"value": "TRY", "label": "TRY - Turkish Lira"},
  {"value": "TTD", "label": "TTD - Trinidad and Tobago Dollar"},
  {"value": "TVD", "label": "TVD - Tuvaluan Dollar"},
  {"value": "TWD", "label": "TWD - New Taiwan Dollar"},
  {"value": "TZS", "label": "TZS - Tanzanian Shilling"},
  {"value": "UAH", "label": "UAH - Ukrainian Hryvnia"},
  {"value": "UGX", "label": "UGX - Ugandan Shilling"},
  {"value": "UYU", "label": "UYU - Uruguayan Peso"},
  {"value": "UZS", "label": "UZS - Uzbekistani Som"},
  {"value": "VES", "label": "VES - Venezuelan Bolívar"},
  {"value": "VND", "label": "VND - Vietnamese Đồng"},
  {"value": "VUV", "label": "VUV - Vanuatu Vatu"},
  {"value": "WST", "label": "WST - Samoan Tālā"},
  {"value": "XAF", "label": "XAF - Central African CFA Franc"},
  {"value": "XCD", "label": "XCD - East Caribbean Dollar"},
  {"value": "XOF", "label": "XOF - West African CFA Franc"},
  {"value": "XPF", "label": "XPF - CFP Franc"},
  {"value": "YER", "label": "YER - Yemeni Rial"},
  {"value": "ZAR", "label": "ZAR - South African Rand"},
  {"value": "ZMW", "label": "ZMW - Zambian Kwacha"},
  {"value": "ZWL", "label": "ZWL - Zimbabwean Dollar"}
];

export const UNIT_TYPES = {
  Billions: "Billions",
  Millions: "Millions",
  Thousands: "Thousands",
};

export const UNIT_TYPE_NUMBERS_MAP = {
  [UNIT_TYPES.Billions]: 1000000000,
  [UNIT_TYPES.Millions]: 1000000,
  [UNIT_TYPES.Thousands]: 1000,
};

export const UNIT_OPTIONS = [
  {
    value: UNIT_TYPES.Billions,
    label: "Billions",
  },
  {
    value: UNIT_TYPES.Millions,
    label: "Millions",
  },
  {
    value: UNIT_TYPES.Thousands,
    label: "Thousands",
  },
  {
    value: "-",
    label: "-",
  },
];

export const MODE = {
  VIEW: "view",
  EDIT: "edit",
};

export const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

export const ROLES = {
  UNDERWRITER: "UNDERWRITER",
  MANAGER: "MANAGER",
  ADMIN: "ADMIN",
};

export const CHART_DURATION = {
  MONTH: "MONTH",
  WEEK: "WEEK",
};

export const ONE_MB = 1024 * 1024

export const MAX_DOCUMENT_UPLOAD_FILE_SIZE = 15 * ONE_MB

export const VALIDATE_PAGE_FIELDS = {
  client_account_name: "client_account_name",
  tp_standard_account_name: "tp_standard_account_name",
  amount: "amount",
  account_code: "account_code",
};