import { useEffect, useRef, useState } from "react";

export const DEFAULT_DELAY_TIME = 300;
export const useDebounce = (value = "", delay = DEFAULT_DELAY_TIME) => {
  const [debouncedValue, setDebouncedValue] = useState("");
  const timerRef = useRef();

  useEffect(() => {
    if (timerRef) {
      timerRef.current = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};
