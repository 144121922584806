import { createContext, useContext, useEffect, useState } from "react"
import Loader from "../../components/Loader"

const LoaderContext = createContext({
  setLoading: (_isLoading) => { },
  isLoading: false,
  loaderContent: <></>,
  setLoaderContent: (_loaderContent) => { }
})

export const useLoader = () => useContext(LoaderContext)

export default function LoaderProvider({ children }) {
  const [isLoading, setLoading] = useState(false)
  const [loaderContent, setLoaderContent] = useState(<></>)

  useEffect(() => {
    if (!isLoading) {
      setLoaderContent(<></>)
    }
  }, [isLoading])

  return (
    <LoaderContext.Provider value={{ setLoading, isLoading, loaderContent, setLoaderContent }}>{children}<Loader isLoading={isLoading} loaderContent={loaderContent} /></LoaderContext.Provider>
  )
}