import React from "react";
import accessDeniedImg from "../../assets/images/access_denied.png";
import { URL_DASHBOARD } from "../../constants/url";

const AccessDeniedPage = () => {
  return (
    <div
      className="w-full max-w-default 2xl:px-16 sm:px-[48px] relative flex flex-col mb-[2rem]"
      style={{ height: "calc(100vh - 70px)" }}
    >
      <div className="w-full h-full absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] p-[2rem] flex flex-col justify-start items-center">
        <img
          src={accessDeniedImg}
          className="w-[440px] h-[440px]"
          alt="Access Denied"
        />
        <span className="text-[2rem] font-[700] leading-[2.5rem] tracking-[-1.76px] text-primary-strong mb-[1.25rem]">
          You've Reached a Restricted Area
        </span>
        <div className="flex justify-center items-center">
          <span class="block text-neutral-medium text-[1.25rem] font-[500] leading-[2rem] tracking-[-0.72px] text-center w-[50%]">
            We're sorry for the inconvenience, but you currently do not have
            access to this page. If you believe this is a mistake, please
            contact us for further assistance. Alternatively, return to the
            homepage to keep exploring.
          </span>
        </div>
        <a href={URL_DASHBOARD}>
          <button className="primary w-[278px] mt-[44px]">
            Go to Homepage
          </button>
        </a>
      </div>
    </div>
  );
};

export default AccessDeniedPage;
