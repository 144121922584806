import { Link, useNavigate } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import auroraLogo from "../../assets/images/aurora_logo.svg";
import trustplusLogo from "../../assets/images/trustplus_logo.svg";
import Dropdowns from "../../components/Dropdowns";
import Textarea from "../../components/Textarea";
import TextInput from "../../components/TextInput";
import { useState } from "react";
import { API_CONTACT_US, URL_DASHBOARD } from "../../constants/url";
import { useToaster } from "../../context/Toaster";
import { TOAST_TYPE } from "../../constants";
import { useLoader } from "../../context/Loader";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

export const CONTACT_US_FIELDS = {
  USER_NAME: "user_name",
  USER_EMAIL: "user_email",
  SUBJECT_CATEGORY: "subject_category",
  SUBJECT: "subject",
  MESSAGE: "message",
};

export default function ContactUs({ fromErrorPage }) {
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const { showToast } = useToaster();
  const [isSubmit, setIsSubmit] = useState(false);
  const fetch = useAuthenticatedFetch()

  const initialValues = {
    [CONTACT_US_FIELDS.USER_NAME]: "",
    [CONTACT_US_FIELDS.USER_EMAIL]: "",
    [CONTACT_US_FIELDS.SUBJECT_CATEGORY]: "",
    [CONTACT_US_FIELDS.SUBJECT]: "",
    [CONTACT_US_FIELDS.MESSAGE]: "",
  };

  const [inputValues, setInputValues] = useState(initialValues);

  const handleChange = (event) => {
    setInputValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleContactUs = async () => {
    setIsSubmit(true);
    if (Object.values(inputValues).some((value) => !value)) {
      return;
    }
    setLoading(true);
    return fetch(API_CONTACT_US, {
      data: inputValues,
      method: 'POST',
    })
      .then(() => {
        showToast('Contact us form successfully submitted', TOAST_TYPE.SUCCESS);
      })
      .catch((err) => {
        showToast(err.message, TOAST_TYPE.ERROR);
      })
      .finally(() => {
        setInputValues(initialValues);
        setLoading(false);
        setIsSubmit(false);
      });
  };

  return (
    <div className="w-full max-w-default 2xl:px-16 sm:px-[48px] pb-16 relative min-h-[100vh] flex flex-col">
      {fromErrorPage ? (
        <img
          src={trustplusLogo}
          alt=""
          className="w-[215px] h-[32px] mt-[52px]"
        />
      ) : (
        <div className="py-[2.5rem] flex gap-[1rem]">
          <img
            src={backIcon}
            alt="back-button"
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-gradient-aurora-blue  text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
            Contact Us
          </span>
        </div>
      )}
      <div className="flex flex-col justify-center items-center gap-[52px] w-full">
        {fromErrorPage && (
          <img
            src={auroraLogo}
            alt=""
            className="w-[300x] h-[83px] pb-24px mt-[48px]"
          />
        )}
        <div className="w-full max-w-[582px] p-[2rem] rounded-[20px] bg-white flex flex-col gap-[1.5rem]">
          {fromErrorPage && (
            <div>
              <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
                Contact Us
              </span>
            </div>
          )}
          <TextInput
            label="User Name"
            placeholder="Enter user name"
            name={CONTACT_US_FIELDS.USER_NAME}
            onChange={handleChange}
            value={inputValues[CONTACT_US_FIELDS.USER_NAME]}
            required
            isSubmit={isSubmit}
          />
          <TextInput
            label="User Email"
            type="email"
            placeholder="Enter user email"
            name={CONTACT_US_FIELDS.USER_EMAIL}
            onChange={handleChange}
            value={inputValues[CONTACT_US_FIELDS.USER_EMAIL]}
            required
            isSubmit={isSubmit}
          />
          <Dropdowns
            label="Subject Category"
            data={[
              {
                label: "Technical Support",
                value: "Technical Support",
              },
              {
                label: "Feedback and Suggestions",
                value: "Feedback and Suggestions",
              },
            ]}
            placeholder="Select from categories"
            name={CONTACT_US_FIELDS.SUBJECT_CATEGORY}
            onChange={(event) =>
              setInputValues((prev) => ({
                ...prev,
                [CONTACT_US_FIELDS.SUBJECT_CATEGORY]: event,
              }))
            }
            allowCustomText
            value={inputValues[CONTACT_US_FIELDS.SUBJECT_CATEGORY]}
            required
            isSubmit={isSubmit}
          />
          <TextInput
            label="Subject"
            placeholder="How can we help you today?"
            name={CONTACT_US_FIELDS.SUBJECT}
            onChange={handleChange}
            value={inputValues[CONTACT_US_FIELDS.SUBJECT]}
            required
            isSubmit={isSubmit}
          />
          <Textarea
            label="Your Message"
            placeholder="Feel free to explain in detail"
            className="flex flex-col"
            rows="4"
            name={CONTACT_US_FIELDS.MESSAGE}
            onChange={handleChange}
            value={inputValues[CONTACT_US_FIELDS.MESSAGE]}
            required
            isSubmit={isSubmit}
          />
          <button
            type="submit"
            className="primary w-full mt-[20px]"
            onClick={handleContactUs}
          >
            Submit
          </button>
          {fromErrorPage && (
            <Link to={URL_DASHBOARD}>
              <button className="w-full text-[1rem] leading-[1.5rem] tracking-[-0.48px] font-[600] text-neutral-strong">
                Back to Homepage
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
