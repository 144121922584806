import PropTypes from "prop-types";
import React, { useState } from "react";
import errorIcon from "../../assets/images/error.svg";

TextInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  referralOptions: PropTypes.arrayOf(PropTypes.string),
  isSubmit: PropTypes.string,
};

export default function TextInput({
  label,
  className,
  errorMessage,
  referralOptions = [],
  isSubmit = false,
  ...props
}) {
  const { id, name = id } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className={`${className} relative`}>
      {label && (
        <label
          htmlFor={id}
          className={`pb-[4px] text-[0.875rem] font-medium ${
            props?.disabled 
              ? "text-[#D1D1D1]"
              : "text-[#121212]"
          }`}
        >
          {label}
        </label>
      )}
      <input
        {...props}
        name={name}
        autoComplete="off"
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`text-[#121212] text-[0.875rem] font-[600] leading-[1.5rem] tracking-[-0.32px] placeholder:font-[400] ${
          props.disabled ? "placeholder:text-[#D1D1D1]" : ""
        }`}
        style={{
          borderBottom: errorMessage
            ? "1.5px solid var(--color-border-error-default)"
            : "none",
        }}
      />
      {(errorMessage || (props?.required && isSubmit && !props?.value)) && (
        <div className="flex gap-[4px] pt-[4px] absolute">
          <img src={errorIcon} alt="Error" />
          <span
            style={{
              color: "var(--color-border-error-default)",
              fontSize: "0.75rem",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1rem",
            }}
          >
            {errorMessage ?? "Please fill up this field"}
          </span>
        </div>
      )}

      {isFocused && referralOptions.length > 0 && (
        <div
          className="absolute w-full z-10 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-[0.5rem] max-h-[200px] overflow-y-auto" role="none">
            <span className="px-[0.75rem] py-[0.5rem] font-[600] text-[0.75rem] leading-[1rem] text-neutral-light">
              Existing Companies in this Group
            </span>
            {referralOptions.map((item) => (
              <span
                className="block px-[0.75rem] py-[0.5rem] text-[0.875rem] font-[500] leading-[1.25rem] tracking-[-0.28px] hover:bg-neutral-flat-hover"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
                key={item}
              >
                {item}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
