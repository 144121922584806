import axios from "axios";

export default async function fetch(url, options = {}) {
  try {
    // Axios request
    const response = await axios({
      url,
      withCredentials: true,
      ...options,
    });

    // Returning data if the request is successful
    return response.data;
  } catch (error) {
    throw error
  }
}
