import { Outlet } from "react-router-dom";
import { useAuthContext } from "../../context/Auth";
import AccessDeniedPage from "../../pages/AccessDenied";

export default function ProtectedRoute({ allowedRoles = [] }) {
  const { user } = useAuthContext();

  if (!user || !allowedRoles.includes(user.role)) {
    return <AccessDeniedPage />;
  }

  return <Outlet />;
}
