import SortIconTable from "../../components/SortIconTable";

export const mockData = [
  {
    id: 1,
    group_name: "HBLAB",
    company_name: "HB Global",
    fiscal_period: "July 2024",
    updated_at: "25 July 2024",
    created_by: "Devon LaneDevon LaneDevon LaneDevon Lane",
  },
  {
    id: 2,
    group_name: "HBLAB",
    company_name: "Test HB Global",
    fiscal_period: "July 2024",
    updated_at: "25 July 2024",
    created_by: "Devon Lane",
  },
];

export const RECENT_ACTIVITY_COLS = {
  GROUP_NAME: "group_name",
  COMPANY_NAME: "company_name",
  FISCAL_PERIOD: "fiscal_period",
  UPDATED_AT: "updated_at",
  CREATED_BY: "created_by",
};

export const recentActivityCols = {
  minWidth: 140 * 5,
  columns: ({ handleSort, isAscCols }) => [
    {
      key: "group_name",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 rounded-tl-[8px] max-w-[228px]"
          onClick={() => handleSort(RECENT_ACTIVITY_COLS.GROUP_NAME)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center border-r-card-on-surface">
            Group name
            <SortIconTable isAsc={isAscCols[RECENT_ACTIVITY_COLS.GROUP_NAME]} />
          </div>
        </th>
      ),
      renderRow: ({ group_name }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[228px] z-20">
          <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] border-r-card-on-surface">
            {group_name}
          </div>
        </td>
      ),
    },
    {
      key: "company_name",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 max-w-[228px]"
          onClick={() => handleSort(RECENT_ACTIVITY_COLS.COMPANY_NAME)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center border-r-card-on-surface">
            Company name
            <SortIconTable
              isAsc={isAscCols[RECENT_ACTIVITY_COLS.COMPANY_NAME]}
            />
          </div>
        </th>
      ),
      renderRow: ({ company_name }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[228px] z-20">
          <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] border-r-card-on-surface">
            {company_name}
          </div>
        </td>
      ),
    },
    {
      key: "fiscal_period",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 max-w-[228px]"
          onClick={() => handleSort(RECENT_ACTIVITY_COLS.FISCAL_PERIOD)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center border-r-card-on-surface">
            Fiscal Period
            <SortIconTable
              isAsc={isAscCols[RECENT_ACTIVITY_COLS.FISCAL_PERIOD]}
            />
          </div>
        </th>
      ),
      renderRow: ({ fiscal_period }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[228px] z-20">
          <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] border-r-card-on-surface">
            {fiscal_period}
          </div>
        </td>
      ),
    },
    {
      key: "updated_at",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 max-w-[228px]"
          onClick={() => handleSort(RECENT_ACTIVITY_COLS.UPDATED_AT)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center w-full border-r-card-on-surface">
            Date modified
            <SortIconTable isAsc={isAscCols[RECENT_ACTIVITY_COLS.UPDATED_AT]} />
          </div>
        </th>
      ),
      renderRow: ({ updated_at }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[228px] z-20">
          <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-card-on-surface">
            {updated_at}
          </div>
        </td>
      ),
    },
    {
      key: "created_by",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 rounded-tr-[8px] max-w-[228px]"
          onClick={() => handleSort(RECENT_ACTIVITY_COLS.CREATED_BY)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center w-full border-r-card-on-surface">
            Created by
          </div>
        </th>
      ),
      renderRow: ({ created_by }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[228px] z-20">
          <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-card-on-surface">
            {created_by}
          </div>
        </td>
      ),
    },
  ],
};
