import iconSearch from "../../assets/icons/icon_search.svg";

const SearchInput = ({ containerClassname, onChange }) => {
  return (
    <form className={`mx-auto ${containerClassname}`}>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <img src={iconSearch} alt="search" />
        </div>
        <input
          type="search"
          id="default-search"
          className="pl-[48px] text-[1rem] font-[400] leading-[1.5rem] tracking-[-0.32px]"
          placeholder="Search..."
          required
          onChange={onChange}
        />
      </div>
    </form>
  );
};

export default SearchInput;
