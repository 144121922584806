import { DOCUMENT_TYPE_UPLOADED } from "../../constants";
import { FORMULAS_MAP } from "../../constants/formula";
import calculateFormulaFromData from "./calculate_formula_from_data";

export default function calculateUploadedDocuments(
  metadata,
  data,
) {
  const calculatedDocumentTypesArr = DOCUMENT_TYPE_UPLOADED.filter(
    ({ key }) => metadata[key]
  );
  const calculatedFormulas = calculatedDocumentTypesArr.reduce(
    (fin, { key, formula_type = key }) => {
      const currFormula = FORMULAS_MAP[formula_type].map((data) => ({
        ...data,
        document_type: formula_type,
      }));
      return [...fin, ...currFormula];
    },
    []
  );

  const baseCalculateMetadata = calculatedDocumentTypesArr.reduce(
    (existingMetadata, { key, formula_type = key }) => {
      return {
        ...existingMetadata,
        [formula_type]: metadata[key],
      };
    },
    {
      isOldestYear: false,
    }
  );

  const result = calculateFormulaFromData(
    data,
    calculatedFormulas,
    baseCalculateMetadata
  );

  result.sort((a, b) =>
    a.account_code.localeCompare(b.account_code, undefined, { numeric: true })
  );
  return result;
};
