import { DOCUMENT_TYPE_ENUM } from "../constants";
import extractPeriodAndDate from "../utils/string/extractPeriodAndDate";
import generatePeriodString from "../utils/string/generatePeriodString";
import { FORMAT_NUMBER_TYPE } from "./number";

export const FORMULA_CALCULATE_OPERATIONS = {
  ADD: "add",
  SUBSTRACT: "substract",
  DIVIDE: "divide",
  MULTIPLY: "multiply",
  PRINT: "print",
};

export const ACCOUNT_CODE_CONSTANT = "CONSTANT";

export const INCOME_STATEMENT_SUBGROUP_FORMULA = [
  {
    bold: true,
    operations: [
      { accountCode: "10.1.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      {
        accountCode: "10.2.00",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "10.3.00",
    tp_standard_account_name: "Gross Profit",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.3.10-10.3.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "10.3.99",
    tp_standard_account_name: "Total Operating Expenses",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.3.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.01", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      {
        accountCode: "10.3.99",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "10.4.00",
    tp_standard_account_name: "Net Operating Profit",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.4.10-10.4.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "10.4.99",
    tp_standard_account_name: "Total Net Interest Income (Expense)",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.5.10-10.5.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "10.5.99",
    tp_standard_account_name: "Total Other Income (Expense)",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.4.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.4.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.5.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "10.6.00",
    tp_standard_account_name: "Profit (Loss) Before Tax",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.6.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      {
        accountCode: "10.6.10",
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "10.7.00",
    tp_standard_account_name: "Net Profit (Loss)",
  },
];

export const BALANCE_SHEET_SUBGROUP_FORMULA = [
  {
    bold: true,
    operations: [
      { accountCode: "20.1.10-20.1.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.1.99",
    tp_standard_account_name: "Total Current Assets",
  },
  {
    bold: true,
    operations: [
      { accountCode: "20.2.10-20.2.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.2.99",
    tp_standard_account_name: "Total Non-Current Assets",
  },
  {
    bold: true,
    operations: [
      { accountCode: "20.1.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.3.00",
    tp_standard_account_name: "Total Assets",
  },
  {
    bold: true,
    operations: [
      { accountCode: "20.4.10-20.4.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.4.99",
    tp_standard_account_name: "Total Current Liabilities",
  },
  {
    bold: true,
    operations: [
      { accountCode: "20.5.10-20.5.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.5.99",
    tp_standard_account_name: "Total Non-Current Liabilities",
  },
  {
    bold: true,
    operations: [
      { accountCode: "20.4.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.6.00",
    tp_standard_account_name: "Total Liabilities",
  },
  {
    bold: true,
    operations: [
      { accountCode: "20.7.10-20.7.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.7.99",
    tp_standard_account_name: "Total Equity",
  },
];

export const CASH_FLOW_SUBGROUP_FORMULA = [
  {
    bold: true,
    operations: [
      { accountCode: "30.2.10-30.2.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.2.99",
    tp_standard_account_name:
      "Adjustment For Non-Cash Items and Financing And Investing Activities",
  },
  {
    bold: true,
    operations: [
      { accountCode: "30.3.10-30.3.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.3.99",
    tp_standard_account_name:
      "Changes in Operating-Related Assets and Liabilities",
  },
  {
    bold: true,
    operations: [
      { accountCode: "30.1.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.1.01", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.4.99",
    tp_standard_account_name:
      "Net Cash Flow From Operating Activities",
  },
  {
    bold: true,
    operations: [
      { accountCode: "30.5.10-30.5.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.5.99",
    tp_standard_account_name: "Net Cash Flow From Investing Activities",
  },
  {
    bold: true,
    operations: [
      { accountCode: "30.6.10-30.6.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.6.99",
    tp_standard_account_name: "Net Cash Flow From Financing Activities",
  },
  {
    bold: true,
    operations: [
      { accountCode: "30.4.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.7.00",
    tp_standard_account_name:
      "Net Increase / (Decrease) In Cash and Cash Equivalents",
  },
  {
    bold: true,
    operations: [
      { accountCode: "30.7.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.7.10-30.7.98", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.8.00",
    tp_standard_account_name: "Cash and Cash Equivalents at End of Year",
  },
];

export const CASH_FLOW_GENERATED_SUBGROUP_FORMULA = [
  {
    bold: true,
    operations: [
      { accountCode: "10.7.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.1.00",
    tp_standard_account_name: "NET PROFIT (LOSS)",
  },
  {
    operations: [
      { accountCode: "10.3.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.2.10",
    tp_standard_account_name: "Depreciation and Amortisation Expenses",
  },
  {
    operations: [
      { accountCode: "10.3.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.2.11",
    tp_standard_account_name: "Impairment Loss",
  },
  {
    operations: [
      {
        accountCode: "10.5.10",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.2.12",
    tp_standard_account_name: "Share of losses (profits) from Associates",
  },
  {
    operations: [
      {
        accountCode: "10.5.11",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.2.13",
    tp_standard_account_name: "Dividend Income",
  },
  {
    operations: [
      {
        accountCode: "10.5.13",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.2.14",
    tp_standard_account_name: "Loss (Gain) on Disposal of Fixed Assets",
  },
  {
    operations: [
      {
        accountCode: "10.5.14",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.2.15",
    tp_standard_account_name: "Loss (Gain) on Investments",
  },
  {
    bold: true,
    operations: [
      { accountCode: "40.2.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.2.99",
    tp_standard_account_name:
      "ADJUSTMENT FOR NON-CASH ITEMS AND FINANCING AND INVESTING ACTIVITIES",
  },
  {
    operations: [
      {
        accountCode: "20.1.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.10",
    tp_standard_account_name: "Change in Trade Receivables",
  },
  {
    operations: [
      {
        accountCode: "20.1.14",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.11",
    tp_standard_account_name: "Change in Other Receivables",
  },
  {
    operations: [
      {
        accountCode: "20.1.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.12",
    tp_standard_account_name: "Change in Inventories",
  },
  {
    operations: [
      {
        accountCode: "20.1.18",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.25",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.25",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.13",
    tp_standard_account_name: "Change in Prepayments",
  },
  {
    operations: [
      {
        accountCode: "20.1.16,20.1.17,20.1.22,20.1.90,20.2.26,20.2.90",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.16,20.1.17,20.1.22,20.1.90,20.2.26,20.2.90",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.14",
    tp_standard_account_name: "Change in Other Operating Assets",
  },
  {
    operations: [
      {
        accountCode: "20.4.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.3.15",
    tp_standard_account_name: "Change in Trade Payables",
  },
  {
    operations: [
      {
        accountCode: "20.4.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.3.16",
    tp_standard_account_name: "Change in Other Payables",
  },
  {
    operations: [
      {
        accountCode: "20.4.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.18",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.17",
    tp_standard_account_name: "Change in Deferred Revenue",
  },
  {
    operations: [
      {
        accountCode: "20.4.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.90",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.19",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.90",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.14",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.90",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.18",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.19",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.90",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.18",
    tp_standard_account_name: "Change in Other Operating Liabilities",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "40.3.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.3.99",
    tp_standard_account_name:
      "CHANGES IN OPERATING-RELATED ASSETS AND LIABILITIES",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "40.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.2.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.4.99",
    tp_standard_account_name:
      "NET CASH FLOW GENERATED FROM OPERATING ACTIVITIES",
    description: 'Capital Expenditure (Purchase of PP&E, Leasehold Improvements, Fixtures & Fittings, Vehicles, Other CAPEX) If Cash Flow Statement is unavailable, Cash Flow from Operations is estimated as: Net Profit + Non-Cash Adjustments + Working Capital Changes – Capital Expenditure'
  },
  {
    operations: [
      {
        accountCode: "10.5.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.10",
    tp_standard_account_name: "Dividend Income",
  },
  {
    operations: [
      {
        accountCode: "10.5.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.11",
    tp_standard_account_name: "Gain (Loss) on Disposal of Fixed Assets",
  },
  {
    operations: [
      {
        accountCode: "10.5.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.12",
    tp_standard_account_name: "Gain (Loss) on Investments",
  },
  {
    operations: [
      {
        accountCode: "20.2.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.13",
    tp_standard_account_name:
      "Disposal (Purchase) of Property, Plant and Equipment",
  },
  {
    operations: [
      {
        accountCode: "20.2.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.14",
    tp_standard_account_name: "Disposal (Purchase) of Leasehold improvements",
  },
  {
    operations: [
      {
        accountCode: "20.2.14",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.15",
    tp_standard_account_name: "Disposal (Purchase) of Fixtures and fittings",
  },
  {
    operations: [
      {
        accountCode: "20.2.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.16",
    tp_standard_account_name: "Disposal (Purchase) of Vehicles",
  },
  {
    operations: [
      {
        accountCode: "20.2.16",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.17",
    tp_standard_account_name: "Decrease (Increase) in Goodwill",
  },
  {
    operations: [
      {
        accountCode: "20.2.17",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.18",
    tp_standard_account_name:
      "Disposal (Purchase) of Intangible Assets other than Goodwill",
  },
  {
    operations: [
      {
        accountCode: "20.2.19",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.19",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.19",
    tp_standard_account_name:
      "Decrease (Increase) in Investments in subsidiaries, joint ventures and associates",
  },
  {
    operations: [
      {
        accountCode: "20.1.19",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.20",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.19",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.20",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.20",
    tp_standard_account_name:
      "Decrease (Increase) in Amounts due from shareholders and directors",
  },
  {
    operations: [
      {
        accountCode: "20.2.20",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.21",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.20",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.21",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.21",
    tp_standard_account_name:
      "Decrease (Increase) in Amounts due from subsidiaries",
  },
  {
    operations: [
      {
        accountCode: "20.2.21",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.22",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.21",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.22",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.22",
    tp_standard_account_name:
      "Decrease (Increase) in Amounts due from associates or other related parties",
  },
  {
    operations: [
      {
        accountCode: "20.1.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.23",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.23",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.23",
    tp_standard_account_name:
      "Disposal (Purchase) of Securities & Other Financial Assets",
  },
  {
    operations: [
      {
        accountCode: "20.2.24",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.24",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.24",
    tp_standard_account_name:
      "Disposal (Purchase) of Other Non-Current investments",
  },
  {
    operations: [
      {
        accountCode: "20.1.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.25",
    tp_standard_account_name: "Decrease (Increase) in Assets held-for-sale",
  },
  {
    operations: [
      {
        accountCode: "20.1.92",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.92",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.92",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.92",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.26",
    tp_standard_account_name:
      "Decrease (Increase) in Assets of discontinued operations",
  },
  {
    operations: [
      {
        accountCode: "20.4.22",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.14",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.22",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.27",
    tp_standard_account_name:
      "Increase (Decrease) in Amounts due to associates or other related parties",
  },
  {
    operations: [
      {
        accountCode: "20.4.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.28",
    tp_standard_account_name:
      "Increase (Decrease) in Liabilities held-for-sale",
  },
  {
    operations: [
      {
        accountCode: "20.4.92",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.92",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.92",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.92",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.29",
    tp_standard_account_name:
      "Increase (Decrease) in Liabilities of discontinued operations",
  },
  {
    bold: true,
    operations: [
      { accountCode: "40.5.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.28", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.29", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.5.99",
    tp_standard_account_name: "NET CASH FLOW FROM INVESTING ACTIVITIES",
  },
  {
    operations: [
      {
        accountCode: "20.4.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.10",
    tp_standard_account_name: "Increase (Decrease) in Dividend Payables",
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.5.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.15,20.5.16",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.11",
    tp_standard_account_name: "Increase (Decrease) in Bank loans",
  },
  {
    operations: [
      {
        accountCode: "20.4.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.16",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.12",
    tp_standard_account_name: "Increase (Decrease) in Short-term debt",
  },
  {
    operations: [
      {
        accountCode: "20.4.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.17",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.17",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.13",
    tp_standard_account_name: "Increase (Decrease) in Long-term debt",
  },
  {
    operations: [
      {
        accountCode: "20.4.19,20.5.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.19,20.5.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.14",
    tp_standard_account_name: "Increase (Decrease) in Finance/Operating leases",
  },
  {
    operations: [
      {
        accountCode: "20.4.20",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.20",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.15",
    tp_standard_account_name:
      "Increase (Decrease) in Amounts due to shareholders / directors",
  },
  {
    operations: [
      {
        accountCode: "20.4.21",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.21",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.16",
    tp_standard_account_name:
      "Increase (Decrease) in Amounts due to subsidiaries",
  },
  {
    operations: [
      {
        accountCode: "20.7.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.17",
    tp_standard_account_name: "Increase (Decrease) in Common Share Capital",
  },
  {
    operations: [
      {
        accountCode: "20.7.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.18",
    tp_standard_account_name: "Increase (Decrease) in Preferred Shares",
  },
  {
    operations: [
      {
        accountCode: "20.7.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.19",
    tp_standard_account_name: "Increase (Decrease) in Treasury Shares",
  },
  {
    operations: [
      {
        accountCode: "20.7.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.20",
    tp_standard_account_name: "Increase (Decrease) in Share Premium",
  },
  {
    operations: [
      {
        accountCode: "20.7.90",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.90",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.21",
    tp_standard_account_name: "Increase (Decrease) in Other Equity",
  },
  {
    operations: [
      {
        accountCode: "20.7.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.22",
    tp_standard_account_name:
      "Increase (Decrease) in Accumulated Other Comprehensive Income (Loss)",
  },
  {
    operations: [
      {
        accountCode: "20.7.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.23",
    tp_standard_account_name: "Increase (Decrease) in Other Reserves",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "40.6.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.19",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.20",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.21",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.22",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.23",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.6.99",
    tp_standard_account_name: "NET CASH FLOW FROM FINANCING ACTIVITIES",
  },
  {
    operations: [
      {
        accountCode: "20.1.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.8.10",
    tp_standard_account_name: "Cash and cash equivalents at beginning of year",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.9.00",
    tp_standard_account_name: "CASH AND CASH EQUIVALENTS AT END OF YEAR",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "40.9.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.8.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.7.10",
    tp_standard_account_name:
      "NET INCREASE / (DECREASE) IN CASH AND CASH EQUIVALENTS",
  },
  {
    operations: [
      {
        accountCode: "40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.5.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.7.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.7.01",
    tp_standard_account_name: "Unspecified cash flow movements",
  },
];

export const KEY_RATIOS_SUBGROUP_FORMULA = [
  {
    operations: [
      {
        accountCode: "20.1.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.1.10",
    tp_standard_account_name: "Quick Ratio (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description: "(Current Assets – Inventory) / Current Liabilities",
  },
  {
    operations: [
      {
        accountCode: "20.1.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.1.11",
    tp_standard_account_name: "Current Ratio (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description: "Current Assets / Current Liabilities",
  },
  {
    operations: [
      {
        accountCode: "20.1.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "50.1.12",
    tp_standard_account_name: "Working Capital",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description: "Current Assets – Current Liabilities",
  },
  {
    operations: [
      {
        accountCode: "40.4.99,40.5.13-40.5.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowGenerated =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_GENERATED];
          let totalAmount = 0;
          if (isCashFlowGenerated) {
            for (const accountCode in accountCodeAmountMap) {
              const amount = accountCodeAmountMap[accountCode];
              totalAmount += amount;
            }
          }
          return totalAmount;
        },
      },
      {
        accountCode: "30.4.99,30.5.19-30.5.23",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];
          let totalAmount = 0;
          if (isCashFlowUploaded) {
            for (const accountCode in accountCodeAmountMap) {
              const amount = accountCodeAmountMap[accountCode];
              totalAmount += amount;
            }
          }
          return totalAmount;
        },
      },
    ],
    displayCode: "50.1.13",
    tp_standard_account_name: "Free Cash Flow",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description: (
      <p>
        Cash Flow from Operations – Capital Expenditure (Purchase of PP&E,
        Leasehold Improvements, Fixtures & Fittings, Vehicles, Other CAPEX)
        <br />
        <br />
        If Cash Flow Statement is unavailable, Cash Flow from Operations is
        estimated as: Net Profit + Non-Cash Adjustments + Working Capital
        Changes – Capital Expenditure
      </p>
    ),
  },
  {
    operations: [
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.4.99,30.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (
          accountCodeAmountMap,
          metadata,
          totals,
          calculatedPeriodString
        ) => {
          const { period } = extractPeriodAndDate(calculatedPeriodString);
          let opNetCashFlow = accountCodeAmountMap["40.4.99"];
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          if (isCashFlowUploaded) {
            opNetCashFlow = accountCodeAmountMap["30.4.99"];
          }

          return -1 * (opNetCashFlow / period);
        },
      },
      {
        accountCode: "40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const amount = accountCodeAmountMap["40.4.99"] || 0;
          if (amount >= 0) {
            return "N/A";
          }

          return 0;
        },
      },
      {
        accountCode: "30.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const amount = accountCodeAmountMap["30.4.99"] || 0;
          if (amount >= 0) {
            return "-";
          }

          return 0;
        },
      },
    ],
    displayCode: "50.1.90",
    tp_standard_account_name: "Cash Runway (months)",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description: (
      <p>
        Cash & Cash Equivalents / Monthly Cash Flow from Operations
        <br />
        <br />
        If Cash Flow Statement is unavailable, Cash Flow from Operations is
        estimated as: Net Profit + Non-Cash Adjustments + Working Capital
        Changes – Capital Expenditure
      </p>
    ),
  },
  {
    operations: [
      {
        accountCode: "20.1.13,20.2.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.13,20.2.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (metadata) => {
          if (metadata.isOldestYear) {
            return 1;
          }

          return 2;
        },
      },
      {
        accountCode: "10.1.00",
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, __, _, calculatedPeriodString) => {
          const { period } = extractPeriodAndDate(calculatedPeriodString);
          const revenue = accountCodeAmountMap["10.1.00"];

          return (12 * revenue) / period;
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.MULTIPLY,
        calculate: () => 365,
      },
    ],
    displayCode: "50.2.10",
    tp_standard_account_name: "Trade Receivable Days",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description: "Average Trade Receivables / Annualised Revenue x 365",
  },
  {
    operations: [
      {
        accountCode: "20.1.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (metadata) => {
          if (metadata.isOldestYear) {
            return 1;
          }

          return 2;
        },
      },
      {
        accountCode: "10.2.00",
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, __, _, periodString) => {
          const { period } = extractPeriodAndDate(periodString);

          const revenue = accountCodeAmountMap["10.2.00"];

          return (12 * revenue) / period;
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.MULTIPLY,
        calculate: () => 365,
      },
    ],
    displayCode: "50.2.11",
    tp_standard_account_name: "Inventory Days",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description: "Average Inventory / Annualised Cost of Revenue x 365",
  },
  {
    operations: [
      {
        accountCode: "20.4.10,20.5.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.10,20.5.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (metadata) => {
          if (metadata.isOldestYear) {
            return 1;
          }

          return 2;
        },
      },
      {
        accountCode: "10.2.00",
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, _, __, periodString) => {
          const { period } = extractPeriodAndDate(periodString);
          const revenue = accountCodeAmountMap["10.2.00"];

          return (12 * revenue) / period;
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.MULTIPLY,
        calculate: () => 365,
      },
    ],
    displayCode: "50.2.12",
    tp_standard_account_name: "Trade Payable Days",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description: "Average Trade Payables / Annualised Cost of Revenue x 365",
  },
  {
    operations: [
      {
        accountCode: "50.2.10,50.2.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "50.2.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "50.2.13",
    tp_standard_account_name: "Working Capital Cycle (Days)",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description: "Trade Receivables Days + Inventory Days – Trade Payable Days",
  },
  {
    operations: [
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        calculate: () => {
          return 1;
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (metadata) => {
          if (metadata.isOldestYear) {
            return "-";
          }

          return;
        },
      },
    ],
    displayCode: "50.3.10",
    tp_standard_account_name: "Revenue Growth YoY (%)",
    formatTypes: [
      FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION,
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:1`,
      FORMAT_NUMBER_TYPE.PERCENTAGE,
    ],
    description:
      "Revenue growth, comparing the same income statement period year-on-year",
  },
  {
    operations: [
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.4.00",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        calculate: () => 1,
      },
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (
          accountCodeAmountMap,
          metadata,
          accountCodeData,
          currentPeriodString
        ) => {
          if (metadata.isOldestYear) {
            return "-";
          }

          const ebitCode = "10.4.00";

          const currentAmount = accountCodeAmountMap[ebitCode];

          const { period, monthInt, year, audited } =
            extractPeriodAndDate(currentPeriodString);

          const previousPeriodString = generatePeriodString(
            monthInt,
            year - 1,
            period,
            audited
          );

          const previousAmount =
            accountCodeData[previousPeriodString]?.[ebitCode];

          if (
            (previousAmount < 0 && currentAmount >= 0) ||
            (previousAmount < 0 && currentAmount > previousAmount)
          ) {
            return "Positive change";
          }

          if (
            (previousAmount >= 0 && currentAmount < 0) ||
            (previousAmount < 0 && currentAmount < previousAmount)
          ) {
            return "Negative change";
          }

          if (currentAmount === previousAmount) {
            return "Same";
          }

          return "";
        },
      },
    ],
    displayCode: "50.3.12",
    tp_standard_account_name: "EBIT Growth YoY (%)",
    formatTypes: [
      FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION,
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:1`,
      FORMAT_NUMBER_TYPE.PERCENTAGE,
    ],
    description:
      "Net Operating Profit growth, comparing the same income statement period year-on-year",
  },
  {
    operations: [
      {
        accountCode: "10.7.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.7.00",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        calculate: () => 1,
      },
      {
        accountCode: "10.7.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (
          accountCodeAmountMap,
          metadata,
          accountCodeData,
          currentPeriodString
        ) => {
          if (metadata.isOldestYear) {
            return "-";
          }

          const ebitCode = "10.7.00";

          const currentAmount = accountCodeAmountMap[ebitCode];

          const { period, monthInt, year, audited } =
            extractPeriodAndDate(currentPeriodString);

          const previousPeriodString = generatePeriodString(
            monthInt,
            year - 1,
            period,
            audited
          );

          const previousAmount =
            accountCodeData[previousPeriodString]?.[ebitCode];

          if (
            (previousAmount < 0 && currentAmount >= 0) ||
            (previousAmount < 0 && currentAmount > previousAmount)
          ) {
            return "Positive change";
          }

          if (
            (previousAmount >= 0 && currentAmount < 0) ||
            (previousAmount < 0 && currentAmount < previousAmount)
          ) {
            return "Negative change";
          }

          if (currentAmount === previousAmount) {
            return "Same";
          }

          return "";
        },
      },
    ],
    displayCode: "50.3.13",
    tp_standard_account_name: "Net Profit Growth YoY (%)",
    formatTypes: [
      FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION,
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:1`,
      FORMAT_NUMBER_TYPE.PERCENTAGE,
    ],
    description:
      "Net Profit growth, comparing the same income statement period year-on-year",
  },
  {
    operations: [
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "30.2.11,30.2.12,30.2.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap = {}, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          if (isCashFlowUploaded) {
            return Object.values(accountCodeAmountMap).reduce(
              (total, amount) => {
                return total + amount;
              },
              0
            );
          }

          return 0;
        },
      },
      {
        accountCode: "10.3.16,10.3.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap = {}, metadata) => {
          const isCashFlowGenerated =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_GENERATED];

          if (isCashFlowGenerated) {
            return Object.values(accountCodeAmountMap).reduce(
              (total, amount) => {
                return total + amount;
              },
              0
            );
          }

          return 0;
        },
      },
    ],
    displayCode: "50.4.10",
    tp_standard_account_name: "EBITDA",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description: (
      <p>
        Net Operating Profit + Depreciation + Amortisation + Impairment
        <br />
        <br />
        If Cash Flow Statement is available, Depreciation, Amortisation and
        Impairment are taken from there. Otherwise, they are taken from Income
        Statement.
      </p>
    ),
  },
  {
    operations: [
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "50.4.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        calculate: () => 1,
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (
          accountCodeAmountMap,
          metadata,
          accountCodeData,
          currentPeriodString
        ) => {
          if (metadata.isOldestYear) {
            return "-";
          }

          const ebitCode = "50.4.10";

          const currentAmount = accountCodeAmountMap[ebitCode];

          const { period, monthInt, year, audited } =
            extractPeriodAndDate(currentPeriodString);

          const previousPeriodString = generatePeriodString(
            monthInt,
            year - 1,
            period,
            audited
          );

          const previousAmount =
            accountCodeData[previousPeriodString]?.[ebitCode];

          if (
            (previousAmount < 0 && currentAmount >= 0) ||
            (previousAmount < 0 && currentAmount > previousAmount)
          ) {
            return "Positive change";
          }

          if (
            (previousAmount >= 0 && currentAmount < 0) ||
            (previousAmount < 0 && currentAmount < previousAmount)
          ) {
            return "Negative change";
          }

          if (currentAmount === previousAmount) {
            return "Same";
          }

          return "";
        },
      },
    ],
    displayCode: "50.3.11",
    tp_standard_account_name: "EBITDA Growth YoY (%)",
    formatTypes: [
      FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION,
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:1`,
      FORMAT_NUMBER_TYPE.PERCENTAGE,
    ],
    description:
      "EBITDA growth, comparing the same income statement period year-on-year",
  },
  {
    operations: [
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "50.4.11",
    tp_standard_account_name: "EBIT",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description: "Net Operating Profit",
  },
  {
    operations: [
      {
        accountCode: "10.3.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.12",
    tp_standard_account_name: "Gross Profit Margin (%)",
    formatTypes: [
      FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION,
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:1`,
      FORMAT_NUMBER_TYPE.PERCENTAGE,
    ],
    description: "Gross Profit / Revenue",
  },
  {
    operations: [
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.13",
    tp_standard_account_name: "EBITDA Margin (%)",
    formatTypes: [
      FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION,
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:1`,
      FORMAT_NUMBER_TYPE.PERCENTAGE,
    ],
    description: "EBITDA / Revenue",
  },
  {
    operations: [
      {
        accountCode: "50.4.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.14",
    tp_standard_account_name: "EBIT Margin (%)",
    formatTypes: [
      FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION,
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:1`,
      FORMAT_NUMBER_TYPE.PERCENTAGE,
    ],
    description: "EBIT / Revenue",
  },
  {
    operations: [
      {
        accountCode: "10.6.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.15",
    tp_standard_account_name: "Profit Before Tax Margin (%)",
    formatTypes: [
      FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION,
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:1`,
      FORMAT_NUMBER_TYPE.PERCENTAGE,
    ],
    description: "Profit Before Tax / Revenue",
  },
  {
    operations: [
      {
        accountCode: "10.7.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.16",
    tp_standard_account_name: "Net Profit Margin (%)",
    formatTypes: [
      FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION,
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:1`,
      FORMAT_NUMBER_TYPE.PERCENTAGE,
    ],
    description: "Net Profit / Revenue",
  },
  {
    operations: [
      {
        accountCode: "20.7.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.16,20.2.17,20.2.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "50.5.10",
    tp_standard_account_name: "Tangible Net Worth",
    formatTypes: [
      `${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:0`,
      FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS,
      FORMAT_NUMBER_TYPE.FORMAT_NUMBER,
    ],
    description:
      "Total Equity – Goodwill – Intangible Assets – Operating Lease RoU Assets",
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.5.11",
    tp_standard_account_name: "Debt / Net Worth (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description:
      "(Bank Loans + Short & Long Term Debts + Finance & Operating Leases) / Total Equity",
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.7.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.5.12",
    tp_standard_account_name: "Net Debt / Net Worth (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description:
      "(Bank Loans + Short & Long Term Debts + Finance & Operating Leases – Cash & Cash Equivalents) / Total Equity",
  },
  {
    operations: [
      {
        accountCode: "20.6.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.5.13",
    tp_standard_account_name: "Total Liabilities / Net Worth (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description: "Total Liabilities / Total Equity",
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "50.5.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.5.14",
    tp_standard_account_name: "Debt / Tangible Net Worth (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description:
      "(Bank Loans + Short & Long Term Debts + Finance & Operating Leases) / Tangible Net Worth",
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "50.5.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.5.15",
    tp_standard_account_name: "Net Debt / Tangible Net Worth (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description:
      "(Bank Loans + Short & Long Term Debts + Finance & Operating Leases – Cash & Cash Equivalents) / Tangible Net Worth",
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, _, __, periodString) => {
          const { period } = extractPeriodAndDate(periodString);
          const amount = accountCodeAmountMap["50.4.10"];
          return (12 * amount) / period;
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.5.16",
    tp_standard_account_name: "Debt / EBITDA (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description:
      "(Bank Loans + Short & Long Term Debts + Finance & Operating Leases) / Annualised EBITDA",
  },
  {
    operations: [
      {
        accountCode:
          "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17,50.5.16",
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (
          accountCodeAmountMap,
          metadata,
          totals = {},
          periodString
        ) => {
          const {
            period: currentPeriod,
            monthInt: currentMonthInt,
            year: currentYear,
            audited: currentAudited,
            month,
          } = extractPeriodAndDate(periodString);
          // if 12M, use Debt / EBITDA
          if (currentPeriod === "12") {
            return;
          }

          const previousPeriodString = generatePeriodString(
            currentMonthInt,
            currentYear - 1,
            currentPeriod,
            currentAudited
          );

          // if no previous x-month data, then cannot be calculated
          if (!totals[previousPeriodString]) {
            return;
          }

          const previousLtms = Object.keys(totals)
            .filter((periodString) => {
              const { period, monthInt, year } =
                extractPeriodAndDate(periodString);

              if (period !== 12) {
                return false;
              }

              if (year < currentYear - 1 || year > currentYear) {
                return false;
              }

              // ltm is after the current report, cannot be considered previous ltm
              if (year === currentYear && monthInt > currentMonthInt) {
                return false;
              }

              if (year === currentYear - 1 && monthInt < currentMonthInt) {
                return false;
              }

              return true;
            })
            .sort((a, b) => {
              const { monthInt: monthA, year: yearA } = extractPeriodAndDate(a);
              const { monthInt: monthB, year: yearB } = extractPeriodAndDate(b);

              if (yearA !== yearB) {
                return yearB - yearA;
              }

              return monthB - monthA;
            });

          const latestPreviousLtmPeriodString = previousLtms[0];

          // not ltm means data cannot be calculated
          if (!latestPreviousLtmPeriodString) {
            return;
          }

          const ebitdaCode = "50.4.10";

          const currentMonthEbitda = accountCodeAmountMap[ebitdaCode];

          const previousMonthEbitda =
            totals[previousPeriodString]?.[ebitdaCode];

          const previous12MonthEbitda =
            totals[latestPreviousLtmPeriodString]?.[ebitdaCode];

          if (
            !currentMonthEbitda ||
            !previousMonthEbitda ||
            !previous12MonthEbitda
          ) {
            return;
          }

          return (
            currentMonthEbitda + previous12MonthEbitda - previousMonthEbitda
          );
        },
      },
      {
        accountCode: "50.5.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap, _metadata, _totals, periodString) => {
          const { period: currentPeriod } = extractPeriodAndDate(periodString);

          const currentEbitdaAmount = accountCodeAmountMap["50.5.16"];

          if (currentPeriod === "12") {
            return currentEbitdaAmount;
          }
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.5.17",
    tp_standard_account_name: "Debt / LTM EBITDA (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description:
      "(Bank Loans + Short & Long Term Debts + Finance & Operating Leases – Cash & Cash Equivalents) / Annualised EBITDA",
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, _, __, periodString) => {
          const { period } = extractPeriodAndDate(periodString);
          const amount = accountCodeAmountMap["50.4.10"];
          return (12 * amount) / period;
        },
      },
    ],
    displayCode: "50.5.18",
    tp_standard_account_name: "Net Debt / EBITDA (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description:
      "(Bank Loans + Short & Long Term Debts + Finance & Operating Leases – Cash & Cash Equivalents) / Annualised EBITDA",
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.10",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (
          accountCodeAmountMap,
          _metadata,
          totals = {},
          periodString
        ) => {
          const {
            period: currentPeriod,
            monthInt: currentMonthInt,
            year: currentYear,
            audited: currentAudited,
          } = extractPeriodAndDate(periodString);
          // if 12M, use Debt / EBITDA
          if (currentPeriod === "12") {
            return;
          }

          const previousPeriodString = generatePeriodString(
            currentMonthInt,
            currentYear - 1,
            currentPeriod,
            currentAudited
          );

          // if no previous x-month data, then cannot be calculated
          if (!totals[previousPeriodString]) {
            return;
          }

          const previousLtms = Object.keys(totals)
            .filter((periodString) => {
              const { period, monthInt, year } =
                extractPeriodAndDate(periodString);

              if (period !== 12) {
                return false;
              }

              if (year < currentYear - 1 || year > currentYear) {
                return false;
              }

              // ltm is after the current report, cannot be considered previous ltm
              if (year === currentYear && monthInt > currentMonthInt) {
                return false;
              }

              if (year === currentYear - 1 && monthInt < currentMonthInt) {
                return false;
              }

              return true;
            })
            .sort((a, b) => {
              const { monthInt: monthA, year: yearA } = extractPeriodAndDate(a);
              const { monthInt: monthB, year: yearB } = extractPeriodAndDate(b);

              if (yearA !== yearB) {
                return yearB - yearA;
              }

              return monthB - monthA;
            });

          const latestPreviousLtmPeriodString = previousLtms[0];

          // not ltm means data cannot be calculated
          if (!latestPreviousLtmPeriodString) {
            return;
          }

          const ebitdaCode = "50.4.10";

          const currentMonthEbitda = accountCodeAmountMap[ebitdaCode];

          const previousMonthEbitda =
            totals[previousPeriodString]?.[ebitdaCode];

          const previous12MonthEbitda =
            totals[latestPreviousLtmPeriodString]?.[ebitdaCode];

          if (
            !currentMonthEbitda ||
            !previousMonthEbitda ||
            !previous12MonthEbitda
          ) {
            return;
          }

          return (
            currentMonthEbitda + previous12MonthEbitda - previousMonthEbitda
          );
        },
      },
      {
        accountCode: "50.5.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap, _metadata, _totals, periodString) => {
          const { period: currentPeriod } = extractPeriodAndDate(periodString);

          const currentEbitdaAmount = accountCodeAmountMap["50.5.18"];

          if (currentPeriod === "12") {
            return currentEbitdaAmount;
          }
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (_, totals, periodString) => {
          const currentTotal = totals[periodString];

          if (currentTotal < 0) {
            return "Neg";
          }
        },
      },
    ],
    displayCode: "50.5.19",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    tp_standard_account_name: "Net Debt / LTM EBITDA (x)",
    description:
      "(Bank Loans + Short & Long Term Debts + Finance & Operating Leases – Cash & Cash Equivalents) / Last 12 month’s EBITDA",
  },
  {
    operations: [
      {
        accountCode: "20.6.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.3.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.5.20",
    tp_standard_account_name: "Total Liabilities / Total Assets (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description:
      "(Bank Loans + Short & Long Term Debts + Finance & Operating Leases – Cash & Cash Equivalents) / Annualised EBITDA",
  },
  {
    operations: [
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.4.11,10.4.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap) => {
          const netInterestIncome = accountCodeAmountMap["10.4.12"] || 0;
          const interestExpense = accountCodeAmountMap["10.4.11"] || 0;

          if (netInterestIncome < 0) {
            return (interestExpense + netInterestIncome) * -1;
          }

          return interestExpense * -1;
        },
      },
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["10.4.00"];
          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.10",
    tp_standard_account_name: "EBIT to Interest Coverage Ratio (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description: "EBIT / Interest Expense",
  },
  {
    operations: [
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.4.11,10.4.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap) => {
          const netInterestIncome = accountCodeAmountMap["10.4.12"] || 0;
          const interestExpense = accountCodeAmountMap["10.4.11"] || 0;

          if (netInterestIncome < 0) {
            return (interestExpense + netInterestIncome) * -1;
          }

          return interestExpense * -1;
        },
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["50.4.10"];
          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.11",
    tp_standard_account_name: "EBITDA to Interest Coverage Ratio (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description: "EBITDA / Interest Expense",
  },
  {
    operations: [
      {
        accountCode: "30.4.99,40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          if (isCashFlowUploaded) {
            return accountCodeAmountMap["30.4.99"];
          }

          return accountCodeAmountMap["40.4.99"];
        },
      },
      {
        accountCode: "10.4.11,10.4.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap) => {
          const netInterestIncome = accountCodeAmountMap["10.4.12"] || 0;
          const interestExpense = accountCodeAmountMap["10.4.11"] || 0;

          if (netInterestIncome < 0) {
            return (interestExpense + netInterestIncome) * -1;
          }

          return interestExpense * -1;
        },
      },
      {
        accountCode: "30.4.99,40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          let value = accountCodeAmountMap["40.4.99"];

          if (isCashFlowUploaded) {
            value = accountCodeAmountMap["30.4.99"];
          }

          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.12",
    tp_standard_account_name:
      "Operating Cash Flow to Interest Coverage Ratio (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description: (
      <p>
        Cash Flow from Operations / Interest Expense
        <br />
        <br />
        If Cash Flow Statement is unavailable, Cash Flow from Operations is
        estimated as: Net Profit + Non-Cash Adjustments + Working Capital
        Changes – Capital Expenditure
      </p>
    ),
  },
  {
    operations: [
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.MULTIPLY,
        calculate: () => 12,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (_, __, periodString) => {
          const { period } = extractPeriodAndDate(periodString);

          return period;
        },
      },
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,10.4.11,10.4.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap = {}) => {
          const {
            "10.4.11": interestExpense = 0,
            "10.4.12": netInterestIncome = 0,
            ...otherValues
          } = accountCodeAmountMap;

          let total = -1 * interestExpense;

          if (netInterestIncome < 0) {
            total -= netInterestIncome;
          }

          const finaltotal = Object.values(otherValues).reduce(
            (total, amount) => {
              return total + amount;
            },
            total
          );

          return finaltotal;
        },
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["50.4.10"];
          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.13",
    tp_standard_account_name: "Debt Service Coverage Ratio (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description:
      "Annualised EBITDA / (Current Bank Loans + Short Term Debts + Current Finance & Operating Leases + Interest Expense)",
  },
  {
    operations: [
      {
        accountCode: "10.4.00,10.3.12,20.4.15,20.4.16,20.4.17,20.4.19",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.3.12,20.4.15,20.4.16,20.4.17,20.4.19,10.4.11,10.4.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap = {}) => {
          const {
            "10.4.11": interestExpense = 0,
            "10.4.12": netInterestIncome = 0,
            ...otherValues
          } = accountCodeAmountMap;

          let total = -1 * interestExpense;

          if (netInterestIncome < 0) {
            total -= netInterestIncome;
          }

          return Object.values(otherValues).reduce((total, amount) => {
            return total + amount;
          }, total);
        },
      },
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          let ebitValue = accountCodeAmountMap["10.4.00"];

          if (ebitValue < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.14",
    tp_standard_account_name: "Fixed Charge Coverage Ratio (x)",
    formatTypes: [`${FORMAT_NUMBER_TYPE.ROUND_DECIMAL}:2`],
    description: (
      <p>
        (EBIT + Fixed Charges) / (Fixed Charges + Interest Expense)
        <br />
        <br />
        Fixed Charges = Personnel and Benefit Expenses + Current Bank Loans +
        Short Term Debts + Current Finance & Operating Leases
      </p>
    ),
  },
];

export const FORMULAS_MAP = {
  [DOCUMENT_TYPE_ENUM.BALANCE_SHEET]: BALANCE_SHEET_SUBGROUP_FORMULA,
  [DOCUMENT_TYPE_ENUM.INCOME_STATEMENT]: INCOME_STATEMENT_SUBGROUP_FORMULA,
  [DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT]: CASH_FLOW_SUBGROUP_FORMULA,
  [DOCUMENT_TYPE_ENUM.CASH_FLOW_GENERATED]:
    CASH_FLOW_GENERATED_SUBGROUP_FORMULA,
  [DOCUMENT_TYPE_ENUM.KEY_RATIOS]: KEY_RATIOS_SUBGROUP_FORMULA,
};

export const BALANCE_SHEET_VALIDATION_FORMULA = {
  validation: {
    formula: {
      operations: [
        { accountCode: "20.3.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
        {
          accountCode: "20.6.00",
          operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        },
        {
          accountCode: "20.7.99",
          operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        },
      ],
      displayCode: "20.3.00",
    },
    equal: 0,
  },
  summary: [
    {
      operations: [
        { accountCode: "20.3.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "20.3.00",
      tp_standard_account_name: "Total Assets",
    },
    {
      operations: [
        { accountCode: "20.6.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "20.6.00",
      tp_standard_account_name: "Total Liabilities",
    },
    {
      operations: [
        { accountCode: "20.7.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "20.7.99",
      tp_standard_account_name: "Total Equity",
    },
    {
      operations: [
        { accountCode: "20.3.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
        {
          accountCode: "20.6.00,20.7.99",
          operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        },
        {
          accountCode: ACCOUNT_CODE_CONSTANT,
          operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
          calculate: (_, totals, calculatedYear) => {
            const currentTotal = totals[calculatedYear];

            if (currentTotal === 0) {
              return "Balanced";
            }

            if (currentTotal > 0) {
              return "Assets are overstated by " + currentTotal;
            }

            return (
              "Liabilities and/or Equity are overstated by " + currentTotal
            );
          },
        },
      ],
      tp_standard_account_name: "",
    },
  ],
};

export const INCOME_STATEMENT_VALIDATION_FORMULA = {
  summary: [
    {
      operations: [
        { accountCode: "10.7.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "10.7.00",
      tp_standard_account_name: "Net Profit (Loss)",
    },
  ],
};

export const CASH_FLOW_VALIDATION_FORMULA = {
  summary: [
    {
      operations: [
        { accountCode: "30.7.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "30.7.00",
      tp_standard_account_name:
        "Net Increase / (Decrease) In Cash and Cash Equivalents",
    },
  ],
};

export const VALIDATION_FORMULA_MAP = {
  [DOCUMENT_TYPE_ENUM.BALANCE_SHEET]: BALANCE_SHEET_VALIDATION_FORMULA,
  [DOCUMENT_TYPE_ENUM.INCOME_STATEMENT]: INCOME_STATEMENT_VALIDATION_FORMULA,
  [DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT]: CASH_FLOW_VALIDATION_FORMULA,
};

export const FORMULA_GROUPING = {
  [DOCUMENT_TYPE_ENUM.INCOME_STATEMENT]: [
    {
      name: "Revenue and Operating Expenses",
      summary: [
        {
          operations: [
            {
              accountCode: "10.4.00",
              operation: FORMULA_CALCULATE_OPERATIONS.ADD,
            },
          ],
          displayCode: "10.4.00",
          tp_standard_account_name: "Net Operating Profit",
        },
      ],
      content: "10.1.00-10.4.00",
    },
    {
      name: "Interest and Non-Operating Income (Expense)",
      summary: [
        {
          operations: [
            {
              accountCode: "10.7.00",
              operation: FORMULA_CALCULATE_OPERATIONS.ADD,
            },
          ],
          displayCode: "10.7.00",
          tp_standard_account_name: "Net Profit (Loss)",
        },
      ],
      content: "10.4.01-10.7.00",
      canInverseValue: true,
    },
  ],
  [DOCUMENT_TYPE_ENUM.BALANCE_SHEET]: [
    {
      name: "Assets",
      summary: [
        {
          operations: [
            {
              accountCode: "20.3.00",
              operation: FORMULA_CALCULATE_OPERATIONS.ADD,
            },
          ],
          displayCode: "20.3.00",
          tp_standard_account_name: "Total Assets",
        },
      ],
      content: "20.1.01-20.3.00",
    },
    {
      name: "Liabilities",
      summary: [
        {
          operations: [
            {
              accountCode: "20.6.00",
              operation: FORMULA_CALCULATE_OPERATIONS.ADD,
            },
          ],
          displayCode: "20.6.00",
          tp_standard_account_name: "Total Liabilities",
        },
      ],
      content: "20.4.01-20.6.00",
    },
    {
      name: "Equity",
      summary: [
        {
          operations: [
            {
              accountCode: "20.7.99",
              operation: FORMULA_CALCULATE_OPERATIONS.ADD,
            },
          ],
          displayCode: "20.7.99",
          tp_standard_account_name: "Total Equity",
        },
      ],
      content: "20.7.01-20.7.99",
    },
  ],
  [DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT]: [
    {
      name: "Operating Activities",
      summary: [
        {
          operations: [
            {
              accountCode: "30.4.99",
              operation: FORMULA_CALCULATE_OPERATIONS.ADD,
            },
          ],
          displayCode: "30.4.99",
          tp_standard_account_name: "Net Cash Flow From Operating Activities",
        },
      ],
      content: "30.1.00-30.4.99",
    },
    {
      name: "Investing Activities",
      summary: [
        {
          operations: [
            {
              accountCode: "30.5.99",
              operation: FORMULA_CALCULATE_OPERATIONS.ADD,
            },
          ],
          displayCode: "30.5.99",
          tp_standard_account_name: "Net Cash Flow From Investing Activities",
        },
      ],
      content: "30.5.00-30.5.99",
    },
    {
      name: "Financing Activities",
      summary: [
        {
          operations: [
            {
              accountCode: "30.6.99",
              operation: FORMULA_CALCULATE_OPERATIONS.ADD,
            },
          ],
          displayCode: "30.6.99",
          tp_standard_account_name: "Net Cash Flow From Financing Activities",
        },
      ],
      content: "30.6.00-30.6.99",
    },
    {
      name: "Cash Balances",
      summary: [
        {
          operations: [
            {
              accountCode: "30.8.00",
              operation: FORMULA_CALCULATE_OPERATIONS.ADD,
            },
          ],
          displayCode: "30.8.00",
          tp_standard_account_name:
            "Cash and Cash Equivalents at End of Period",
        },
      ],
      content: "30.7.01-30.8.00",
    },
  ],
  [DOCUMENT_TYPE_ENUM.KEY_RATIOS]: [
    {
      name: "Liquidity",
      content: "50.1.10-50.1.99",
    },
    {
      name: 'Cash Conversion Cycle',
      content: '50.2.10-50.2.99',
    },
    {
      name: 'Growth',
      content: '50.3.10-50.3.99',
    },
    {
      name: 'Profitability',
      content: '50.4.10-50.4.99',
    },
    {
      name: 'Leverage',
      content: '50.5.10-50.5.99',
    },
    {
      name: 'Debt Coverage',
      content: '50.6.10-50.6.99',
    },
  ],
};