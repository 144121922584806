import { createContext, useContext, useEffect, useRef, useState } from "react";
import { API_PENDING_APPLICATIONS } from "../../constants/url";
import { useToaster } from "../Toaster";
import { APPLICATION_STATUS_PENDING_APPLICATIONS_MAP, PENDING_APPLICATION_STATUS } from "../../constants/statuses";
import { ROLES, TOAST_TYPE } from "../../constants";
import debounce from "../../utils/api/debounce";
import { useAuthContext } from "../Auth";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

const PendingApplicationsContext = createContext({
  pendingApplications: [],
  hasPendingApplication: false,
  removePendingApplication: (_applicationId) => {}, 
  addPendingApplication: (_companyName) => {},
});

export const usePendingApplications = () => useContext(PendingApplicationsContext)

export default function PendingApplicationsProvider({ children }) {
  const { user } = useAuthContext()
  const [pendingApplications, setPendingApplications] = useState([]);
  const { showToast } = useToaster();
  const intervalId = useRef(null)
  const isFetching = useRef(false)
  const authenticatedFetch = useAuthenticatedFetch()
  const abortControllerRef = useRef()

  const checkHasPendingApplication = (applications) => applications.some(
    (application) =>
      application.status === PENDING_APPLICATION_STATUS.IN_PROGRESS
  );


  const getPendingApplications = async (forcedRefresh = false) => {
    if (isFetching.current && !forcedRefresh) {
      return
    }

    let isAborted = false;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
   
    const controller = new AbortController();
    abortControllerRef.current = controller;

    isFetching.current = true
    return authenticatedFetch(API_PENDING_APPLICATIONS, {
      withCredentials: true,
      method: 'GET',
      signal: controller.signal,
    })
    .then(({ data }) => {
      const newPendingApplication = data
      .map(({ status, id, company_name, company_id }) => ({
        companyName: company_name,
        id,
        status: APPLICATION_STATUS_PENDING_APPLICATIONS_MAP[status],
        companyId: company_id,
      }))
      .filter(Boolean)

      setPendingApplications(newPendingApplication);
 
      return newPendingApplication
    })
    .catch((err) => {
      if (!err.message) {
        return
      }

      if (["AbortError", "CanceledError"].includes(err.name)) {
        isAborted = true
        return
      }

      showToast("failed to get pending applications", TOAST_TYPE.ERROR);
    }).finally(() => {
        if (!isAborted) {
          isFetching.current = false;
        }
    });
  }

  const debouncedGetPendingApplication = debounce(
    getPendingApplications,
    500
  );


  const cancelPoll = () => {
    clearInterval(intervalId.current);
    intervalId.current = null;
    debouncedGetPendingApplication.cancel()
  }

  const startPoll = () => {
    if (!intervalId.current) {
      intervalId.current = setInterval(() => getPendingApplications().then((newPendingApplications) => {
        if (newPendingApplications && !checkHasPendingApplication(newPendingApplications)) {
          cancelPoll()
        }
      }), 5000);
    }
  }

  const refreshPendingApplication = () => {
    debouncedGetPendingApplication(true)
    startPoll()
  }


  useEffect(() => {
    if([ROLES.MANAGER, ROLES.UNDERWRITER].includes(user.role))
    refreshPendingApplication()

    return () => {
      cancelPoll()
    }
  }, [user])

  const removePendingApplication = (applicationId) => {
    setPendingApplications(
      (pendingApplications) =>
       pendingApplications.filter(({ id }) => id !== applicationId)
    );
    refreshPendingApplication()
  };

  const addPendingApplication = (companyName) => {
    setPendingApplications((pendingApplications) => [
      ...pendingApplications,
      {
        companyName,
        status: PENDING_APPLICATION_STATUS.IN_PROGRESS,
      },
    ]);

    refreshPendingApplication();
  };

  const hasPendingApplication = checkHasPendingApplication(pendingApplications)

    return (
        <PendingApplicationsContext.Provider value={{ pendingApplications, hasPendingApplication, removePendingApplication, addPendingApplication }}>{children}</PendingApplicationsContext.Provider>
    )

}