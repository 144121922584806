import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import sendIcon from "../../assets/icons/icon_send.svg";
import { ROLES } from "../../constants";
import { useAuthContext } from "../../context/Auth";
import {
  ADMIN_CARDS,
  greeting,
  MANAGER_CARDS,
  UNDERWRITER_CARDS,
} from "./helpers";

export default function DashboardPage() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const cards = useMemo(() => {
    switch (user?.role) {
      case ROLES.UNDERWRITER:
        return UNDERWRITER_CARDS({ navigate });
      case ROLES.MANAGER:
        return MANAGER_CARDS({ navigate });
      case ROLES.ADMIN:
        return ADMIN_CARDS({ navigate });
      default:
        return [];
    }
  }, [user?.role]);

  return (
    <Fragment>
      <div className="bg-page-gradient fixed inset-0 z-[-1]" />
      <div className="2xl:px-16 sm:px-[48px] pb-16 flex justify-between flex-col h-screen-minus-header w-full">
        <div className="2xl:pt-[64px] lg:pt-[48px]">
          <div className="pb-8">
            <div className="2xl:pb-[64px] lg:pb-[48px]">
              <div>
                <span className="text-gradient-aurora-blue font-[700] lg:text-[2.75rem] lg:leading-[3rem] lg:tracking-[-1.76px]">
                  {greeting()}, {user?.first_name ?? ""}!
                </span>
              </div>
              <h2 className="text-Gray-500 mt-[12px] font-[600] leading-[2.5rem] text-[2rem] tracking-[-0.96px]">
                How can I help you today?
              </h2>
            </div>
            <div
              // className="flex flex-row justify-center gap-[1rem] w-full "
              className="flex flex-row justify-center flex-wrap 2xl:gap-[1.5rem] lg:gap-[1.25rem] w-full "
              style={{ transform: `scale(${1})` }}
            >
              {cards.map(({ onClick, description, title, icon }, _) => (
                <div
                  key={title}
                  className="hover:bg-primary-2 cursor-pointer w-[18%] p-[2px] rounded-[20px]"
                  onClick={onClick}
                >
                  <div className="bg-card-gradient w-full h-full rounded-[20px] flex flex-col justify-between 2xl:p-[1.5rem] lg:p-[20px]">
                    <div className="pb-8 text-left">
                      <h3 className="text-primary-950 font-[600] 2xl:text-[1.125rem] 2xl:leading-[1.5rem] 2xl:tracking-[-0.54px] lg:text-[1.125rem] lg:leading-[1.5rem] lg:tracking-[-0.54px] mt-[12px]">
                        {title}
                      </h3>
                      <p className="text-black-Alpha-600 font-[500] 2xl:text-[0.875rem] lg:text-[0.8125rem] 2xl:leading-[1.25rem] 2xl:tracking-[-0.28px] lg:tracking-[-0.26px] mt-[12px]">
                        {description}
                      </p>
                    </div>
                    <div className="flex justify-end w-full">
                      <div className="aspect-square bg-white rounded-full flex items-center border-[7px] border-primary-50 justify-center 2xl:w-[68px] lg:w-[56px]">
                        <img
                          src={icon}
                          alt={title}
                          className="aspect-square 2xl:w-[36px] lg:w-[32px]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="mx-auto w-full max-w-[884px] justify-between flex flex-col gap-[1.5rem]">
          <p className="text-left font-[500] text-[1rem] text-neutral-medium">
            For additional actions such as viewing your profile or entering
            direct commands (e.g., ‘I want to add a new set of financial
            statements for Trustplus AI’), please type your instructions
            directly into the text bar below.
          </p>
          <div className="relative w-full">
            <input
              className="bg-white opacity-55"
              placeholder="Type your instructions here"
            />
            <img
              src={sendIcon}
              alt="Send"
              className="w-10 h-10 bottom-1 absolute right-5"
            />
          </div>
        </div> */}
        <div className="min-h-[24px]"></div>
      </div>
    </Fragment>
  );
}
