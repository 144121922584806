export default function Tooltip({
  children,
  tooltip,
  tooltipClassname,
  customStyle,
  topTooltip = false,
  handleClick = () => {},
  tooltipArrowClassname = "",
  boxShadow,
}) {
  return (
    <div class="relative group" onClick={handleClick}>
      {children}
      <div
        class={`tooltip flex justify-center items-center text-[10px] font-[400] leading-[12px] mb-[8px] shadow-popover absolute left-1/2 -translate-x-1/2 bottom-full w-[260px] invisible !bg-[#fff] opacity-0 text-[#121212] rounded-[2px] py-[4px] px-[0.5rem]  transition-opacity duration-300 group-hover:visible group-hover:opacity-90 ${tooltipClassname}`}
        style={{
          boxShadow: boxShadow || "0px 4px 24px 0px #f1f1f1",
          ...customStyle,
        }}
      >
        {tooltip}
        {!topTooltip && (
          <div
            class={`absolute left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[5px] border-r-[5px] -bottom-[4px] !bg-transparent ${tooltipArrowClassname}`}
            style={{
              borderTop: "5px solid #fff",
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderBottom: "none",
            }}
          ></div>
        )}
      </div>
    </div>
  );
}
