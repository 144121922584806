import backIcon from "../../assets/icons/icon_back.svg";
import BarChart from "../../components/BarChart";

import { useNavigate } from "react-router-dom";
import CommonTable from "../../components/CommonTable/cop";
import { URL_DASHBOARD } from "../../constants/url";
import TeamMember from "../common/TeamMember";
import {
  mockData,
  RECENT_ACTIVITY_COLS,
  recentActivityCols,
} from "./recentActivityCols";
import { useState } from "react";
import { isSortedByKey, sortByKey } from "../../utils/object/sortByKey";
import { CHART_DURATION, SORT_ORDER } from "../../constants";
import { CHART_DURATION_OPTIONS, CHART_DURATION_PERIOD_OPTIONS } from "../../constants/options";

export default function TeamActivity() {
  const navigate = useNavigate();
  const [activities, setActivities] = useState(mockData);
  const [periodType, setPeriodType] = useState(CHART_DURATION.MONTH)

  const chartDurationPeriodOption = CHART_DURATION_PERIOD_OPTIONS[periodType]

  const [selectedPeriodOption, setSelectedPeriodOption] = useState(chartDurationPeriodOption[0].value)

  const setChartDurationType = (periodType) => {
    setPeriodType(periodType)
    const chartDurationPeriodOption = CHART_DURATION_PERIOD_OPTIONS[periodType]
    setSelectedPeriodOption(chartDurationPeriodOption[0].value)
  }


  const [isAscCols, setIsAscCols] = useState({
    [RECENT_ACTIVITY_COLS.GROUP_NAME]: true,
    [RECENT_ACTIVITY_COLS.COMPANY_NAME]: true,
    [RECENT_ACTIVITY_COLS.FISCAL_PERIOD]: true,
    [RECENT_ACTIVITY_COLS.UPDATED_AT]: true,
    [RECENT_ACTIVITY_COLS.CREATED_BY]: true,
  });

  const datasets = [
    {
      label: "",
      data: [30, 45, 60, 40, 70, 90, 20],
      borderColor: "rgba(0, 0, 0, 0.11)",
      backgroundColor: "rgba(0, 0, 0, 0.11)",
      fill: true,
      tension: 0.4,
      hoverBackgroundColor: "#023972",
      borderRadius: 4,
    },
  ];
  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];

  const handleSort = (colName) => {
    const currentAscCols = isAscCols;
    setIsAscCols((prev) => ({
      ...prev,
      [colName]: !prev[colName],
    }));
    if (
      isSortedByKey(
        activities,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    ) {
      return;
    }
    setActivities(
      sortByKey(
        activities,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    );
  };

  return (
    <div className=" 2xl:px-16 sm:px-[48px] relative min-h-[calc(100vh-70px-16px)] flex flex-col overflow-hidden">
      <div className="py-[2.5rem] flex gap-[1rem]">
        <img
          src={backIcon}
          alt="back-button"
          className="cursor-pointer"
          onClick={() => navigate(URL_DASHBOARD)}
        />
        <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
          Team Activity
        </span>
      </div>
      <div className="flex 2xl:gap-[1.5rem] sm:gap-[1.25rem] w-full">
        <div className="flex flex-col 2xl:gap-[1.5rem] sm:gap-[1.25rem] w-full">
          <BarChart
            datasets={datasets}
            labels={labels}
            displayLegend={false}
            title="Team’s Financial Assessments"
            duration={periodType}
            period={selectedPeriodOption}
            durationOptions={CHART_DURATION_OPTIONS}
            periodOptions={chartDurationPeriodOption}
            onDurationChange={setChartDurationType}
            onPeriodChange={(period) => {
              setSelectedPeriodOption(period)
            }}
          />

          <CommonTable
            minWidth={recentActivityCols.minWidth}
            columns={recentActivityCols.columns({ handleSort, isAscCols })}
            rowsData={activities}
            label="Recent Activity"
            titleClassname={"!text-[1.25rem]"}
          />
        </div>

        <TeamMember />
      </div>
      <div className="min-h-[16px]"></div>
    </div>
  );
}
