import { Outlet, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/Auth";
import Header from "../Header";
import Loader from "../Loader";
import { Navigate } from "react-router-dom";
import { URL_LOGIN } from "../../constants/url";
import PendingApplicationsProvider from "../../context/PendingApplications";
import AuthenticatedRouteProvider from "../../context/AuthenticatedRoute";

export default function AuthenticatedRoute({ Component, ...props }) {
  const { isAuthenticated, isLoading } = useAuthContext();
  const location = useLocation();

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  if (!isAuthenticated) {
    const currentPath = location.pathname + location.search;
    return <Navigate to={`${URL_LOGIN}?redirect=${currentPath}`} />;
  }

  return (
    <AuthenticatedRouteProvider>
      <PendingApplicationsProvider>
        <div className="max-w-[1900px] w-full flex flex-col items-center">
          <Header />
          <Outlet />
        </div>
      </PendingApplicationsProvider>
    </AuthenticatedRouteProvider>
  );
}
