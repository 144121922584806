import { useState } from "react";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import BaseButton from "../../components/BaseButton";
import CommonTable from "../../components/CommonTable/cop";
import SearchInput from "../../components/SearchInput";
import UserModal from "../../components/UserModal";
import { SORT_ORDER } from "../../constants";
import { URL_DASHBOARD } from "../../constants/url";
import { isSortedByKey, sortByKey } from "../../utils/object/sortByKey";
import { mockData, USER_COLS, userCols } from "./userColumn";
import Modal from "../../components/Modal";
import deleteConfirmImg from "../../assets/images/delete_confirm.png";

export default function ManageUser() {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [members, setMembers] = useState(mockData);
  const [isAscCols, setIsAscCols] = useState({
    [USER_COLS.NAME]: true,
    [USER_COLS.EMAIL]: true,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleToggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const handleOpenModalEdit = () => {
    handleToggleModal();
    setIsEdit((prev) => !prev);
  };

  const handleSort = (colName) => {
    const currentAscCols = isAscCols;
    setIsAscCols((prev) => ({
      ...prev,
      [colName]: !prev[colName],
    }));
    if (
      isSortedByKey(
        members,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    ) {
      return;
    }
    setMembers(
      sortByKey(
        members,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    );
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleDeleteUser = async () => {
    try {
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <div className="w-full max-w-default sm:px-[48px] 2xl:px-[64px] relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[2.5rem] flex-col gap-[1rem]">
        <div className="pb-[1rem] flex justify-between">
          <div className="flex gap-[1rem] flex-1 items-center">
            <img
              src={backIcon}
              alt="back-button"
              className="cursor-pointer"
              onClick={() => navigate(URL_DASHBOARD)}
            />
            <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
              Managing Users
            </span>
          </div>
          <div className="flex justify-center items-center gap-[1.25rem]">
            <SearchInput />
            <BaseButton
              title={"Create New Member"}
              btnClassname="!h-[3rem]"
              handleClick={handleToggleModal}
            />
          </div>
        </div>
      </div>
      <CommonTable
        minWidth={userCols.minWidth}
        columns={userCols.columns({
          handleEdit: handleOpenModalEdit,
          toggleDeleteModal,
          handleSort,
          isAscCols,
        })}
        rowsData={members}
        label="Financial Mapping"
      />
      {openModal && (
        <UserModal closeModal={handleToggleModal} isEdit={isEdit} />
      )}
      {showDeleteModal && (
        <Modal
          title={"Delete Confirmation!"}
          img={deleteConfirmImg}
          confirmText="Delete"
          cancelText="Cancel"
          onConfirm={handleDeleteUser}
          onClose={toggleDeleteModal}
          additionalContent={
            <div className="text-[#525252] text-[1.125rem] leading-[1.75rem] text-center">
              Deleting this user will remove it from your list. This action
              cannot be undone.
            </div>
          }
        />
      )}
    </div>
  );
}
