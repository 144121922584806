export default function debounce(func, delay) {
  let timeoutId;

  const debouncedFunction = (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
  
  debouncedFunction.cancel = () => {
    if (timeoutId) clearTimeout(timeoutId);
  };

  return debouncedFunction
}