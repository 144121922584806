import { addPercentage, convertAmountToPercentage, negativeBrackets, roundDecimal } from "../utils/formula/format_amount"
import formatNumber from "../utils/number/formatNumber"
import processNumber from "../utils/number/process_number"

export const FORMAT_NUMBER_TYPE = {
  ROUND_DECIMAL: 'ROUND_DECIMAL',
  NEGATIVE_BRACKETS: 'NEGATIVE_BRACKETS',
  FORMAT_NUMBER: 'FORMAT_NUMBER',
  PERCENTAGE: 'PERCENTAGE',
  PERCENTAGE_CONVERSION: 'PERCENTAGE_CONVERSION'
}

export const FORMAT_NUMBER_FORMULA_MAP = {
  [FORMAT_NUMBER_TYPE.ROUND_DECIMAL]: roundDecimal,
  [FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS]: negativeBrackets,
  [FORMAT_NUMBER_TYPE.FORMAT_NUMBER]: processNumber.bind(null, formatNumber),
  [FORMAT_NUMBER_TYPE.PERCENTAGE]: addPercentage,
  [FORMAT_NUMBER_TYPE.PERCENTAGE_CONVERSION]: convertAmountToPercentage
}