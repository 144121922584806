export default function generateHighlightDiv ({ width, height, left = 0, top = 0 }) {
  const highlightDiv = document.createElement("span");
  highlightDiv.style.position = "absolute";
  highlightDiv.className =
    "border-2 border-solid border-blue-600 bg-blue-600/[.08]";
  highlightDiv.style.padding = `6px`;
  highlightDiv.style.left = `${left - 4}px`;
  highlightDiv.style.top = `${top - 4}px`;
  highlightDiv.style.width = `${width + 12}px`;

  highlightDiv.style.height = `${height + 4}px`;
  highlightDiv.style.display = "none";

  return highlightDiv;
};