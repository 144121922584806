import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const DATE_FORMAT_DDMMMYYYY = "D MMMM YYYY";

export const stringToDateFormat = (
  dateString = "",
  format = DATE_FORMAT_DDMMMYYYY
) => {
  if (!dateString) {
    return "";
  }

  const date = dayjs.utc(dateString);

  if (!date.isValid()) {
    return '';
  }

  return date.local().format(format)
};
