import "chart.js/auto"; // Importing the chart.js defaults
import { Bar } from "react-chartjs-2";
import TimeControlChart from "../TimeControlChart";

export default function BarChart({
  title,
  datasets,
  labels,
  displayLegend = true,
  legendPosition = "top",
  customLegend = <></>,
  duration,
  period,
  durationOptions,
  periodOptions,
  onDurationChange,
  onPeriodChange,
}) {
  const combinedData = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart to adapt to container size
    plugins: {
      legend: {
        display: displayLegend,
        position: legendPosition,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => value, // Format the y-axis labels for cost charts
        },
      },
    },
  };

  return (
    <div className="bg-[#fff] flex flex-col 2xl:p-[1.5rem] sm:p-[20px] gap-[1.5rem] items-stretch rounded-[20px]">
      <TimeControlChart
        title={title}
        handleSelectDuration={onDurationChange}
        handleSelectPeriod={onPeriodChange}
        duration={duration}
        period={period}
        durationOptions={durationOptions}
        periodOptions={periodOptions}
      />
      <div className="w-full 2xl:h-[266px] sm:h-[374px]">
        <Bar data={combinedData} options={options} />
      </div>
      {customLegend}
    </div>
  );
}
