import { SORT_ORDER } from "../../constants";

export const sortByKey = (array, key, order = SORT_ORDER.ASC) => {
  return array.sort((a, b) => {
    // Check if we want ASC or DESC order
    if (order === SORT_ORDER.ASC) {
      return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
    } else if (order === SORT_ORDER.DESC) {
      return a[key] < b[key] ? 1 : a[key] > b[key] ? -1 : 0;
    }
  });
};

export const isSortedByKey = (array, key, order = SORT_ORDER.ASC) => {
  for (let i = 0; i < array.length - 1; i++) {
    if (order === SORT_ORDER.ASC) {
      // Check if the current item is greater than the next one
      if (array[i][key] > array[i + 1][key]) {
        return false; // Not sorted in ascending order
      }
    } else if (order === SORT_ORDER.DESC) {
      // Check if the current item is smaller than the next one
      if (array[i][key] < array[i + 1][key]) {
        return false; // Not sorted in descending order
      }
    }
  }
  return true; // Sorted in the specified order
};
