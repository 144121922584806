import { FORMAT_NUMBER_EXCEL_MAP } from "../../constants/excel"

export default function mergeNumberFormat(initialNumberFormat, formatTypesWithParameters) {
  return formatTypesWithParameters.reduce((currentFormat, formatTypeWithParameters) => {
    const [formatType, parameters = []] = formatTypeWithParameters.split(':')
    let formatFunction = FORMAT_NUMBER_EXCEL_MAP[formatType]

    if (!formatFunction) {
      return currentFormat
    }

    return formatFunction(...parameters, currentFormat)
  }, initialNumberFormat)
}