import React, { useEffect, useRef } from "react";

const Popover = ({ position = {}, closePopover, children, className = "" }) => {
  const popoverRef = useRef();

  // Handle outside click to close popover
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        closePopover(); // Close popover when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closePopover]);

  return (
    <div className="absolute z-[100] w-[20px]">
      <div className="relative">
        <div
          ref={popoverRef}
          style={{
            position: "fixed",
            top: position.top + position.height,
            left: position.left,
          }}
          className={`z-50 p-[1rem] rounded-[16px] shadow-popover bg-white ${className}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popover;
