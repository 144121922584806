import { Link } from "react-router-dom";
import auroraLogo from "../../assets/images/aurora_logo.svg";
import error404 from "../../assets/images/error-404.png";
import trustplusLogo from "../../assets/images/trustplus_logo.svg";
import { URL_DASHBOARD } from "../../constants/url";

export default function NotFound() {
  return (
    <div className="w-full max-w-default 2xl:px-16 sm:px-[48px] relative min-h-[100vh] flex flex-col">
      <img
        src={trustplusLogo}
        className="w-[215px] h-[32px] absolute top-[52px] left-[64px]"
        alt="Trustplus"
      />
      <div className="w-full absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] p-[2rem] flex flex-col justify-center items-center">
        <img
          src={auroraLogo}
          className="w-[300x] h-[83px] pb-24px"
          alt="Aurora"
        />
        <img src={error404} className="w-[440px] h-[440px]" alt="Not Found" />
        <span className="text-[2.75rem] font-[700] leading-[3rem] tracking-[-1.76px] text-primary-strong mb-[20px]">
          The Page You’re Looking For Can’t Be Found
        </span>
        <div>
          <span class="block text-neutral-medium text-[1.5rem] font-[500] leading-[2rem] tracking-[-0.72px] text-center">
            We can’t seem to find the page you’re looking for. It might have
            been removed, had
            <br /> its name changed, or is temporarily unavailable. Please check
            the URL for errors, or
            <br /> click below to return to our homepage
          </span>
        </div>
        <Link to={URL_DASHBOARD}>
          <button className="primary w-[278px] mt-[44px]">
            Go to Homepage
          </button>
        </Link>
      </div>
    </div>
  );
}
