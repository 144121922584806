import { useNavigate } from "react-router-dom";
import trusplus from "../../assets/images/trustplus_logo.svg";
import PendingApplicationsMenu from "./PendingApplicationsMenu";
import ProfileMenu from "./ProfileMenu";
import { URL_DASHBOARD } from "../../constants/url";

export default function Header() {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between h-header border-b 2xl:px-16 sm:px-[48px] w-full">
      <img
        src={trusplus}
        className="w-[176px] h-auto cursor-pointer"
        alt="Aurora Plus"
        onClick={() => navigate(URL_DASHBOARD)}
      />
      <div className="flex items-center">
        <PendingApplicationsMenu />
        <ProfileMenu />
      </div>
    </div>
  );
}
