import { useEffect, useState } from "react";

import arrow from "../../assets/icons/keyboard_arrow_up.png";

const Collapse = ({ panel, title }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  const toggleCollapse = (event) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  return (
    <div
      className="bg-white px-[1.5rem] py-[0.5rem] rounded-[20px] border-[1px] border-card-on-surface"
      data-accordion="collapse"
    >
      <div
        className="w-full flex justify-between select-none cursor-pointer items-center"
        data-collapse-target="collapse"
        onClick={toggleCollapse}
      >
        <span className="flex justify-center items-center text-[1.125rem] font-[700] leading-[1.5rem] tracking-[-0.54px]">
          {title}
        </span>
        <img
          alt=""
          src={arrow}
          className={`w-[24px] h-[24px] ${isOpen ? "" : "rotate-180"}`}
        />
      </div>
      {isOpen && (
        <div
          data-collapse="collapse"
          className={`w-full basis-full overflow-hidden transition-all duration-300 ease-in-out`}
          onClick={(event) => event.stopPropagation()}
        >
          {panel}
        </div>
      )}
    </div>
  );
};

export default Collapse;
