import { useEffect, useRef, useState } from "react";
import defaultAvatarImage from "../../assets/images/default_avatar.png";
import accountIcon from "../../assets/icons/icon_account.svg";
import logoutIcon from "../../assets/icons/icon_logout.svg";
import mailIcon from "../../assets/icons/icon_mail.svg";
import { useAuthContext } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import { URL_CONTACT_US, URL_USER_PROFILE } from "../../constants/url";

export default function ProfileMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { handleLogout } = useAuthContext();
  const navigate = useNavigate();

  const MenuList = [
    {
      icon: accountIcon,
      children: "User Profile",
      onClick: () => {
        navigate(URL_USER_PROFILE);
      },
    },
    {
      icon: mailIcon,
      children: "Contact Us",
      onClick: () => {
        navigate(URL_CONTACT_US);
      },
    },
    {
      icon: logoutIcon,
      children: "Logout",
      onClick: handleLogout,
    },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="pl-6 relative" ref={dropdownRef}>
      <img
        src={defaultAvatarImage}
        alt="Profile"
        className="w-10 h-10 rounded-full cursor-pointer"
        onClick={toggleDropdown}
      />
      {isOpen && (
        <div className="z-50 absolute right-0 top-full bg-white border rounded-2xl w-48 flex flex-col text-bg-neutral-600 mt-3">
          <div className="px-3 py-2 text-xs">Account</div>
          {MenuList.map(({ icon, children, onClick }) => {
            return (
              <button
                className="flex px-3 py-2 items-center flex-row text-sm hover:bg-gray-100"
                onClick={onClick}
              >
                <img
                  src={icon}
                  alt={children}
                  className="w-10 h-10 rounded-full cursor-pointer pr-4"
                />
                <div>{children}</div>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
