import PropTypes from "prop-types";
import Dropdowns from "../Dropdowns";

TimeControlChart.propTypes = {
  title: PropTypes.string.isRequired,
  handleSelectDuration: PropTypes.func.isRequired,
  selectedTime: PropTypes.any.isRequired,
};

export const timeOptions = [
  {
    label: "Last 12 months",
    value: "Last 12 months",
  },
  {
    label: "Last 6 months",
    value: "Last 6 months",
  },
];

export default function TimeControlChart({
  title,
  handleSelectDuration,
  handleSelectPeriod,
  duration,
  period,
  durationOptions,
  periodOptions,
}) {
  return (
    <div className="flex gap-[0.5rem] justify-between">
      <div className="text-[#121212] leading-[1.75rem] text-[1.25rem] font-bold">
        {title}
      </div>
      <div className="flex items-center gap-[1.25rem]">
        <div className="flex p-[4px] rounded-[1000px] bg-[rgba(0,0,0,0.06)] h-[44px] text-[0.875rem] font-semibold leading-[1.25rem] tracking-[-0.28px]">
          {durationOptions.map(({ value, label }) => {
            return (
              <div
                className={`flex justify-center items-center p-[1rem] gap-[0.5rem] cursor-pointer ${
                  duration === value
                    ? "text-[#121212] rounded-[1000px] bg-[#fff] border-[0.5px] border-[#E5E5E5]"
                    : "text-[#525252]"
                }`}
                onClick={() => handleSelectDuration(value)}
              >
                {label}
              </div>
            );
          })}
        </div>
        <Dropdowns
          data={periodOptions}
          className={"flex-1"}
          defaultValue={period}
          value={period}
          onChange={handleSelectPeriod}
        />
      </div>
    </div>
  );
}
