import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import Preview from "../../components/Preview";
import Upload from "../../components/Upload";
import { FILE_TYPE_PDF } from "../../constants/file";
import {
  API_APPLICATIONS,
  API_GET_COMPANIES,
  API_SEARCH_COMPANIES,
  URL_APPLICATION_VALIDATE,
  URL_DASHBOARD,
} from "../../constants/url";
import { useApplicationContext } from "../../context/Application";
import { useLoader } from "../../context/Loader";
import { useToaster } from "../../context/Toaster";
import { MAX_DOCUMENT_UPLOAD_FILE_SIZE, TOAST_TYPE, UNAUDITED_VALUE } from "../../constants";
import FinancialSpreadingForm from "../../components/FinancialSpreadingForm";
import { usePendingApplications } from "../../context/PendingApplications";
import { useDebounce } from "../../hooks/useDebounce";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

export default function FinancialSpreadingUploadPage() {
  const { company_id } = useParams();
  const [file, setFile] = useState();

  const [formData, setFormData] = useState({
    audited: UNAUDITED_VALUE,
  });
  const [companyData, setCompanyData] = useState();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const { setLoading, setLoaderContent } = useLoader();
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const { addPendingApplication } = usePendingApplications();
  const debouncedGroupName = useDebounce(formData.group_name, 200);
  const [existingCompanies, setExistingCompanies] = useState([]);
  const [groups, setGroups] = useState([]);
  const [hasSearchedGroupName, setHasSearchGroupName] = useState(false);
  const authenticatedFetch = useAuthenticatedFetch()
  const location = useLocation()
  const redirectedToHome = useRef(false)

  const fetchCompanies = async (groupName) => {
    if (!groupName)
      return

    try {
      const {
        data,
      } = await authenticatedFetch(`${API_SEARCH_COMPANIES}?group_name=${groupName}`);

      setExistingCompanies(data);

      const uniqueGroups = [
        ...new Set(data.map(({ group_name }) => group_name)),
      ];
      setGroups(
        uniqueGroups.map((groupName) => ({
          value: groupName,
          label: groupName,
        }))
      );

      setHasSearchGroupName(true);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  useEffect(() => {
    fetchCompanies(debouncedGroupName);
  }, [debouncedGroupName]);

  const goToDashboard = () => {
    navigate(URL_DASHBOARD);
  };
  const { currencyOptions } = useApplicationContext();

  const handleFinancialSpreadingFormChange = (formData) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      ...formData,
    }));
  };

  const handleBackToHome = () => {
    redirectedToHome.current = true
    setLoading(false);
    navigate(URL_DASHBOARD);
  };

  useEffect(() => {
    if (company_id) {
      setLoading(true);
      authenticatedFetch(`${API_GET_COMPANIES}/${company_id}?include_detail=false`)
        .then(({ data }) => {
          const {
            company_name,
            group_name,
            doc_currency,
            out_currency,
            exchange_rate,
          } = data;
          setCompanyData(data);
          setFormData({
            company_name,
            group_name,
            doc_currency,
            out_currency,
            exchange_rate,
            audited: UNAUDITED_VALUE,
          });
        })
        .catch((err) => {
            if (!err.message) {
              return
            }

          showToast('An unexpected error occured', TOAST_TYPE.ERROR)
          if (location.key === "default") {
            navigate(URL_DASHBOARD);
          } else {
            navigate(-1);
          }
          console.error('err:', err)
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [company_id]);

  const loadingContent = () => {
    return (
      <>
        <div className="text-[#121212] text-center font-medium text-[1.125rem] leading-[1.75rem] w-[565px]">
          We’re processing your request, it’ll just take a few minutes. You’ll
          receive an email when we’re done. Feel free to stick around or head
          back to Home to start another task. Thanks for waiting!
        </div>
        <div className="flex flex-col align-center justify-center h-[48px] rounded-[8px] py-[0.75rem] px-[20px] bg-gradient-to-r from-[#023972] to-[#356CA5]">
          <div
            className="text-[#fff] text-base font-semibold tracking-[-0.48px] cursor-pointer"
            onClick={() => {
              handleBackToHome();
            }}
          >
            Back to Home
          </div>
        </div>
      </>
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const request = new FormData();

    let submittedFormData = { ...formData };
    if (companyData) {
      submittedFormData.company_name = companyData.company_name;
      submittedFormData.group_name = companyData.group_name;
    }

    Object.keys(formData).forEach((fieldName) => {
      const fieldValue = formData[fieldName];
      request.append(fieldName, fieldValue);
    });

    request.append("file", file);
    if (company_id) request.append("company_id", company_id);
    setLoaderContent(loadingContent);
    addPendingApplication(submittedFormData.company_name);

    return authenticatedFetch(API_APPLICATIONS, {
      data: request,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: 'POST',
    })
      .then(({ data }) => {
        if (redirectedToHome.current) {
          showToast(`Your application for ${submittedFormData.company_name} is done being processed`, TOAST_TYPE.SUCCESS)
          return
        }
        navigate(`${URL_APPLICATION_VALIDATE}/${data.id}`);
      })
      .catch((err) => {
        console.error("err:", err);
        showToast(
          `Unexpected error when uploading file for ${submittedFormData.company_name}. please try again later`,
          TOAST_TYPE.ERROR,
          "",
          0
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const companyReferenceList = existingCompanies
    .filter(({ group_name }) => group_name === formData.group_name)
    .map(({ company_name }) => company_name);

  return (
    <div
      className={`w-full max-w-default px-[4rem] relative flex flex-col items-center`}
    >
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col items-center"
      >
        <div className="flex items-center justify-between w-full">
          <div className="py-[2.5rem] flex gap-[1rem] items-center">
            <img
              src={backIcon}
              alt="back-button"
              className="cursor-pointer"
              onClick={goToDashboard}
            />
            <span className="text-gradient-aurora-blue text-[2rem] font-[700] 2xl:leading-[2.5rem] tracking-[-0.06rem]">
              Create New Company
            </span>
          </div>
          <button
            className="w-[4.5rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem] primary font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] text-white flex justify-center items-center"
            type="submit"
            onClick={() => setIsSubmit(true)}
            disabled={!file || !isFormValid}
          >
            Next
          </button>
        </div>
        <FinancialSpreadingForm
          form={formData}
          initialValue={companyData}
          currencyOptions={currencyOptions}
          onFormChange={handleFinancialSpreadingFormChange}
          companies={companyReferenceList}
          groups={groups}
          isSubmit={isSubmit}
          onFormValidChange={setIsFormValid}
          hasSearchedGroupName={hasSearchedGroupName}
        />
      </form>
      <div className="mt-[2rem] flex flex-col gap-[0.5rem] p-[2rem] bg-white rounded-[20px] w-full max-w-[1186px]">
        <span className="leading-[1.25rem] tracking-[-0.28px] font-[500] text-[0.875rem]">
          Upload Financial Statement
        </span>
        <Upload
          onFileSelect={setFile}
          file={file}
          accept={[FILE_TYPE_PDF]}
          acceptLabel="1 PDF file only. "
          maxSize={MAX_DOCUMENT_UPLOAD_FILE_SIZE}
        />
      </div>
      {file && (
        <div className="p-[2rem] mt-[2rem] bg-white rounded-[20px] w-full max-w-[1186px]">
          <Preview file={file} />
        </div>
      )}
      <div className="min-h-[2rem]"></div>
    </div>
  );
}
