import { SORT_TIME_OPTIONS } from "../../constants/options";
import Checkbox from "../Checkbox";
import Collapse from "../Collapse";
import Dropdowns from "../Dropdowns";
import { FORM_FIELDS } from "./ViewFinancialResult";

const SortFilterCollapse = ({
  formData,
  handleDropdownChange,
  setHiddenPeriods,
  fiscal_period_order,
  setHiddenPeriodByIndex,
  hiddenPeriods,
}) => {
  return (
    <Collapse
      title="Sort & Filter"
      panel={
        <div
          className="w-full flex flex-col gap-[2rem] pt-[22px]"
          onClick={(event) => event.stopPropagation()}
        >
          <Dropdowns
            label="Sort by"
            data={SORT_TIME_OPTIONS}
            defaultValue={formData[FORM_FIELDS.sort_by]}
            value={formData[FORM_FIELDS.sort_by]}
            onChange={handleDropdownChange}
            onClick={(event) => {
              event.stopPropagation();
            }}
            id={FORM_FIELDS.sort_by}
            labelClassName="text-[1rem] leading-[1.5rem] tracking-[-0.32px] font-[600] text-neutral-strong mb-[10px] block"
            dropdownDataClassname="!font-normal"
          />
          <div className="flex flex-col gap-[1rem] items-start w-full">
            <div className="flex justify-between items-center w-full">
              <span className="text-[1rem] leading-[1.5rem] tracking-[-0.32px] font-[600] text-neutral-strong flex-1">
                Show & Hide column
              </span>
              <div className="flex items-center justify-between gap-[1.25rem] w-fit">
                <span
                  className="select-none cursor-pointer text-[0.875rem] font-[600] leading-[1.5rem] tracking-[-0.28px] text-neutral-medium"
                  onClick={(event) => {
                    event.stopPropagation();
                    setHiddenPeriods(fiscal_period_order.map(() => true));
                  }}
                >
                  Clear All
                </span>
                <span
                  className="select-none cursor-pointer text-[0.875rem] font-[600] leading-[1.5rem] tracking-[-0.28px] text-primary-1"
                  onClick={(event) => {
                    event.stopPropagation();
                    setHiddenPeriods([]);
                  }}
                >
                  Select All
                </span>
              </div>
            </div>
            <div
              className="flex gap-[1rem] items-center justify-start w-full flex-wrap"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {fiscal_period_order.map((time, index) => (
                <Checkbox
                  onChange={setHiddenPeriodByIndex.bind(null, index)}
                  className="min-w-[150px]"
                  title={time}
                  key={time}
                  isChecked={!hiddenPeriods[index]}
                />
              ))}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default SortFilterCollapse;
