// Predefined color array
export const predefinedColors = [
  "#F65737",
  "#6366F1",
  "#93BBFD",
  "#45B441",
  "#2570EB",
];

export const getRandomColor = () => {
  const randomColor = () => Math.floor(Math.random() * 255);
  return `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`;
};
