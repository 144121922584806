import React, { useState } from 'react';
import AppRoutes from './Routes';
import ErrorBoundary from './pages/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
        <AppRoutes />
    </ErrorBoundary>
  );
}

export default App;