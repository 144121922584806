import { useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import hidePasswordIcon from "../../assets/icons/icon_eye_closed.svg";
import showPasswordIcon from "../../assets/icons/icon_eye_open.svg";
import aurora from "../../assets/images/aurora_logo.svg";
import TextInput from "../../components/TextInput";
import { TOAST_TYPE } from "../../constants";
import { API_RESET_PASSWORD, URL_LOGIN } from "../../constants/url";
import { useLoader } from "../../context/Loader";
import { useToaster } from "../../context/Toaster";
import "./resetpassword.css";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

const FIELDS = {
  password: "password",
  confirm_password: "confirm_password",
};

const REQUIRED_FIELDS = Object.values(FIELDS);

const initialFormValue = REQUIRED_FIELDS.reduce((formValue, fieldName) => {
  return {
    ...formValue,
    [fieldName]: "",
  };
}, {});

export default function ResetPassword() {
  const [formData, setFormData] = useState(initialFormValue);
  const isPasswordMatch = formData.password === formData.confirm_password;
  const [showPassword, setShowPassword] = useState({
    [FIELDS.password]: false,
    [FIELDS.password]: false,
  });
  const [searchParams] = useSearchParams();
  const { setLoading } = useLoader();
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const authenticatedFetch = useAuthenticatedFetch()

  const token = searchParams.get("token");

  if (!token) {
    return <Navigate to={URL_LOGIN} />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const request = {
      token,
      password: formData[FIELDS.password],
    };

    setLoading(true);
    return authenticatedFetch(API_RESET_PASSWORD, {
      data: request, 
      method: 'POST',
    })
      .then(() => {
        navigate(URL_LOGIN);
      })
      .catch((err) => {
        const errMsg = err.response?.data?.detail || "Internal Server Error";
        showToast(errMsg, TOAST_TYPE.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = (type) => {
    setShowPassword((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const isDisabledSubmitButton =
    REQUIRED_FIELDS.some((fieldName) => !formData[fieldName]) ||
    formData[FIELDS.confirm_password] !== formData[FIELDS.password];

  return (
    <div className="resetpassword-container">
      <img
        src={aurora}
        alt="Login Logo"
        className="resetpassword-aurora-logo"
      />
      <div className="line" />
      <div>
        <span className="text-gradient-aurora-blue resetpassword-title">
          Reset Password
        </span>
      </div>
      <div className="resetpassword-content-container">
        <form onSubmit={handleSubmit} className="text-left">
          <div className="input-container">
            <TextInput
              id={FIELDS.password}
              label="New Password"
              type={showPassword[FIELDS.password] ? "text" : "password"}
              placeholder="Enter your new password"
              className="pb-6"
              onChange={handleChange}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button
                type="button"
                onClick={() => {
                  togglePasswordVisibility(FIELDS.password);
                }}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {showPassword[FIELDS.password] ? (
                  <img src={hidePasswordIcon} alt="hide password" />
                ) : (
                  <img src={showPasswordIcon} alt="show password" />
                )}
              </button>
            </div>
          </div>
          <div className="input-container">
            <TextInput
              id={FIELDS.confirm_password}
              label="Confirm New Password"
              type={showPassword[FIELDS.confirm_password] ? "text" : "password"}
              placeholder="Enter your new password"
              onChange={handleChange}
              className="pb-6"
              errorMessage={
                !isPasswordMatch && formData[FIELDS.confirm_password]
                  ? "Password does not match"
                  : null
              }
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button
                type="button"
                onClick={() => {
                  togglePasswordVisibility(FIELDS.confirm_password);
                }}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {showPassword[FIELDS.confirm_password] ? (
                  <img src={hidePasswordIcon} alt="hide password" />
                ) : (
                  <img src={showPasswordIcon} alt="show password" />
                )}
              </button>
            </div>
          </div>
          <div className="pt-[20px]">
            <button
              type="submit"
              className={`primary w-full text-white`}
              disabled={isDisabledSubmitButton}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
