import { useEffect, useMemo, useState } from "react";
import backIcon from "../../assets/icons/icon_back.svg";
import BarChart from "../../components/BarChart";

import { useNavigate } from "react-router-dom";
import CommonTable from "../../components/CommonTable/cop";
import { API_GET_COMPANY_SUMMARY, API_SEARCH_COMPANIES, URL_DASHBOARD } from "../../constants/url";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";
import {
  mockData,
  PORTFOLIO_OVERVIEW_COLS,
  dueReviewPortfolioCols,
  watchlistPortfolioCols,
} from "./portfolioCols";
import { isSortedByKey, sortByKey } from "../../utils/object/sortByKey";
import { useLoader } from "../../context/Loader";
import { CHART_DURATION_OPTIONS, CHART_DURATION_PERIOD_OPTIONS, MONTH_CHART_DURATION_PERIOD_OPTIONS, SORT_TYPES } from "../../constants/options";
import { PERFORMANCE_CLASSIFICATION_TYPES } from "../../constants/types";
import SearchInput from "../../components/SearchInput";
import GroupedAccordion from "../../components/GroupedAccordion";
import { CHART_DURATION } from "../../constants";

export default function OverallPortfolio() {
  const navigate = useNavigate();
  const [isAscCols, setIsAscCols] = useState({
    [PORTFOLIO_OVERVIEW_COLS.MONTH]: true,
    [PORTFOLIO_OVERVIEW_COLS.NUMBER_OF_GROUP]: true,
    [PORTFOLIO_OVERVIEW_COLS.NUMBER_OF_COMPANY]: true,
  });
  const { setLoading } = useLoader();

  const [dueReviewCompanies, setDueReviewCompanies] = useState([])
  const [watchListedCompanies, setWatchlistedCompanies] = useState([])
  const [activeCompanies, setActiveCompanies] = useState([])
  const [groupedCompanies, setGroupedCompanies] = useState([])
  const [expandedGroup, setExpandedTeam] = useState()
  const [periodType, setPeriodType] = useState(CHART_DURATION.MONTH)
  const [companyKeyword, setCompanyKeyword] = useState('')
  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([])

  const chartDurationPeriodOption = CHART_DURATION_PERIOD_OPTIONS[periodType]

  const [selectedPeriodOption, setSelectedPeriodOption] = useState(chartDurationPeriodOption[0].value)

  const setChartDurationType = (periodType) => {
    setPeriodType(periodType)
    const chartDurationPeriodOption = CHART_DURATION_PERIOD_OPTIONS[periodType]
    setSelectedPeriodOption(chartDurationPeriodOption[0].value)
  }

  const toggleTeam = (groupName) => {
    setExpandedTeam(expandedGroup === groupName ? null : groupName);
  };

  const authenticatedFetch = useAuthenticatedFetch()

  const [portfolio, setPortfolio] = useState(mockData);

  const groupCompanies = (companies) => {
   return companies.reduce((groupedData, companyData) => {
      const { group_name, company_name } = companyData

      const currentGroupData = groupedData[group_name]

      if (!currentGroupData) {
        return {
          ...groupedData,
          [group_name]: {
            group_name,
            companies: [{
              name: company_name
            }]
          }
        }
      }

      const { companies: newCompanies = [] } = currentGroupData

      newCompanies.push({
        name: company_name,
      })

      return {
        ...groupedData,
        [group_name]: {
          ...currentGroupData,
          companies: newCompanies,
        }
      }



    }, {})
  }

  useEffect(() => {
    const groupedCompanies = groupCompanies(activeCompanies.filter(({ group_name, company_name }) => 
      group_name?.toLowerCase().includes(companyKeyword.toLowerCase()) || company_name?.toLowerCase().includes(companyKeyword.toLowerCase())
    ))

    setGroupedCompanies(Object.values(groupedCompanies))
  }, [companyKeyword, activeCompanies])

  const fetchAllActiveCompanies = async () => {
    const params = {
      sort_by: "updated_at", 
    }

    const res = await authenticatedFetch(`${API_SEARCH_COMPANIES}`, {
      params,
    });

    const groupedActiveCompanies = groupCompanies(res.data)

    setActiveCompanies(res.data)
    setGroupedCompanies(Object.values(groupedActiveCompanies))
  }

  const fetchActiveCompaniesInPeriod = async (periodType, selectedPeriodOption) => {
    const confirmStartDate = new Date();

    switch (periodType) {
      case CHART_DURATION.MONTH: {
        confirmStartDate.setMonth(confirmStartDate.getMonth() - selectedPeriodOption, 0);
        break;
      }
      default: {
        // weeks
        confirmStartDate.setDate(confirmStartDate.getDate() - (7 * selectedPeriodOption));

      }
    }

    const params = {
      confirm_start_date: confirmStartDate.toISOString().split('T')[0], 
    }

    const res = await authenticatedFetch(API_GET_COMPANY_SUMMARY, {
      params,
    });
    
  const { labels, totalGroupData, totalCompanyData } = res.data.reduce(({ labels, totalGroupData, totalCompanyData }, { key, group_total, company_total }) => {
    const newLabel = [...labels, key]
    const newTotalGroupData = [...totalGroupData, group_total]
    const newTotalCompanyData = [...totalCompanyData, company_total]

    return {
      labels: newLabel,
      totalGroupData: newTotalGroupData,
      totalCompanyData: newTotalCompanyData
    }
  }, {
    labels: [],
    totalGroupData: [],
    totalCompanyData: [],
  })

  const datasets = [
    {
      label: "Group",
      data: totalGroupData,
      borderColor: "rgba(0, 0, 0, 0.11)",
      backgroundColor: "rgba(0, 0, 0, 0.11)",
      fill: true,
      tension: 0.4,
      hoverBackgroundColor: "#45B441",
      borderRadius: 4,
    },
    {
      label: "Company",
      data: totalCompanyData,
      borderColor: "rgba(0, 0, 0, 0.11)",
      backgroundColor: "rgba(0, 0, 0, 0.11)",
      fill: true,
      tension: 0.4,
      hoverBackgroundColor: "#023972",
      borderRadius: 4,
    },
  ];

    setLabels(labels)
    setDatasets(datasets)
  }

  const fetchDueCompanies = async () => {
    try {
      const dueReviewDate = new Date();
      dueReviewDate.setMonth(dueReviewDate.getMonth() + 3, 0)

      const limit = 15
      const res = await authenticatedFetch(`${API_SEARCH_COMPANIES}`, {
        params: {
          next_review_end_date: dueReviewDate.toISOString(),
          limit,
          order_by: SORT_TYPES.ASC,
          sort_by: 'next_review_date',
        }
      });
      
      setDueReviewCompanies(res.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchWatchlistedCompanies = async () => {
    try {
      const res = await authenticatedFetch(`${API_SEARCH_COMPANIES}`, {
        params: {
          performances: PERFORMANCE_CLASSIFICATION_TYPES.WATCHLIST,
        }
      });
      
      setWatchlistedCompanies(res.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchDueCompanies(),
      fetchWatchlistedCompanies(),
      fetchAllActiveCompanies()
    ]).finally(() => {
      setLoading(false);
    })
  }, [])

  useEffect(() => {
    fetchActiveCompaniesInPeriod(periodType, selectedPeriodOption)
  }, [periodType, selectedPeriodOption])


  const handleSort = (colName) => {
    const currentAscCols = isAscCols;
    setIsAscCols((prev) => ({
      ...prev,
      [colName]: !prev[colName],
    }));
    if (
      isSortedByKey(
        portfolio,
        colName,
        !currentAscCols[colName] ? SORT_TYPES.ASC : SORT_TYPES.DESC
      )
    ) {
      return;
    }
    setPortfolio(
      sortByKey(
        portfolio,
        colName,
        !currentAscCols[colName] ? SORT_TYPES.ASC : SORT_TYPES.DESC
      )
    );
  };

  const customLegend = useMemo(() => {
    return (
      <div className="flex gap-[1.5rem]">
        <div className="flex items-center gap-[4px]">
          <div className="w-[12px] h-[12px] rounded-[100px] bg-[#45B441]"></div>
          <div className="text-[#525252] text-[0.875rem] font-[450] leading-[1.25rem] tracking-[-0.28px]">
            Group
          </div>
        </div>
        <div className="flex items-center gap-[4px]">
          <div className="w-[12px] h-[12px] rounded-[100px] bg-[#023972]"></div>
          <div className="text-[#525252] text-[0.875rem] font-[450] leading-[1.25rem] tracking-[-0.28px]">
            Company
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="overflow-hidden 2xl:px-16 sm:px-[48px] relative min-h-[calc(100vh-70px-16px)] flex flex-col">
      <div className="py-[2.5rem] flex gap-[1rem]">
        <img
          src={backIcon}
          alt="back-button"
          className="cursor-pointer"
          onClick={() => navigate(URL_DASHBOARD)}
        />
        <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
          Overall Portfolio
        </span>
      </div>
      <div className="flex 2xl:gap-[1.5rem] sm:gap-[1.25rem] w-full">
        <div className="flex flex-col w-full 2xl:gap-[1.5rem] sm:gap-[1.25rem]">
          <BarChart
            title="Team Portfolio Overview"
            datasets={datasets}
            labels={labels}
            displayLegend={false}
            customLegend={customLegend}
            duration={periodType}
            period={selectedPeriodOption}
            durationOptions={CHART_DURATION_OPTIONS}
            periodOptions={chartDurationPeriodOption}
            onDurationChange={setChartDurationType}
            onPeriodChange={(period) => {
              setSelectedPeriodOption(period);
            }}
          />
          {/* Portfolio Overview */}
          <CommonTable
            minWidth={dueReviewPortfolioCols.minWidth}
            columns={dueReviewPortfolioCols.columns({ handleSort, isAscCols })}
            rowsData={dueReviewCompanies}
            label="Due for Review"
          />
          <CommonTable
            minWidth={watchlistPortfolioCols.minWidth}
            columns={watchlistPortfolioCols.columns({ handleSort, isAscCols })}
            rowsData={watchListedCompanies}
            label="Watchlist Overview"
          />
        </div>
        {/* team member */}
        <div className="w-full 2xl:max-w-[581px] sm:max-w-[381px] max-w-default transition-all mx-auto overflow-hidden flex h-full 2xl:p-[1.5rem] sm:p-[20px] flex-col gap-[0.5rem] rounded-[20px] bg-[#fff]">
          <div className="w-full 2xl:max-w-[581px] sm:max-w-[381px] max-w-default transition-all mx-auto overflow-hidden flex h-full 2xl:p-[1.5rem] sm:p-[20px] flex-col gap-[0.5rem] rounded-[20px] bg-[#fff]">
            <div className="flex justify-between items-center mt-[1rem] w-full min-w-[300px]">
              <h2 className="text-[#121212] font-bold text-[1.25rem] leading-[1.75rem] tracking-[-0.6px]">
                Companies
              </h2>
              <SearchInput
                containerClassname={"!mx-0 2xl:w-[200px] sm:w-[160px]"}
                onChange={(event) => setCompanyKeyword(event.target.value)}
              />
            </div>

            {groupedCompanies.map(({ group_name, companies }, index) => (
              <GroupedAccordion
                key={index}
                groupName={group_name}
                members={companies}
                isExpanded={expandedGroup === group_name}
                toggleTeam={() => toggleTeam(group_name)}
              />
            ))}
          </div>
        </div>

        {/* <TeamMember /> */}
      </div>
      <div className="min-h-[16px]"></div>
    </div>
  );
}
