import AuthProvider from "./Auth";
import LoaderProvider from "./Loader";
import ToasterProvider from "./Toaster";

export default function Providers({ children }) {
  return (
    <AuthProvider>
    <ToasterProvider>
      <LoaderProvider>
        {children}
      </LoaderProvider>
    </ToasterProvider>
    </AuthProvider>
  );
}
