import { useState } from "react";
import { useNavigate } from "react-router-dom";
import trustplusLogo from "../../assets/images/trustplus_logo.svg";
import { API_FORGOT_PASSWORD, URL_LOGIN } from "../../constants/url";
import { useLoader } from "../../context/Loader";
import "./index.css";
import RequestEmail from "./RequestEmail";
import ForgotPasswordResendEmail from "./ResendEmail";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

export default function ForgotPassword() {
  const { setLoading } = useLoader();
  const [step, setStep] = useState(0);
  const [requestedEmail, setRequestedEmail] = useState("");
  const [expiryDate, setExpiryDate] = useState();
  const navigate = useNavigate();
  const authenticatedFetch = useAuthenticatedFetch()

  const goToLogin = () => navigate(URL_LOGIN);

  const setResendExpiryDate = () => {
    const expiryTime = new Date();
    expiryTime.setMinutes(expiryTime.getMinutes() + 2);
    setExpiryDate(expiryTime.toString());
  };

  const onRequestEmailSuccess = (email) => {
    setStep(1);
    setRequestedEmail(email);
    setResendExpiryDate();
  };

  const requestForgotPassword = (email = requestedEmail) => {
    const request = {
      email,
    };

    setLoading(true);
    return authenticatedFetch(API_FORGOT_PASSWORD, {
      data: request,
      method: 'POST',
    })
      .then(() => onRequestEmailSuccess(email))
      .finally(() => {
        setLoading(false);
      });
  };

  const Body = () => {
    if (step === 1) {
      return (
        <ForgotPasswordResendEmail
          email={requestedEmail}
          expiryDate={expiryDate}
          onResend={requestForgotPassword}
          onBackToLogin={goToLogin}
        />
      );
    }

    return <RequestEmail onBack={goToLogin} onSubmit={requestForgotPassword} />;
  };

  return (
    <div className="forget-password-container">
      <div className="absolute top-4 left-4">
        <img src={trustplusLogo} alt="Login Logo" className="h-8 w-[215px]" />
      </div>
      <div className="forget-password-content-container">
        <Body />
      </div>
    </div>
  );
}
