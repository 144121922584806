import { SORT_TYPES } from "../../constants/options";
import extractPeriodAndDate from "../string/extractPeriodAndDate";

export default function sortPeriodString(fiscalPeriodOrder, order = SORT_TYPES.ASC){
  let orderModifier = order === SORT_TYPES.ASC ? 1 : -1
  return fiscalPeriodOrder.sort((firstPeriodString, secondPeriodString) => {
    const firstPeriodStringData = extractPeriodAndDate(firstPeriodString)
    const secondPeriodStringData = extractPeriodAndDate(secondPeriodString)

    if (firstPeriodStringData.period !== secondPeriodStringData.period) {
      return (firstPeriodStringData.period - secondPeriodStringData.period) * -1;
    }

    if (firstPeriodStringData.year !== secondPeriodStringData.year) {
      return (firstPeriodStringData.year - secondPeriodStringData.year) * orderModifier;
    }

    return (firstPeriodStringData.month - secondPeriodStringData.month) * orderModifier
  });
};
