import React, { useState } from "react";
import SearchInput from "../../components/SearchInput";
import defaultAvatarImage from "../../assets/images/default_avatar.png";
import { ReactComponent as ArrowDropdownIcon } from "../../assets/icons/arrow_drop_down.svg";

const Team = ({ teamName, members, count, isExpanded, toggleTeam }) => (
  <div>
    <div
      className={`flex justify-between p-4 cursor-pointer ${
        isExpanded ? "bg-[#E8F4FF] rounded-t-[12px]" : ""
      }`}
      onClick={toggleTeam}
    >
      <div className="flex items-center">
        <span
          className={`mr-2 transition-transform ${
            isExpanded ? "rotate-180" : "rotate-0"
          }`}
        >
          <ArrowDropdownIcon color={isExpanded ? "#121212" : "#525252"} />
        </span>
        <span
          className={`text-[1.125rem] font-bold leading-[1.5rem] tracking-[-0.54px] ${
            isExpanded ? "text-[#121212]" : "text-[#525252]"
          }`}
        >
          {teamName}
        </span>
      </div>
      <span className="text-[#121212] text-[0.875rem] font-semibold leading-[1.25rem] tracking-[-0.28px]">
        {count}
      </span>
    </div>
    {isExpanded && (
      <div className="p-[1rem] pt-0 bg-[#E8F4FF] rounded-b-[12px]">
        <ul>
          {members.map((member, index) => (
            <li
              key={index}
              className="flex items-center px-[1rem] py-[0.75rem]"
            >
              {member.image && (
                <img
                  className="w-[32px] h-[32px] rounded-full mt-[1rem]"
                  src={member.image || defaultAvatarImage}
                  alt={member.name}
                />
              )}
              <span className="text-[#000] text-base leading-[1.5rem] tracking-[0.32px]">
                {member.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

const TeamMember = () => {
  // Manage the state for expanded teams
  const [expandedTeam, setExpandedTeam] = useState(null);

  // Toggle team expansion
  const toggleTeam = (team) => {
    setExpandedTeam(expandedTeam === team ? null : team);
  };

  // Sample team data
  const teams = [
    {
      name: "Team A",
      count: 6,
      members: [
        { name: "Darlene Robertson", image: defaultAvatarImage },
        { name: "Leslie Alexander", image: "https://via.placeholder.com/150" },
        { name: "Eleanor Pena", image: "https://via.placeholder.com/150" },
        { name: "Jane Cooper", image: "https://via.placeholder.com/150" },
        { name: "Kathryn Murphy", image: "https://via.placeholder.com/150" },
        { name: "Jacob Jones", image: "https://via.placeholder.com/150" },
      ],
    },
    {
      name: "Team B",
      count: 4,
      members: [
        { name: "Member B1", image: "https://via.placeholder.com/150" },
        { name: "Member B2", image: "https://via.placeholder.com/150" },
      ],
    },
    {
      name: "Team C",
      count: 10,
      members: [
        { name: "Member C1", image: "https://via.placeholder.com/150" },
        { name: "Member C2", image: "https://via.placeholder.com/150" },
      ],
    },
  ];

  return (
    <div className="w-full 2xl:max-w-[581px] sm:max-w-[381px] max-w-default transition-all mx-auto overflow-hidden flex h-full 2xl:p-[1.5rem] sm:p-[20px] flex-col gap-[0.5rem] rounded-[20px] bg-[#fff]">
      <div className="flex justify-between items-center mt-[1rem] w-full min-w-[300px]">
        <h2 className="text-[#121212] font-bold text-[1.25rem] leading-[1.75rem] tracking-[-0.6px]">
          Team Member
        </h2>
        <SearchInput containerClassname={"!mx-0 2xl:w-[200px] sm:w-[160px]"} />
      </div>

      {teams.map((team, index) => (
        <Team
          key={index}
          teamName={team.name}
          members={team.members}
          count={team.count}
          isExpanded={expandedTeam === team.name}
          toggleTeam={() => toggleTeam(team.name)}
        />
      ))}
    </div>
  );
};

export default TeamMember;
