import { useState } from "react";
import backIcon from "../../../assets/icons/icon_back.svg";
import hidePasswordIcon from "../../../assets/icons/icon_eye_closed.svg";
import showPasswordIcon from "../../../assets/icons/icon_eye_open.svg";
import TextInput from "../../../components/TextInput";
import { API_CHANGE_PASSWORD, URL_USER_PROFILE } from "../../../constants/url";
import { TOAST_TYPE } from "../../../constants";
import { useToaster } from "../../../context/Toaster";
import { useLoader } from "../../../context/Loader";
import { useNavigate } from "react-router-dom";
import useAuthenticatedFetch from "../../../hooks/useAuthenticatedFetch";

const FIELDS = {
  current_password: "current_password",
  new_password: "new_password",
  confirm_password: "confirm_new_password",
};

const REQUIRED_FIELDS = Object.values(FIELDS);

const initialFormValue = REQUIRED_FIELDS.reduce((formValue, fieldName) => {
  return {
    ...formValue,
    [fieldName]: "",
  };
}, {});

export default function ProfileChangePassword() {
  const { setLoading } = useLoader();
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const authenticatedFetch = useAuthenticatedFetch()

  const [formData, setFormData] = useState(initialFormValue);
  const [isSubmit, setIsSubmit] = useState(false);

  const isPasswordMatch =
    formData.new_password === formData.confirm_new_password;
  const [showPassword, setShowPassword] = useState({
    current_password: false,
    new_password: false,
    confirm_new_password: false,
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const togglePasswordVisibility = (type) => {
    setShowPassword((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };
  const isDisabledSubmitButton =
    REQUIRED_FIELDS.some((fieldName) => !formData[fieldName]) ||
    formData[FIELDS.confirm_password] !== formData[FIELDS.new_password] ||
    !formData[FIELDS.current_password];

  const handleChangePassword = async () => {
    setIsSubmit(true);
    if (isDisabledSubmitButton) {
      return;
    }
    setLoading(true);
    return authenticatedFetch(API_CHANGE_PASSWORD, {
      data: formData,
      method: 'POST',
    })
      .then((response) => {
        showToast(response.data.message, TOAST_TYPE.SUCCESS);
        setLoading(false);
        navigate(URL_USER_PROFILE);
      })
      .catch((err) => {
        showToast("Current password is incorrect", TOAST_TYPE.ERROR);
        setLoading(false);
      });
  };

  return (
    <div className="w-full max-w-default sm:px-[48px] 2xl:px-[64px] relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[2.5rem] flex gap-[1rem]">
        <img
          src={backIcon}
          alt="back-button"
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <span className="text-gradient-aurora-blue  text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
          Change Password
        </span>
      </div>
      <div className="w-full max-w-[582px] p-[44px] flex gap-[2rem] bg-white rounded-[20px] self-center">
        <div className="text-left w-full">
          <div className="relative">
            <TextInput
              name="current_password"
              id={FIELDS.current_password}
              label="Current Password"
              type={showPassword.current_password ? "text" : "password"}
              placeholder="Enter your current password"
              className="pb-6"
              onChange={handleChange}
              value={formData[FIELDS.current_password]}
              required
              isSubmit={isSubmit}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button
                type="button"
                onClick={() => {
                  togglePasswordVisibility("current_password");
                }}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {showPassword.current_password ? (
                  <img src={hidePasswordIcon} alt="hide password" />
                ) : (
                  <img src={showPasswordIcon} alt="show password" />
                )}
              </button>
            </div>
          </div>
          <div className="relative">
            <TextInput
              name="new_password"
              id={FIELDS.new_password}
              label="New Password"
              type={showPassword.new_password ? "text" : "password"}
              placeholder="Enter your new password"
              className="pb-6"
              onChange={handleChange}
              value={formData[FIELDS.new_password]}
              required
              isSubmit={isSubmit}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button
                type="button"
                onClick={() => {
                  togglePasswordVisibility("new_password");
                }}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {showPassword.new_password ? (
                  <img src={hidePasswordIcon} alt="hide password" />
                ) : (
                  <img src={showPasswordIcon} alt="show password" />
                )}
              </button>
            </div>
          </div>
          <div className="relative">
            <TextInput
              name="confirm_new_password"
              id={FIELDS.confirm_password}
              label="Confirm New Password"
              type={showPassword.confirm_new_password ? "text" : "password"}
              placeholder="Enter your new password"
              onChange={handleChange}
              className="pb-6"
              errorMessage={
                !isPasswordMatch && formData.confirm_new_password
                  ? "Password does not match"
                  : null
              }
              value={formData[FIELDS.confirm_password]}
              required
              isSubmit={isSubmit}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button
                type="button"
                onClick={() => {
                  togglePasswordVisibility("confirm_new_password");
                }}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {showPassword.confirm_new_password ? (
                  <img src={hidePasswordIcon} alt="hide password" />
                ) : (
                  <img src={showPasswordIcon} alt="show password" />
                )}
              </button>
            </div>
          </div>
          <div className="pt-[12px]">
            <button
              type="submit"
              className={`primary w-full text-white`}
              // disabled={isDisabledSubmitButton}
              onClick={handleChangePassword}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
