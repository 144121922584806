import PropTypes from "prop-types";
import iconFilterTable from "../../assets/icons/icon_filter_table.svg";

SortIconTable.propTypes = {
  isAsc: PropTypes.bool.isRequired,
};
export default function SortIconTable({ isAsc }) {
  return (
    <img src={iconFilterTable} className={`${isAsc ? "" : "rotate-180"}`} />
  );
}
