import { Fragment, useEffect, useRef, useState } from "react";
import downIcon from "../../assets/icons/icon_down.svg";
import savedIcon from "../../assets/icons/icon_saved.svg";
import failedIcon from "../../assets/icons/icon_process_failed.svg";
import upIcon from "../../assets/icons/icon_up.svg";
import { usePendingApplications } from "../../context/PendingApplications";
import { PENDING_APPLICATION_STATUS } from "../../constants/statuses";
import { Link, useNavigate } from "react-router-dom";
import {
  API_APPLICATIONS,
  URL_APPLICATION_CREATE,
  URL_APPLICATION_VALIDATE,
} from "../../constants/url";
import checklistIcon from "../../assets/icons/icon_checklist.svg";
import Tooltip from "../Tooltip";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

export default function PendingApplications() {
  const {
    pendingApplications,
    hasPendingApplication,
    removePendingApplication,
  } = usePendingApplications();
  const authenticatedFetch = useAuthenticatedFetch()

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen((isOpen) => !isOpen);
  const pendingApplicationsDropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (
      pendingApplicationsDropdownRef.current &&
      !pendingApplicationsDropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!pendingApplications.length) {
    return null;
  }

  const handleDeleteApplication = async (applicationId) => {
    try {
      await authenticatedFetch(`${API_APPLICATIONS}/${applicationId}`, {
        withCredentials: true,
        method: 'DELETE'
      })

      removePendingApplication(applicationId)
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  return (
    <div ref={pendingApplicationsDropdownRef} className="relative">
      <div
        className={`w-full border justify-evenly border-neutral-200 py-3 px-4 flex flex-row h-max items-center cursor-pointer rounded-2xl ${
          isOpen &&
          "border border-b-0 rounded-b-2xl rounded-bl-none rounded-br-none"
        }`}
        onClick={toggleDropdown}
      >
        {hasPendingApplication ? (
          <Fragment>
            <div className="animate-spin rounded-full h-[21.6px] w-[21.6px] border-[3px] border-primary-1 mr-4 border-t-white" />
            <span className="pr-3 text-primary-2 font-semibold text-[15px] leading-[1.25rem]">
              Processing Status
            </span>
          </Fragment>
        ) : (
          <Fragment>
            <img src={checklistIcon} className="mr-4 h-6" alt="Done" />
            <span className="pr-3 text-[#45B441] font-semibold text-[15px] leading-[1.25rem]">
              Processing Completed
            </span>
          </Fragment>
        )}
        <img src={isOpen ? upIcon : downIcon} className="h-6" alt="Toggle" />
      </div>
      {isOpen && (
        <div className="z-50 w-full absolute top-full bg-white border border-t-0 rounded-b-2xl flex flex-col w-72s p-[1rem]">
          {pendingApplications.map(({ companyName, id, status, companyId }) => {
            const canProgress = [
              PENDING_APPLICATION_STATUS.DONE,
              PENDING_APPLICATION_STATUS.FAILED,
            ].includes(status);

            let target = ''

            if (canProgress) {
              if (status === PENDING_APPLICATION_STATUS.DONE) {
                target = `${URL_APPLICATION_VALIDATE}/${id}`
              }

              if (status === PENDING_APPLICATION_STATUS.FAILED) {
                target = `${URL_APPLICATION_CREATE}/${companyId}`
              }
            }

            return (
              <Link
                key={id}
                className={`flex justify-between ${
                  canProgress && "hover:bg-gray-100"
                } ${
                  !canProgress && "cursor-default"
                } border-b-[1px] py-[0.5rem]`}
                to={target}
                onClick={async (e) => {
                  if (!canProgress) {
                    e.preventDefault(); 
                    return;
                  }
              
                  setIsOpen(false);
              
                  if (status === PENDING_APPLICATION_STATUS.FAILED) {
                    await handleDeleteApplication(id);
                  }
                }}
              >
                <Tooltip
                  tooltip={companyName}
                  tooltipClassname="flex p-[8px] justify-center items-center rounded-[2px] !bg-[rgba(255, 255, 255, 0.80)]"
                  customStyle={{
                    width: `${companyName.length * 8 + 16}px`,
                  }}
                >
                  <span className="block text-neutral-strong text-[0.875rem] font-[600] truncate !max-w-[172px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {companyName}
                  </span>
                </Tooltip>

                {status === PENDING_APPLICATION_STATUS.IN_PROGRESS && (
                  <Tooltip
                    tooltip="Processing"
                    tooltipClassname="flex p-[8px] justify-center items-center rounded-[2px] !bg-[rgba(255, 255, 255, 0.80)] w-[68px]"
                  >
                    <div className="animate-spin rounded-full h-[21.6px] w-[21.6px] border-[3px] border-primary-1 border-t-white" />
                  </Tooltip>
                )}
                {status === PENDING_APPLICATION_STATUS.DONE && (
                  <Tooltip
                    tooltip="Financial Spreading Processing Completed"
                    tooltipClassname="flex p-[8px] justify-center items-center rounded-[2px] !bg-[rgba(255, 255, 255, 0.80)] !max-w-[121px]"
                  >
                    <img src={savedIcon} className="h-6" alt="Done" />
                  </Tooltip>
                )}
                {status === PENDING_APPLICATION_STATUS.FAILED && (
                  <Tooltip
                    tooltip="Financial Spreading Processing Failed"
                    tooltipClassname="flex p-[8px] justify-center items-center rounded-[2px] !bg-[rgba(255, 255, 255, 0.80)] w-[106px]"
                  >
                    <img src={failedIcon} className="h-6" alt="Failed" />
                  </Tooltip>
                )}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}
