export default async function scrollToPosition(ref, targetPosition) {
  return new Promise((resolve) => {
    // Trigger smooth scroll on the ref element
    ref.current.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });

    // Check if scroll is complete using requestAnimationFrame
    function checkIfDone() {
      // When we reach the target position (allow for slight offset)
      if (Math.abs(ref.current.scrollTop - targetPosition) < 1) {
        resolve(); // Resolve the promise once scrolling is done
      } else {
        requestAnimationFrame(checkIfDone); // Continue checking until done
      }
    }

    // Start checking
    requestAnimationFrame(checkIfDone);
  });
}