import Lottie from "react-lottie";
import PropTypes from "prop-types";
import logoAnimation from "./loadingLogo.json";

LoadingLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

function LoadingLogo({ width = 565, height = 84 }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: logoAnimation,
    renderer: "svg",
  };
  return (
    <>
      <Lottie options={defaultOptions} height={height} width={width} />
    </>
  );
}

export default LoadingLogo;
