export default function generatePeriodString(monthNo, year, period, audited = '', useFullPeriodString = false) {
  const date = new Date(year, monthNo - 1); 

  const monthAbbreviation = date.toLocaleString('default', { month: 'short' });

  if (useFullPeriodString) {
    return `${period || 12}M ${monthAbbreviation} ${year} ${audited}`.trim()
  }

  let basePeriodString = `${monthAbbreviation} ${year}`

  if (period) {
    basePeriodString = `${period}M ${basePeriodString}`
  }

  if (audited) {
    basePeriodString = `${basePeriodString} ${audited}`
  }

  return basePeriodString;
}