import { FORMAT_NUMBER_TYPE } from "./number";

export const FORMAT_NUMBER_EXCEL_MAP = {
  [FORMAT_NUMBER_TYPE.ROUND_DECIMAL]: (decimalPlaces, currentFormat) => {
    let decimalFmt = '0'

    if (decimalPlaces > 0) {
      decimalFmt += `.${'0'.repeat(decimalPlaces)}`
    }
    return `${currentFormat || ''}${decimalFmt}`;  
  },
  [FORMAT_NUMBER_TYPE.NEGATIVE_BRACKETS]: (currentFormat) => {
    const baseFormat = currentFormat || `#,##0`;  // Default if no current format
    return `${baseFormat};(${baseFormat})`;  // Add parentheses for negative numbers
  },
  [FORMAT_NUMBER_TYPE.FORMAT_NUMBER]: (currentFormat) => {
    if (!currentFormat) return `#,##0`;  // If there's no format, default to just thousands separator

    // Check if there are already decimal places, percentages, or brackets, and add commas without overwriting
    return currentFormat.replace(/0+/, '#,##0');
  },
  [FORMAT_NUMBER_TYPE.PERCENTAGE]: (currentFormat) => {
    // Simply append the percentage sign at the end, keeping everything else intact
    if (!currentFormat) return `0%`;  // Default format if no current format

    // If the format already exists, just add % to it, preserving decimals, brackets, and commas
    return `${currentFormat}%`;
  },
}