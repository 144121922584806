export default function extractPeriodAndDate(periodString) {
  let period = null;

    // Regular expression pattern to match <number>M MM YYYY
    const pattern = /(?:([\d]+)M\s)?([A-Za-z]+\s)?(\d{4})(?:\s(.+))?/;
    const match = periodString.toString().match(pattern);

    if (match) {
        period = match[1];
        const month = match[2]?.trim();
        const year = match[3];
        const auditedStatus = match[4]?.trim()

        const date = new Date(`${month} 1, ${year}`);
        
        if (!isNaN(date.getTime())) {
            return {
              period,
              month,
              year,
              monthInt: date.getMonth() + 1,
              audited: auditedStatus,
            }
        } else {
            return {};
        }
    }

    return {};
}
