const BaseButton = ({
  btnClassname = "",
  titleClassname = "",
  title,
  handleClick,
}) => {
  return (
    <button
      className={`bg-gradient-to-r from-[#023972] to-[#356CA5] p-[0.75rem] flex items-center justify-center rounded-[8px] ${btnClassname}`}
      onClick={handleClick}
    >
      <div
        className={`font-[600] leading-[1.5rem] tracking-[-0.48px] text-[1rem] text-white ${titleClassname}`}
      >
        {title}
      </div>
    </button>
  );
};

export default BaseButton;
