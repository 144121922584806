import PropTypes from "prop-types";
import "./index.css";
import LoadingLogo from "../LoadingLogo";

Loader.propTypes = {
  isLoading: PropTypes.bool,
  loaderContent: PropTypes.element,
};

export default function Loader({ isLoading, loaderContent }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="fixed inset-0 w-full h-full bg-white bg-opacity-50 backdrop-blur-md flex flex-col justify-center items-center z-50 text-[rgb(32,58,164)] text-xl gap-[2.5rem]">
      {/* <div className="loader" /> */}
      <LoadingLogo />
      {loaderContent}
    </div>
  );
}
