import { useEffect, useState } from "react";
import closeIcon from "../../assets/icons/icon_close.svg";

export default function PreviewAvatar({ file, url, ...otherProps }) {
  const [fileUrl, setFileUrl] = useState(url);
  useEffect(() => {
    if (file && !url) {
      setFileUrl(URL.createObjectURL(file));
    }
  }, [file]);

  if (!fileUrl) {
    return null;
  }

  const fileType = file.type;

  const isImage = fileType.startsWith("image/");

  if (isImage) {
    return (
      <div className="flex flex-col gap-[0.5rem] px-[1rem] py-[0.75rem] rounded-[8px] bg-black bg-opacity-[0.06]">
        <div className="flex justify-between items-start gap-[1rem] w-full">
          <div className="flex gap-[1rem]">
            <img
              src={fileUrl}
              alt="avatar"
              width={36}
              height={36}
              className="rounded-[8px] w-[36px] h-[36px]"
            />
            <div className="flex flex-col justify-center items-start gap-[0.5rem] w-full">
              <div className="text-[#121212] text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.28px]">
                {file.name}
              </div>
              <div className="text-[#737373] text-[0.75rem] leading-[1rem]">
                {(file.size / 1024).toFixed(2)} KB
              </div>
            </div>
          </div>
          <img
            src={closeIcon}
            alt="Close"
            className="min-w-10 w-10 inline-block cursor-pointer"
            onClick={() => setFileUrl(null)}
          />
        </div>
      </div>
    );
  }

  return <div>Unsupported file type</div>;
}
