export default function aggregateData(data) {
  const aggregatedData = {};

  data.forEach((item) => {
    const key = item.account_code; // You can change this to 'tp_standard_account_name' if needed
    if (!aggregatedData[key]) {
      aggregatedData[key] = { ...item, amount: { ...item.amount } };
      return;
    }

    Object.keys(item.amount).forEach((year) => {
      aggregatedData[key].amount[year] =
        (aggregatedData[key].amount[year] || 0) + item.amount[year];
    });
  });

  return aggregatedData
}