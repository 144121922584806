export const createEmptyObject = (inputObject) => {
  let emptyObject = {};

  Object.keys(inputObject).forEach((key) => {
    if (
      typeof inputObject[key] === "object" &&
      !Array.isArray(inputObject[key])
    ) {
      // For nested object (like "amount"), set each nested key to an empty string
      Object.keys(inputObject[key]).forEach((nestedKey) => {
        emptyObject[nestedKey] = "";
      });
    } else {
      // For other properties, set the value to an empty string
      emptyObject[key] = "";
    }
  });

  return emptyObject;
};
