import { createContext, useContext } from "react";
import { Outlet } from "react-router-dom";

const AuthenticatedRouteContext = createContext({
  isAuthenticatedRoute: false,
});

export const useAuthenticatedRouteContext = () => useContext(AuthenticatedRouteContext);

export default function AuthenticatedRouteProvider({ children }) {
  const isAuthenticatedRoute = true

  return (
    <AuthenticatedRouteContext.Provider
      value={{
        isAuthenticatedRoute,
      }}
    >
      {children || <Outlet />}
    </AuthenticatedRouteContext.Provider>
  );
}
