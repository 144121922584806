import { AUDITED_VALUE, UNAUDITED_VALUE } from "../../constants";
import {
  ACCOUNT_CODE_CONSTANT,
} from "../../constants/formula";
import extractPeriodAndDate from "../string/extractPeriodAndDate";
import generatePeriodString from "../string/generatePeriodString";
import aggregateData from "./aggregateData";
import calculateTotalByOperation from "./calculate_total_by_operation";
import defaultCalculateFormula from "./default_calculate_formula";
 
export default function calculateFormulaFromData(
  data,
  formula,
  baseCalculateMetadata = {},
  baseData = {},
  baseMetadata = {},
) {
  const aggregatedData = aggregateData(data)

  const { modifier = 1 } = baseMetadata
 
  return formula.map(
    ({ tp_standard_account_name, displayCode, operations, document_type, ...otherMetaData }) => {
      const totals = {};

      operations.forEach((currentOperationData) => {
        const {
          accountCode,
          operation,
          year: usedYear = 0,
          calculate = defaultCalculateFormula,
        } = currentOperationData;

        if (accountCode === ACCOUNT_CODE_CONSTANT) {
          for (const periodString in totals) {
            const { period, monthInt, year, audited } = extractPeriodAndDate(periodString)
            const calculateMetadata = {
              ...baseCalculateMetadata,
            };
            const calculatedYear = Number(year) + usedYear;
            const calculatedPeriodString = generatePeriodString(monthInt, calculatedYear, period, audited)

            // when comparing previous value, priority is AUDITED, UNAUDITED, no audited
            const previousYear = year - 1
            const previousPeriodString = generatePeriodString(monthInt, previousYear, period, AUDITED_VALUE)

            let previousPeriodTotal = totals[previousPeriodString]

            if (typeof previousPeriodTotal === 'undefined') {
              const previousPeriodStringUnaudited = generatePeriodString(monthInt, previousYear, period, UNAUDITED_VALUE)
              previousPeriodTotal  = totals[previousPeriodStringUnaudited]
            }

            if (typeof previousPeriodTotal === 'undefined') {
              const previousPeriodStringWithoutAudited = generatePeriodString(monthInt, previousYear, period)
              previousPeriodTotal  = totals[previousPeriodStringWithoutAudited]
            }

            calculateMetadata.isOldestYear = typeof totals[previousPeriodString] === 'undefined';

            const value = calculate(calculateMetadata, totals, calculatedPeriodString);
            const currentTotal = totals[periodString];

            const newTotal = calculateTotalByOperation(
              currentTotal,
              value,
              operation
            );

            totals[periodString] = newTotal;
          }
          return 
        }

        const accountCodeList = accountCode.split(",");

        const accountCodeAmountMap = {};
 
        const aggregatedFinancialData = Object.values(aggregatedData)

        aggregatedFinancialData.forEach((item) => {
          accountCodeList.forEach((accountCode) => {
            const [startAccountCode, endAccountCode = startAccountCode] =
              accountCode.split("-");
            if (
              item.account_code >= startAccountCode &&
              item.account_code <= endAccountCode
            ) {
              accountCodeAmountMap[item.account_code] = item.amount;
            }
          });
        });

        const periodStringAccountCodeMap = {};

        for (const accountCode in accountCodeAmountMap) {
          const periodStringAmounts = accountCodeAmountMap[accountCode];

          for (const periodString in periodStringAmounts) {
            if (!periodStringAccountCodeMap[periodString]) {
              periodStringAccountCodeMap[periodString] = {};
            }

            periodStringAccountCodeMap[periodString][accountCode] = periodStringAmounts[periodString];
          }
        }

        const accountCodeSum = {};

        for (const periodString in periodStringAccountCodeMap) {
            const { period, monthInt, year, audited } = extractPeriodAndDate(periodString)
            const calculateMetadata = {
            ...baseCalculateMetadata,
          };

          // when comparing previous value, priority is AUDITED, UNAUDITED, no audited
          const previousYear = year - 1
          const previousPeriodString = generatePeriodString(monthInt, previousYear, period, AUDITED_VALUE)

          let previousPeriodTotal = totals[previousPeriodString]

          if (typeof previousPeriodTotal === 'undefined') {
            const previousPeriodStringUnaudited = generatePeriodString(monthInt, previousYear, period, UNAUDITED_VALUE)
            previousPeriodTotal  = totals[previousPeriodStringUnaudited]
          }

          if (typeof previousPeriodTotal === 'undefined') {
            const previousPeriodStringWithoutAudited = generatePeriodString(monthInt, previousYear, period)
            previousPeriodTotal  = totals[previousPeriodStringWithoutAudited]
          }

          calculateMetadata.isOldestYear = typeof totals[previousPeriodString] === 'undefined';

          const calculatedYear = Number(year) + usedYear;
          let calculatedPeriodString = generatePeriodString(monthInt, calculatedYear, period, audited)
          let amount = periodStringAccountCodeMap[calculatedPeriodString];

          if (!amount && audited === UNAUDITED_VALUE) {
            calculatedPeriodString = generatePeriodString(monthInt, calculatedYear, period)
            amount = periodStringAccountCodeMap[calculatedPeriodString];
          }

          if (amount)
            Object.keys(amount).forEach(key => {
              amount[key] *= modifier;
            });

          const result = calculate(
            amount,
            calculateMetadata,
            periodStringAccountCodeMap,
            calculatedPeriodString
          );

          accountCodeSum[periodString] = result;
        }

        const combinedPeriodStrings = new Set([...Object.keys(totals), ...Object.keys(accountCodeSum)])

        combinedPeriodStrings.forEach((periodString) => {
          const value = accountCodeSum[periodString];
          const currentTotal = totals[periodString];

          const newTotal = calculateTotalByOperation(
            currentTotal,
            value,
            operation
          );

          totals[periodString] = newTotal;
        })
      });
 
      const newData = {
        ...otherMetaData,
        tp_standard_account_name: tp_standard_account_name,
        account_code: displayCode,
        amount: totals,
        document_type,
        ...baseData,
      };
    
      aggregatedData[newData.account_code] = newData;

      return newData;
    }
  );
}
