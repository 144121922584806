import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import iconEdit from "../../assets/icons/edit.png";
import backIcon from "../../assets/icons/icon_back.svg";
import defaultAvt from "../../assets/images/3d_avatar_19.png";
import { URL_CHANGE_PASSWORD, URL_DASHBOARD } from "../../constants/url";
import { useAuthContext } from "../../context/Auth";
import { capitalizeString } from "../../utils/string/convertString";

export default function Profile() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(defaultAvt); // Store the selected image or default

  // Function to handle image change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a URL for the image
      setSelectedImage(imageUrl); // Update the state with the new image URL
    }
  };

  return (
    <div className="w-full max-w-default sm:px-[48px] 2xl:px-[64px] relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[2.5rem] flex gap-[1rem] w-full justify-between">
        <div className=" flex gap-[1rem]">
          <img
            src={backIcon}
            alt="back-button"
            className="cursor-pointer"
            onClick={() => navigate(URL_DASHBOARD)}
          />
          <div>
            <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
              Profile Settings
            </span>
          </div>
        </div>
        <div className="self-end flex items-center gap-[1.25rem]">
          {false && (
            <>
              <button className="font-[600] leading-[1.5rem] tracking-[-0.48px] text-[1rem] text-neutral-strong w-[91px] py-[0.75rem] px-[20px] h-[48px] rounded-[8px] border-solid border-neutral-medium border-[1px] flex items-center justify-center">
                Cancel
              </button>
              <button className="w-[91px] primary py-[0.75rem] px-[20px] h-[48px] flex items-center justify-center">
                Save
              </button>
            </>
          )}
        </div>
      </div>
      <div className="w-full max-w-[884px] p-[1.5rem] flex gap-[2rem] bg-white rounded-[20px] self-center">
        <div className="relative w-[120px] h-[120px]">
          <img
            className="w-[120px] h-[120px] rounded-[50%]"
            src={selectedImage} // Use the selected image or default
            alt="avatar"
          />
          <img
            className="w-[36px] h-[36px] p-[8px] rounded-[50%] absolute bottom-0 right-0 bg-edit-bg border-[2px] border-solid border-white cursor-pointer"
            src={iconEdit}
            alt="edit"
            onClick={() => document.getElementById("fileInput").click()} // Trigger the file input on click
          />
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            className="hidden" // Hide the input element
            onChange={handleImageChange} // Handle the image change
          />
        </div>
        <div className="flex flex-col grow">
          <div className="flex flex-col justify-center gap-[0.75rem] h-[128px] p-b-[8px] border-b-black-Alpha-200 border-b-[1px] border-solid">
            <span className="text-black text-[1.5rem] font-[600] leading-[2rem] tracking-[-0.72px]">
              {`${user.first_name} ${user.last_name}`}
            </span>
            <div className="p-[8px] rounded-[4px] bg-display-blue-light flex items-center h-[24px] text-[0.875rem] leading-[1.25rem] tracking-[-0.28px] font-[500] w-fit text-display-blue-strong">
              {capitalizeString(user.role)}
            </div>
          </div>
          <div className="py-[32px] flex flex-col gap-[1.5rem]">
            <div className="flex gap-[1.5rem]">
              <span className="w-[80px] text-[1rem] font-[500] leading-[1.5rem] tracking-[-0.32px] text-Text-neutral-medium">
                Company
              </span>
              <span className="text-[1.125rem] font-[600] leading-[1.5rem] tracking-[-0.54px]">
                {user?.company_name}
              </span>
            </div>
            <div className="flex gap-[1.5rem]">
              <span className="w-[80px] text-[1rem] font-[500] leading-[1.5rem] tracking-[-0.32px] text-Text-neutral-medium">
                Team
              </span>
              <span className="text-[1.125rem] font-[600] leading-[1.5rem] tracking-[-0.54px]">
                {user?.team_name}
              </span>
            </div>
            <div className="flex gap-[1.5rem]">
              <span className="w-[80px] text-[1rem] font-[500] leading-[1.5rem] tracking-[-0.32px] text-Text-neutral-medium">
                Email
              </span>
              <span className="text-[1.125rem] font-[600] leading-[1.5rem] tracking-[-0.54px]">
                {user?.email}
              </span>
            </div>
          </div>
          <Link to={URL_CHANGE_PASSWORD}>
            <button className="w-fit h-[2.5rem] rounded-[8px] border-solid border-neutral-medium border-[1px] px-[1rem]">
              Change Password
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
