import SortIconTable from "../../components/SortIconTable";
import formatNumber from "../../utils/number/formatNumber";

export const mockData = [
  {
    id: 1,
    team_name: "Team A",
    number_of_generation: 10,
    total_cost: 42250,
  },
  {
    id: 2,
    team_name: "Team B",
    number_of_generation: 10,
    total_cost: 42250000,
  },
];

export const TEAM_REPORT_COLS = {
  TEAM_NAME: "team_name",
  NUMBER_OF_GENERATION: "number_of_generation",
  TOTAL_COST: "total_cost",
};

export const teamReportCols = {
  minWidth: 180 * 3,
  columns: ({ handleSort, isAscCols }) => [
    {
      key: "team_name",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 rounded-tl-[8px] max-w-[380px]"
          onClick={() => handleSort(TEAM_REPORT_COLS.TEAM_NAME)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center border-r-card-on-surface">
            Team
            <SortIconTable isAsc={isAscCols[TEAM_REPORT_COLS.TEAM_NAME]} />
          </div>
        </th>
      ),
      renderRow: ({ team_name }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[380px] z-20">
          <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] border-r-card-on-surface">
            {team_name}
          </div>
        </td>
      ),
    },
    {
      key: "number_of_generation",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 max-w-[380px]"
          onClick={() => handleSort(TEAM_REPORT_COLS.NUMBER_OF_GENERATION)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center border-r-card-on-surface">
            Number of Generation
            <SortIconTable
              isAsc={isAscCols[TEAM_REPORT_COLS.NUMBER_OF_GENERATION]}
            />
          </div>
        </th>
      ),
      renderRow: ({ number_of_generation }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[380px] z-20">
          <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] border-r-card-on-surface">
            {number_of_generation}
          </div>
        </td>
      ),
    },
    {
      key: "total_cost",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[51px] bg-primary-50 rounded-tr-[8px] max-w-[380px]"
          onClick={() => handleSort(TEAM_REPORT_COLS.TOTAL_COST)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center border-r-card-on-surface">
            Total Cost
            <SortIconTable isAsc={isAscCols[TEAM_REPORT_COLS.TOTAL_COST]} />
          </div>
        </th>
      ),
      renderRow: ({ total_cost }) => (
        <td className="bg-white !h-[35px] !p-0 max-w-[380px] z-20">
          <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] border-r-card-on-surface">
            ${formatNumber(total_cost)}
          </div>
        </td>
      ),
    },
  ],
};
