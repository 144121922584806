import { FORMULA_GROUPING } from "../../constants/formula"

  export default function groupDataByDocumentType(data = [], documentType) {
    const groupings = FORMULA_GROUPING[documentType]

    const groupedData = {}

    data.forEach((datum) => {
      const { account_code } = datum
      for (let i = 0; i < groupings.length; i++) {
        const { name, content } = groupings[i]

        const [startAccountCode, endAccountCode = startAccountCode] =
          content.split("-")

          if (
            account_code >= startAccountCode &&
            account_code <= endAccountCode
          ) {
            const currentGroupedData = groupedData[name] || {
              groupTitle: name,
              data: []
            }
            currentGroupedData.data.push(datum)
            groupedData[name] = currentGroupedData;
            break;
          }
      }
    })

    return Object.values(groupedData)
 }
