import { useLocation, useNavigate } from "react-router-dom";
import { URL_LOGIN } from "../constants/url";
import { useAuthContext } from "../context/Auth";
import { useToaster } from "../context/Toaster";
import { TOAST_TYPE } from "../constants";
import fetch from "../utils/api/fetch";
import { useAuthenticatedRouteContext } from "../context/AuthenticatedRoute";
import { ERROR_NOT_FOUND } from "../constants/errors";

export default function useAuthenticatedFetch() {
  const navigate = useNavigate();
  const { user, handleLogout } = useAuthContext()
  const { showToast } = useToaster()
  const { isAuthenticatedRoute } = useAuthenticatedRouteContext()
  const location = useLocation();

  const authenticatedFetch = async (url, options = {}) => {
    return fetch(url, options).catch((error) => {
        const status_code = error.response?.status
        if (isAuthenticatedRoute) {
        if (status_code === 401) {
          if (user) {
            showToast('Your session has expired. Please login again.', TOAST_TYPE.ERROR, '', 0)
          }
          const currentPath = location.pathname + location.search;
          navigate(`${URL_LOGIN}?redirect=${currentPath}`)
          handleLogout()
          throw Error()
        }
      }

      if (status_code === 404) {
        throw Error(ERROR_NOT_FOUND)
      }
     

      throw error
      // if (error.response) {
      //   const status = error.response.status;

      //   if (status === 401) {
      //     // Unauthorized: Redirect to login
      //     navigate('/login');
      //   } else if (status === 403) {
      //     // Forbidden: Redirect to dashboard
      //     navigate('/dashboard');
      //   } else {
      //     // For other status codes, you can handle them as needed
      //     console.error(`Error: ${error.response.statusText}`);
      //     throw new Error(`Error: ${error.response.statusText}`);
      //   }
      // } else {
      //   // For network errors or other issues outside of response codes
      //   console.error('Error fetching data:', error);
      //   throw error;
      // }
    })
  };

  return authenticatedFetch;
}