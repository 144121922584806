import { ReactComponent as ArrowDropdownIcon } from "../../assets/icons/arrow_drop_down.svg";

export default function GroupedAccordion({
  groupName,
  members,
  isExpanded,
  toggleTeam,
}) {
  return (
    <div>
      <div
        className={`flex justify-between p-4 cursor-pointer ${
          isExpanded ? "bg-[#E8F4FF] rounded-t-[12px]" : ""
        }`}
        onClick={toggleTeam}
      >
        <div className="flex items-center">
          <span
            className={`mr-2 transition-transform ${
              isExpanded ? "rotate-180" : "rotate-0"
            }`}
          >
            <ArrowDropdownIcon color={isExpanded ? "#121212" : "#525252"} />
          </span>
          <span
            className={`text-[1.125rem] font-bold leading-[1.5rem] tracking-[-0.54px] ${
              isExpanded ? "text-[#121212]" : "text-[#525252]"
            }`}
          >
            {groupName}
          </span>
        </div>
        <span className="text-[#121212] text-[0.875rem] font-semibold leading-[1.25rem] tracking-[-0.28px]">
          {members.length}
        </span>
      </div>
      {isExpanded && (
        <div className="p-[1rem] pt-0 bg-[#E8F4FF] rounded-b-[12px]">
          <ul>
            {members.map((member, index) => (
              <li
                key={index}
                className="flex items-center px-[1rem] py-[0.75rem]"
              >
                <span className="text-[#000] text-base leading-[1.5rem] tracking-[0.32px] h-[32px]">
                  {member.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
