import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { CHART_DURATION } from "../../constants";
import { useState, useRef } from "react";
import TimeControlChart from "../TimeControlChart";
import { shortenNumber } from "../../utils/number/formatNumber";
import { getRandomColor } from "../../utils/color/colorSet";

const timeOptions = [
  {
    label: "Last 12 months",
    value: "Last 12 months",
  },
  {
    label: "Last 6 months",
    value: "Last 6 months",
  },
];

export default function LineChart({
  title,
  datasets,
  labels,
  displayLegend = true,
  legendPosition = "top",
  additionalHeader = <></>,
}) {
  const [selectedTime, setSelectedTime] = useState({
    duration: CHART_DURATION.MONTH,
    period: timeOptions?.[0],
  });

  const combinedData = {
    labels,
    datasets,
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: displayLegend,
        position: legendPosition,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => shortenNumber(value),
          stepSize: 10000,
        },
      },
    },
    elements: {
      point: {
        radius: 3, // Radius of the points
      },
    },
  };

  const handleSelectDuration = (duration) => {
    setSelectedTime((prev) => ({
      ...prev,
      duration,
    }));
  };

  return (
    <div className="bg-[#fff] flex flex-col p-[1.5rem] gap-[1.5rem] items-stretch rounded-[20px]">
      <TimeControlChart
        title={title}
        handleSelectDuration={handleSelectDuration}
        selectedTime={selectedTime}
      />
      {additionalHeader}
      <div className="w-full h-[266px]">
        <Line data={combinedData} options={options} />
      </div>
    </div>
  );
}
