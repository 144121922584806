import { useMemo, useState } from "react";
import backIcon from "../../assets/icons/icon_back.svg";

import { useNavigate } from "react-router-dom";
import CommonTable from "../../components/CommonTable/cop";
import LineChart from "../../components/LineChart";
import { URL_DASHBOARD } from "../../constants/url";
import { getRandomColor, predefinedColors } from "../../utils/color/colorSet";
import formatNumber from "../../utils/number/formatNumber";
import TeamMember from "../common/TeamMember";
import { mockData, TEAM_REPORT_COLS, teamReportCols } from "./portfolioCols";
import { isSortedByKey, sortByKey } from "../../utils/object/sortByKey";
import { SORT_ORDER } from "../../constants";

export default function UsageReport() {
  const navigate = useNavigate();

  const [data, setData] = useState(mockData);
  const [isAscCols, setIsAscCols] = useState({
    [TEAM_REPORT_COLS.TEAM_NAME]: true,
    [TEAM_REPORT_COLS.NUMBER_OF_GENERATION]: true,
    [TEAM_REPORT_COLS.TOTAL_COST]: true,
  });

  const datasets = [
    {
      label: "Team 1",
      data: [33000, 53000, 85000, 41000, 44000, 65000],
      fill: false,
    },
    {
      label: "Team 2",
      data: [33000, 25000, 35000, 51000, 54000, 76000],
      fill: false,
    },
  ];
  // Assign colors based on the number of datasets
  const coloredDatasets = datasets.map((dataset, index) => ({
    ...dataset,
    borderColor:
      datasets.length > 5
        ? getRandomColor()
        : predefinedColors[index % predefinedColors.length],
    backgroundColor:
      datasets.length > 5
        ? getRandomColor()
        : predefinedColors[index % predefinedColors.length],
    fill: false, // No fill by default
  }));
  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

  const handleSort = (colName) => {
    const currentAscCols = isAscCols;
    setIsAscCols((prev) => ({
      ...prev,
      [colName]: !prev[colName],
    }));
    if (
      isSortedByKey(
        data,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    ) {
      return;
    }
    setData(
      sortByKey(
        data,
        colName,
        !currentAscCols[colName] ? SORT_ORDER.ASC : SORT_ORDER.DESC
      )
    );
  };

  const renderAdditionalHeader = useMemo(() => {
    const totalCost = 1117078;
    return (
      <div className="flex justify-between items-end">
        {/* Total Cost Display */}
        <div className="flex min-w-[160px] flex-col gap-[1rem]">
          <div className="text-[#121212] text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.28px]">
            Total Cost
          </div>
          <div className="text-[#121212] text-[2.75rem] font-bold leading-[3rem] tracking-[-1.76px]">
            ${formatNumber(totalCost)}
          </div>
        </div>

        {/* Custom Legend */}
        <div className="flex gap-[1rem] min-w-[160px]">
          {coloredDatasets.map((dataset, index) => (
            <div
              key={index}
              className={`flex items-center cursor-pointer gap-[4px]`}
            >
              <div
                className="w-[12px] h-[12px] rounded-full"
                style={{ backgroundColor: dataset.borderColor }}
              />
              <span className="text-[#121212] text-[0.875rem] font-[450] leading-[1.25rem] tracking-[-0.28px]">
                {dataset.label}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }, [coloredDatasets]);

  return (
    <div className="overflow-hidden 2xl:px-16 sm:px-[48px] relative min-h-[calc(100vh-70px-16px)] flex flex-col">
      <div className="py-[2.5rem] flex gap-[1rem]">
        <img
          src={backIcon}
          alt="back-button"
          className="cursor-pointer"
          onClick={() => navigate(URL_DASHBOARD)}
        />
        <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
          Overall Portfolio
        </span>
      </div>
      <div className="flex 2xl:gap-[1.5rem] sm:gap-[1.25rem] w-full">
        <div className="flex flex-col w-full 2xl:gap-[1.5rem] sm:gap-[1.25rem]">
          <LineChart
            title="Activity Report"
            datasets={coloredDatasets}
            labels={labels}
            displayLegend={false}
            additionalHeader={renderAdditionalHeader}
          />
          {/* Portfolio Overview */}
          <CommonTable
            minWidth={teamReportCols.minWidth}
            columns={teamReportCols.columns({ handleSort, isAscCols })}
            rowsData={mockData}
            label="Team Report List"
          />
        </div>
        {/* team member */}
        <TeamMember />
      </div>
      <div className="min-h-[16px]"></div>
    </div>
  );
}
