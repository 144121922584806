export default function GroupTable({ groups, config, label }) {
  return (
    <>
      <div className="bg-white p-[1.5rem] border-[1px] border-solid rounded-[20px] flex flex-col">
        <span className="leading-[1.75rem] tracking-[-0.6px] font-[700] text-[1.125rem] text-black">
          {label}
        </span>
        <div className="overflow-x-auto">
          <table
            className="table-fixed"
            style={{
              minWidth: config.minWidth,
            }}
          >
            <thead>
              <tr className="border-b-[1px] border-b-card-on-surface">
                {config.renderHeader()}
              </tr>
            </thead>
            <tbody>
              {groups.map((group, index) => (
                <>
                  <tr>{config.renderTitle(group.groupTitle, index)}</tr>
                  {group.data.map((item) => (
                    <tr>{config.renderRow(item)}</tr>
                  ))}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
