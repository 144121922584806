import { useEffect, useMemo, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ResizeIcon } from "../../assets/icons/icon-resize.svg";
import backIcon from "../../assets/icons/icon_back.svg";
import {
  DOCUMENT_TYPE_ENUM,
  DOCUMENT_TYPE_UPLOADED,
  MODE,
  TOAST_TYPE,
} from "../../constants";
import { DEVICE_WIDTH } from "../../constants/dimension";
import { FORMULA_GROUPING } from "../../constants/formula";
import {
  API_GET_APPLICATION_FILE,
  API_GET_APPLICATION_OCR,
  API_GET_COMPANIES,
} from "../../constants/url";
import { useApplicationContext } from "../../context/Application";
import { useToaster } from "../../context/Toaster";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FinancialSpreadingValidateTable from "../../pages/FinancialSpreadingUploadValidate/FinancialSpreadingValidateTable";
import debounce from "../../utils/api/debounce";
import generateHighlightDiv from "../../utils/financialSpreading/generateHighlightDiv";
import calculateUploadedDocuments from "../../utils/formula/calculateUploadedDocuments";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";
import generatePeriodString from "../../utils/string/generatePeriodString";
import Dropdowns from "../Dropdowns";
import Preview from "../Preview";
import Tabs from "../Tabs";
import useAuthenticatedFetch from "../../hooks/useAuthenticatedFetch";

const INITIAL_CONFIRM_STATE = DOCUMENT_TYPE_UPLOADED.reduce(
  (finalConfirmState, { key }) => {
    const confirmState = {};
    const documentTypeGroups = FORMULA_GROUPING[key];
    documentTypeGroups.forEach(({ summary = [], name }) => {
      const groupConfirmState = confirmState[name] || {};
      summary.forEach(({ displayCode }) => {
        groupConfirmState[displayCode] = true;
      });

      confirmState[name] = groupConfirmState;
    });

    return {
      ...finalConfirmState,
      [key]: confirmState,
    };
  },
  {}
);

export default function EditFinancialResult({
  validateResult = false,
  client,
  handleBack,
}) {
  const tableRef = useRef(null);
  const authenticatedFetch = useAuthenticatedFetch()

  const [selectedApplication, setSelectedApplication] = useState(0);

  const documentListsDropdown =
    client.applications?.map(({ metadata, ...data }, index) => {
      const fiscalPeriodOrders = Object.values(metadata).reduce(
        (currentFiscalPeriodOrders, { fiscal_period_order = [] }) => {
          return [...currentFiscalPeriodOrders, ...fiscal_period_order];
        },
        []
      );

      const largestExtractedFiscalPeriod = fiscalPeriodOrders.reduce(
        (largestExtractedFiscalPeriod, currentFiscalPeriodString) => {
          const currentExtractedFiscalPeriod = extractPeriodAndDate(
            currentFiscalPeriodString
          );

          const { period, month, year } = largestExtractedFiscalPeriod;

          if (!period || !month || !year) {
            return currentExtractedFiscalPeriod;
          }

          if (currentExtractedFiscalPeriod.period > period) {
            return currentExtractedFiscalPeriod;
          }

          if (currentExtractedFiscalPeriod.period < period) {
            return largestExtractedFiscalPeriod;
          }

          if (currentExtractedFiscalPeriod.year > year) {
            return currentExtractedFiscalPeriod;
          }

          if (currentExtractedFiscalPeriod.year < year) {
            return largestExtractedFiscalPeriod;
          }

          if (currentExtractedFiscalPeriod.month > month) {
            return currentExtractedFiscalPeriod;
          }

          if (currentExtractedFiscalPeriod.month < month) {
            return largestExtractedFiscalPeriod;
          }

          return largestExtractedFiscalPeriod;
        },
        {}
      );

      const { year, period, monthInt } = largestExtractedFiscalPeriod;

      const fileName = `${client.company_name}_${year}${String(
        monthInt
      ).padStart(2, "0")}_${period}M_Financial_Statement.pdf`;

      return {
        value: index,
        label: fileName,
      };
    }) || [];

  const { company_id } = useParams();
  const { showToast } = useToaster();
  const navigate = useNavigate();
  const location = useLocation();
  const [editingRows, setEditingRows] = useState({});
  const abortControllerRef = useRef(null);
  const ocrDataRef = useRef({})
  const ocrHighlightsRef = useRef([])
  const isFetchingOcrRef = useRef({})

  const { getStandardAccountOptions } = useApplicationContext();
  const { width } = useWindowDimensions();

  const [activeTab, setActiveTab] = useState(
    DOCUMENT_TYPE_ENUM.INCOME_STATEMENT
  );
  const [triggerReRender, setTriggerRender] = useState(1);
  const [data, setData] = useState(client.data);
  const [pendingSave, setPendingSave] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const fileRef = useRef(null);
  const [aggregatedTextContents, setAggregatedTextContents] = useState([]);
  const highlightsRef = useRef([]);
  const [selectedRow, setSelectedRow] = useState();
  const [fileHeight, setFileHeight] = useState();
  const [confirmState, setConfirmState] = useState(INITIAL_CONFIRM_STATE);
  const [tabsLabel, setTabsLabel] = useState(DOCUMENT_TYPE_UPLOADED);

  const [isScroll, setIsScroll] = useState(false);
  const [metadata, setMetadata] = useState(client.metadata);
  const [applicationMetadata, setApplicationMetadata] = useState({});

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeTab]);

  useEffect(() => {
    const currentApplication = client.applications[selectedApplication];
    setApplicationMetadata(currentApplication?.metadata);
  }, [selectedApplication, client]);

  const handleResetConfirm = (documentType, groupName) => {
    setConfirmState((currentConfirmState) => {
      const documentTypeConfirmState = confirmState[documentType] || {};

      return {
        ...currentConfirmState,
        [documentType]: {
          ...documentTypeConfirmState,
          [groupName]: {},
        },
      };
    });
  };

  useEffect(() => {
    const updatedTabsLabel = DOCUMENT_TYPE_UPLOADED.map((item) => ({
      ...item,
      isConfirmed: Object.values(confirmState[item.key]).every((section) =>
        Object.values(section).every((confirmed) => confirmed)
      ),
    }));
    setTabsLabel(updatedTabsLabel);
  }, [confirmState]);

  const handleConfirmStateUpdate = (documentType, groupName, displayCode) => {
    setConfirmState((currentConfirmState) => {
      const documentTypeConfirmState = confirmState[documentType] || {};
      const groupConfirmState = documentTypeConfirmState[groupName] || {};
      const allDocumentTypeConfirmed = Object.values(
        confirmState[documentType]
      ).every((section) => Object.values(section)[0]);
      if (allDocumentTypeConfirmed) {
        setTabsLabel((prev) => {
          const updatedLabel = prev.map((item) => {
            if (item.key === documentType) {
              return {
                ...item,
                isConfirmed: true,
              };
            }
            return item;
          });
          return updatedLabel;
        });
      }

      return {
        ...currentConfirmState,
        [documentType]: {
          ...documentTypeConfirmState,
          [groupName]: {
            ...groupConfirmState,
            [displayCode]: true,
          },
        },
      };
    });
  };

  useEffect(() => {
    const selectedRef = highlightsRef.current[selectedRow] || ocrHighlightsRef.current[selectedRow];
    highlightsRef.current.forEach((ref) => {
      ref.style.display = "none";
    });
    ocrHighlightsRef.current.forEach((ref) => {
      ref.style.display = "none";
    });

    if (!selectedRef) {
      return;
    }
    selectedRef.style.display = "";
  }, [selectedRow]);

  useEffect(() => {
    const applicationId =
      client.applications[selectedApplication]?.application_id;

    if (!applicationId) {
      return;
    }

    const getApplicationFileApi = API_GET_APPLICATION_FILE.replace(
      ":id",
      applicationId
    );
    authenticatedFetch(getApplicationFileApi, {
      withCredentials: true,
      responseType: "blob",
      method: 'GET',
    })
      .then((fileBlob) => {
        const fileType = fileBlob.type;
        const createdFileUrl = URL.createObjectURL(fileBlob);
        setFileUrl(createdFileUrl);
        setFileType(fileType);
      })
      .catch((err) => {
        console.error("error fetching application file:", err);
      });
  }, [client, selectedApplication]);

  const currentDocumentType = activeTab;
  const documentMetadata = metadata[currentDocumentType] || {};
  const documentApplicationMetadata =
    applicationMetadata[currentDocumentType] || {};
  const { fiscal_period_order = [] } = documentMetadata;
  const { page_number } = documentApplicationMetadata;

  
  const [currentScrolledPage, setCurrentScrolledPage] = useState(page_number)

  const currentPageOcrRowData = ocrDataRef.current[currentScrolledPage]

  useEffect(() => {
    if (!data.length || !currentPageOcrRowData?.length) {
      return;
    }

    ocrHighlightsRef.current.forEach((ref) => ref.remove());
    ocrHighlightsRef.current = [];
    setSelectedRow(null);
    let lastMatchedIndex = 0;

    const currentPageDiv = fileRef.current.getPages(currentScrolledPage - 1)

    const parentElement = currentPageDiv.querySelector('.rpv-core__text-layer')

    if (!parentElement) {
      return
    }

    const parentWidth = parentElement.clientWidth;
    const parentHeight = parentElement.clientHeight;

    data.forEach(({ client_account_name, document_type }, dataIndex) => {
      if (document_type !== currentDocumentType) {
        return;
      }
      for (let i = lastMatchedIndex; i < currentPageOcrRowData.length; i++) {
            const { constructedStr, minX, minY, maxX, maxY } = currentPageOcrRowData[i];

        const lowerClientAccountName = client_account_name.toLowerCase().trim();
        const lowerConstructedStr = constructedStr.toLowerCase().trim();

        // client account name equals, means single line and found
        if (lowerConstructedStr.startsWith(lowerClientAccountName)) {
          const left = minX * parentWidth
          const top = minY * parentHeight
          const width = (maxX - minX) * parentWidth
          const height = (maxY - minY) * parentHeight
          const highlightDiv = generateHighlightDiv({
            width,
            height,
            left,
            top,
          }) 
          ocrHighlightsRef.current[dataIndex] = highlightDiv;
          parentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;
          break;
        }


        // multi line client account name
        if (lowerClientAccountName.startsWith(lowerConstructedStr)) {
            let remainingCheckedStr = lowerClientAccountName
            .substring(lowerConstructedStr.length)
            .trim();

          const multilineOcrData = []
          for (let j = i + 1; j < currentPageOcrRowData.length; j++) {
            const { constructedStr } = currentPageOcrRowData[j];
            const lowerConstructedStr = constructedStr.toLowerCase().trim();

            if (
              !(
                lowerConstructedStr.startsWith(remainingCheckedStr) ||
                remainingCheckedStr.startsWith(lowerConstructedStr)
              )
            ) {
              i = j - 1;
              break;
            }

            multilineOcrData.push(currentPageOcrRowData[j])

            // last string
            if (lowerConstructedStr.startsWith(remainingCheckedStr)) {
              i = j;
              break;
            }
          }

          if (!multilineOcrData.length) {
            return
          }

          const { minX, minY } = multilineOcrData[0]
          const { maxX, maxY } = multilineOcrData[multilineOcrData.length - 1]

          const left = minX * parentWidth
          const top = minY * parentHeight
          const width = (maxX - minX) * parentWidth
          const height = (maxY - minY) * parentHeight
          const highlightDiv = generateHighlightDiv({
            width,
            height,
            left,
            top,
          }) 

          ocrHighlightsRef.current[dataIndex] = highlightDiv;
          parentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;

          break;
        }
      }
    });
  }, [currentPageOcrRowData, data]);

  const documentTypeGroups = FORMULA_GROUPING[currentDocumentType];

  useEffect(() => {
    if (triggerReRender && isFileLoaded) {
      setTimeout(() => {
          fileRef.current.scrollToPage(currentScrolledPage);
      }, 200);
    }
  }, [triggerReRender, isFileLoaded]);

  useEffect(() => {
    if (page_number && isFileLoaded) {
      fileRef.current.scrollToPage(page_number);
    }
  }, [page_number, isFileLoaded]);

  const handleSaveData = async (signal) => {
    const saveClientApi = `${API_GET_COMPANIES}/${company_id}`;

    const savedData = data.filter((_, index) => !editingRows[index]);

    authenticatedFetch(saveClientApi, {
      data: {
        ...client,
        data: savedData,
      },
      withCredentials: true,
      signal,
      method: 'PUT',
    })
   .catch((err) => {
        if (["AbortError", "CanceledError"].includes(err.name)) {
          return;
        }
        showToast(err.message, TOAST_TYPE.ERROR);
      });
  };

  const debouncedSave = debounce(handleSaveData, 500);

  useEffect(() => {
    if (pendingSave) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const controller = new AbortController();
      abortControllerRef.current = controller;

      debouncedSave(controller.signal);
      setPendingSave(false);
    }
  }, [pendingSave]);

  const handleSetEditingRows = (index, value) => {
    setEditingRows((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const addNewDataRow = (index, groupName) => {
    setData((currentData) => {
      const newData = [...currentData];
      const emptyRow = {
        client_account_name: "",
        tp_standard_account_name: "",
        account_code: "",
        document_type: currentDocumentType,
        amount: fiscal_period_order.reduce(
          (acc, period) => ({ ...acc, [period]: 0 }),
          {}
        ),
        add_time: Date.now(),
        groupName,
      };
      newData.splice(index + 1, 0, emptyRow);

      return newData;
    });

    setEditingRows((prev) => {
      const newEditingRows = Object.keys(prev).reduce(
        (newEditingRows, currentIndex) => {
          const currentEditRow = prev[currentIndex];

          const newIndex =
            currentIndex > index ? parseInt(currentIndex) + 1 : currentIndex;

          return {
            ...newEditingRows,
            [newIndex]: currentEditRow,
          };
        },
        {}
      );

      return {
        ...newEditingRows,
        [index + 1]: true,
      };
    });
  };

  const deleteRow = (index) => {
    setData((currentData) => currentData.filter((_, i) => i !== index));

    setEditingRows((prev) => {
      const newEditingRows = Object.keys(prev).reduce(
        (newEditingRows, currentIndex) => {
          const currentEditRow = prev[currentIndex];

          const newIndex =
            currentIndex > index ? parseInt(currentIndex) - 1 : currentIndex;

          return {
            ...newEditingRows,
            [newIndex]: currentEditRow,
          };
        },
        {}
      );

      return newEditingRows;
    });

    setPendingSave(Date.now());
  };

  const handleSaveRow = (index, newRowData) => {
    setData((currentData) => {
      const newData = [...currentData];

      newData[index] = newRowData;

      return newData;
    });

    handleSetEditingRows(index, undefined);

    setPendingSave(Date.now());
  };

  const handleSave = () => {
    setPendingSave(Date.now());
  };

  const standardAccountOptions = (
    getStandardAccountOptions(currentDocumentType) || []
  )?.map(({ account_code, tp_standard_account_name, groupName }) => ({
    value: account_code,
    label: tp_standard_account_name,
    groupName,
  }));

  const handleAmountKeyChange = (
    documentType,
    previousFiscalPeriod,
    newFiscalPeriod
  ) => {
    if (previousFiscalPeriod === newFiscalPeriod) {
      return;
    }

    let newFiscalPeriodExists = false;

    setData((currentData) => {
      const newData = [];
      for (let i = 0; i < currentData.length; i++) {
        const datum = currentData[i] || {};
        const { amount, document_type } = datum;

        if (document_type !== documentType) {
          newData.push(datum);
          continue;
        }

        if (newFiscalPeriod in amount) {
          newFiscalPeriodExists = true;
          break;
        }

        const newAmount = { ...amount };

        if (previousFiscalPeriod in newAmount) {
          newAmount[newFiscalPeriod] = newAmount[previousFiscalPeriod];
          delete newAmount[previousFiscalPeriod]; // Remove the old key
        }

        datum.amount = newAmount;
        newData.push(datum);
      }

      if (newFiscalPeriodExists) {
        return currentData;
      }

      return newData;
    });

    if (newFiscalPeriodExists) {
      showToast("Period already exists", TOAST_TYPE.ERROR);
      return;
    }

    setMetadata((currentMetadata) => {
      const { [documentType]: currentDocumentTypeMetadata = {} } =
        currentMetadata;
      const { fiscal_period_order = [] } = currentDocumentTypeMetadata;
      const newFiscalPeriodOrder = [...fiscal_period_order];

      for (let i = 0; i < fiscal_period_order.length; i++) {
        const currentFiscalPeriod = fiscal_period_order[i];
        if (currentFiscalPeriod === previousFiscalPeriod) {
          newFiscalPeriodOrder[i] = newFiscalPeriod;
          break;
        }
      }

      return {
        ...currentMetadata,
        [documentType]: {
          ...currentDocumentTypeMetadata,
          fiscal_period_order: newFiscalPeriodOrder,
        },
      };
    });

    setPendingSave(Date.now());
  };

  const onDateChange = (documentType, previousFiscalPeriod, month, year) => {
    const { period, audited } = extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(month, year, period, audited);

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
  };

  const onPeriodChange = (documentType, previousFiscalPeriod, period) => {
    const { monthInt, year, audited } =
      extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(
      monthInt,
      year,
      period,
      audited
    );

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
  };

  const onAuditedChange = (documentType, previousFiscalPeriod, audited) => {
    const { monthInt, year, period } =
      extractPeriodAndDate(previousFiscalPeriod);
    const newPeriodString = generatePeriodString(
      monthInt,
      year,
      period,
      audited
    );

    handleAmountKeyChange(documentType, previousFiscalPeriod, newPeriodString);
  };

  const calculatedUploadedDocuments = useMemo(
    () => calculateUploadedDocuments(metadata, data),
    [data, metadata]
  );

  const onInverseRow = (index) => {
    setData((currentData) => {
      if (!currentData[index]) {
        return currentData;
      }

      const newData = [...currentData];

      const { amount = {} } = newData[index];

      const newAmount = { ...amount };

      for (let periodString in newAmount) {
        const value = newAmount[periodString] || 0;

        newAmount[periodString] = value * -1;
      }

      newData[index] = {
        ...newData[index],
        amount: newAmount,
      };

      return newData;
    });

    setPendingSave(Date.now());
  };

  const aggregatePdfTextsByLine = (currentPageIndex, height) => {
    const result = [];
    let currentArray = [];
    let constructedStr = "";

    const currentPageDiv = fileRef.current.getPages(currentPageIndex)

    const textDivs = currentPageDiv.querySelectorAll(
      ".rpv-core__text-layer-text"
    );

    textDivs.forEach((element, index) => {
      const str = element.textContent;
      const isNewLine = element.tagName === "BR";
      if (isNewLine) {
        if (currentArray.length > 0) {
          result.push({
            constructedStr,
            content: currentArray,
            width,
            height,
          });
          currentArray = [];
          constructedStr = "";
        }
      } else {
        currentArray.push({ parentRef: textDivs[index] });
        constructedStr += str;
      }
    });

    setAggregatedTextContents(result);
  }


  const getOcrDataByPage = async (page_number) => {
    const currentPageOcrData = ocrDataRef.current[page_number] 

    if (ocrDataRef.current[page_number]) {
      return currentPageOcrData
    }

    if (isFetchingOcrRef.current[page_number])
      return

    isFetchingOcrRef.current[page_number] = true

    const applicationId = client.applications?.[selectedApplication].application_id
    
    if (!applicationId) {
      return
    }

    const getApplicationOcrApi = API_GET_APPLICATION_OCR.replace(':id', applicationId) + '/' + page_number
    return authenticatedFetch(getApplicationOcrApi).then(({ data: { pages }}) => {
      const { blocks = [] } = pages?.[0] || {}
      const { lines = [] } = blocks[0] || {}

      if (!lines?.length) {
        return
      }

      let linesMap = {}

      lines.forEach((linesData) => {
        const { words = [] } = linesData

        let constructedStr = ''
        let [[minX, minY], [maxX, maxY]] = words[0]?.geometry || []
        words.forEach(({ value, geometry }) => {
          constructedStr += (value + ' ')
          const [[x0, y0], [x1, y1]] = geometry;
          if (x0 < minX) minX = x0;
          if (y0 < minY) minY = y0;
          if (x1 > maxX) maxX = x1;
          if (y1 > maxY) maxY = y1;
        })

        const width = (maxX - minX) * 100;

        const linesMapKey = Math.ceil(minY * 100)

        const currentLinesMapArr = linesMap[linesMapKey] || []
        currentLinesMapArr.push({
          minX,
          maxX, 
          minY,
          maxY,
          width, 
          constructedStr,
        })

        linesMap[linesMapKey] = currentLinesMapArr
      })

      const rowData = Object.values(linesMap).map((rowData) => {
        let rowString = ''
        let { minX = Infinity, maxX = 0, minY, maxY } = rowData[0] || {}

        rowData.forEach(({ constructedStr, minX: rowMinX, maxX: rowMaxX }) => {
          rowString += (constructedStr + ' ')
          if (rowMinX < minX) {
            minX = rowMinX
          }

          if (rowMaxX > maxX) {
            maxX = rowMaxX
          }
        })

        return {
          constructedStr: rowString,
          minX,
          maxX,
          minY,
          maxY,
        }
      })

      ocrDataRef.current[page_number] = rowData
    }).catch((err) => {
      if (!err.message || ["AbortError", "CanceledError"].includes(err.name)) {
        return
      }
    })

  }

  const loadDataIntoPdf = (
    textContent,
    [, , width, height],
    currentPageIndex
  ) => {
    setFileHeight(height);
    setIsFileLoaded(true);
    setCurrentScrolledPage(currentPageIndex + 1)

    getOcrDataByPage(currentPageIndex + 1)
    
    setTimeout(() => aggregatePdfTextsByLine(currentPageIndex, height), 200)
  };


  const documentTypesStartingPages = Object.values(metadata).reduce((startingPagesStr, currentDocumentTypeMetadata) => {
    const { page_number } = currentDocumentTypeMetadata;

    if (!startingPagesStr) {
      return page_number
    }

    return startingPagesStr + ',' + page_number

  }, '')

  useEffect(() => {
    if (!documentTypesStartingPages) {
      return
    }

    const startingPages = documentTypesStartingPages.split(',')

    startingPages.forEach((page_number) => {
      getOcrDataByPage(page_number)
    })
  }, [documentTypesStartingPages])

  const handleScroll = () => {
    if (window?.scrollY > 400) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!data.length || !aggregatedTextContents.length) {
      return;
    }

    highlightsRef.current.forEach((ref) => ref.remove());
    highlightsRef.current = [];
    setSelectedRow(null);
    let lastMatchedIndex = 0;

    data.forEach(({ client_account_name, document_type }, dataIndex) => {
      if (document_type !== currentDocumentType) {
        return;
      }
      for (let i = lastMatchedIndex; i < aggregatedTextContents.length; i++) {
        const { constructedStr, content } = aggregatedTextContents[i];

        const lowerClientAccountName = client_account_name.toLowerCase().trim();
        const lowerConstructedStr = constructedStr.toLowerCase().trim();

        if (lowerConstructedStr.startsWith(lowerClientAccountName)) {
          const { parentRef: firstParentElement } = content[0];
          const { parentRef: lastParentElement } = content[content.length - 1];

          const { left, height } = firstParentElement.getBoundingClientRect();

          const { right } = lastParentElement.getBoundingClientRect();

          const parentTransform =
            window.getComputedStyle(firstParentElement).transform;

          let scaleX = 1;

          if (parentTransform !== "none") {
            const matrixValues = parentTransform.match(/matrix\(([^)]+)\)/);

            if (matrixValues) {
              const values = matrixValues[1].split(",");

              scaleX = parseFloat(values[0]);
            }
          }

          const highlightDiv = generateHighlightDiv({
            width: (right - left) / scaleX + 4,
            height,
          });

          highlightsRef.current[dataIndex] = highlightDiv;
          firstParentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;
          break;
        }

        // multi line client account name
        if (lowerClientAccountName.startsWith(lowerConstructedStr)) {
          const { parentRef: firstParentElement } = content[0];
          const { parentRef: lastParentElement } = content[content.length - 1];
          const firstParentElements = [firstParentElement];
          const lastParentElements = [lastParentElement];
          let remainingCheckedStr = lowerClientAccountName
            .substring(lowerConstructedStr.length)
            .trim();
          for (let j = i + 1; j < aggregatedTextContents.length; j++) {
            const { constructedStr, content } = aggregatedTextContents[j];
            const lowerConstructedStr = constructedStr.toLowerCase().trim();

            if (
              !(
                lowerConstructedStr.startsWith(remainingCheckedStr) ||
                remainingCheckedStr.startsWith(lowerConstructedStr)
              )
            ) {
              i = j - 1;
              break;
            }

            const { parentRef: firstParentElement } = content[0];
            const { parentRef: lastParentElement } =
              content[content.length - 1];
            firstParentElements.push(firstParentElement);
            lastParentElements.push(lastParentElement);

            // last string
            if (lowerConstructedStr.startsWith(remainingCheckedStr)) {
              i = j;
              break;
            }
          }
          const { left, top } = firstParentElement.getBoundingClientRect();

          const { bottom: bottomFirstParentElementBottom } =
            firstParentElements[
              firstParentElements.length - 1
            ].getBoundingClientRect();
          const height = bottomFirstParentElementBottom - top;

          const right = lastParentElements.reduce(
            (right, currentLastParentElement) => {
              const { right: currentRight } =
                currentLastParentElement.getBoundingClientRect();
              if (currentRight > right) {
                return currentRight;
              }

              return right;
            },
            0
          );

          const parentTransform =
            window.getComputedStyle(firstParentElement).transform;

          let scaleX = 1;

          if (parentTransform !== "none") {
            const matrixValues = parentTransform.match(/matrix\(([^)]+)\)/);

            if (matrixValues) {
              const values = matrixValues[1].split(",");

              scaleX = parseFloat(values[0]);
            }
          }

          const highlightDiv = generateHighlightDiv({
            width: (right - left) / scaleX + 4,
            height,
          });

          highlightsRef.current[dataIndex] = highlightDiv;
          firstParentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;

          break;
        }
      }
    });
  }, [aggregatedTextContents, data]);

  // width for client account name / tp standard account name
  const namesWidth = width >= DEVICE_WIDTH.DESKTOP ? 200 : 120;
  const nonNamesWidth = 100;
  const minWidth =
    namesWidth * 2 + (fiscal_period_order.length + 1) * nonNamesWidth;

  const renderButton = useMemo(() => {
    const isNextButtonDisabled = DOCUMENT_TYPE_UPLOADED.some(({ key }) => {
      const documentTypeGroups = FORMULA_GROUPING[key];
      const documentTypeConfirmState = confirmState[key] || {};

      return documentTypeGroups.some(({ summary = [], name }) => {
        const groupConfirmState = documentTypeConfirmState[name] || {};
        return summary.some(({ displayCode }) => {
          return !groupConfirmState[displayCode];
        });
      });
    });

    return (
      <div className="flex gap-[1.25rem] transition-all duration-500">
        <button
          className="w-[4.5rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem] default border-[1px] border-neutral-medium font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] flex justify-center items-center"
          onClick={handleBack}
        >
          Previous
        </button>
        <button
          className="w-[4.5rem] !h-[2rem] !py-[0.25rem] !px-[0.5rem] primary font-[600] leading-[1.5rem] tracking-[-0.48px] text-[0.875rem] text-white flex justify-center items-center"
          disabled={isNextButtonDisabled}
          onClick={() => navigate(`${location.pathname}?mode=${MODE.VIEW}`)}
        >
          Next
        </button>
      </div>
    );
  }, [confirmState, navigate]);

  return (
    <div
      className={`w-full max-w-default sm:px-[48px] 2xl:px-[64px] relative min-h-[calc(100vh-70px)] flex flex-col transition-all duration-300`}
    >
      <div className="py-[2.5rem] flex-col gap-[1rem]">
        <div className="pb-[1rem] flex justify-between">
          <div className="flex gap-[1rem] flex-1 items-center">
            <button onClick={handleBack}>
              <img
                src={backIcon}
                alt="back-button"
                className="cursor-pointer"
              />
            </button>
            <span className="text-gradient-aurora-blue text-[2rem] font-[700] leading-[2.5rem] tracking-[-0.96px]">
              {validateResult
                ? "Financial Spreading Confirmation"
                : "Edit Financial Spreading"}
            </span>
          </div>
          {!isScroll && renderButton}
        </div>

        <div className="bg-[#fff] rounded-[20px] flex flex-col gap-[1.5rem] p-[2rem]">
          <div className="flex flex-col gap-[1rem]">
            <div className="text-[#000] text-[1.25rem] font-bold leading-[1.75rem] tracking-[-0.6px]">
              Original Document
            </div>
            <ul className="list-disc pl-[1.625rem] text-[1rem] leading-[1.5rem] tracking-[-0.54px] font-[500] max-w-[1187px] w-full">
              <li>
                Select the Original Document to be displayed on the left, and
                make corrections to the mapped accounts, figures or fiscal
                periods on the right.
              </li>
              <li>
                After confirming the calculated totals on all available tabs,
                click "Next" to proceed.
              </li>
            </ul>
          </div>
          <div className="flex items-center gap-[1.5rem]">
            <Dropdowns
              label="Document List"
              className="flex-1"
              defaultValue={documentListsDropdown[selectedApplication].value}
              data={documentListsDropdown}
              onChange={setSelectedApplication}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-[2rem] w-full">
        <div className="max-w-[1792px] w-full flex flex-col gap-[1.5rem]">
          <div
            className={`flex justify-between align-center ${
              isScroll
                ? "sticky bg-[#fff] top-0 z-[20] py-[0.75rem] px-[0.5rem] rounded-[20px] pb-0"
                : ""
            }`}
          >
            <Tabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabsLabel={tabsLabel}
              wrapperClassname={
                isScroll ? "py-[0.75rem] px-[0.5rem] !border-none" : ""
              }
            />
            {isScroll && renderButton}
          </div>
          <PanelGroup direction="horizontal" className="gap-[2px] !overflow-visible h-full">
            <Panel
              defaultSizePercentage={40}
              maxSize={50}
              className="flex flex-col gap-[1.5rem] min-w-[430px] sticky top-0 self-start"
            >
              <div className="flex gap-[1.5rem] items-center">
                <span className="text-[1.125rem] font-[700] leading-[2rem] tracking-[-0.72px] ">
                  Original Document
                </span>
              </div>
              <div
                key={triggerReRender}
                className="bg-white p-[1.5rem] !pr-0 border-[1px] border-solid rounded-[20px] flex flex-col w-full aspect-[4/3]"
              >
                <Preview
                  url={fileUrl}
                  type={fileType}
                  onPageLoad={loadDataIntoPdf}
                  ref={fileRef}
                  initialPage={page_number}
                />
              </div>
            </Panel>
            <PanelResizeHandle
              className="relative w-[24px] h-[24px] flex items-center justify-center"
              onDragging={(isDragging) => {
                if (!isDragging) {
                  setTriggerRender((prev) => prev + 1);
                }
              }}
              style={{
                top: (fileHeight ?? 700 - 50) / 2,
              }}
            >
              <ResizeIcon color="black" />
            </PanelResizeHandle>
            <Panel
              defaultSizePercentage={50}
              minSize={50}
              className="flex flex-col overflow-x-auto hideScrollbar"
              style={{
                width: minWidth,
              }}
            >
              <div ref={tableRef} className="" />
              <div className="text-[1.125rem] mb-[1.5rem] font-[700] leading-[2rem] tracking-[-0.72px] h-[2rem]">
                Financial Mapping
              </div>
              <div
                className={`flex-col gap-[1.5rem] flex`}
                style={{
                  overflow: "auto",
                }}
              >
                {documentTypeGroups.map(
                  ({ name, summary, content, canInverseValue }, index) => {
                    const groupConfirmState =
                      confirmState[currentDocumentType][name];

                    return (
                      <FinancialSpreadingValidateTable
                        minWidth={minWidth}
                        name={name}
                        fiscalPeriods={fiscal_period_order}
                        data={data}
                        documentType={currentDocumentType}
                        standardAccountOptions={standardAccountOptions}
                        onAddRow={addNewDataRow}
                        onDeleteRow={deleteRow}
                        onSaveRow={handleSaveRow}
                        canChangeDate={index === 0}
                        onDateChange={onDateChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onPeriodChange={onPeriodChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onAuditedChange={onAuditedChange.bind(
                          null,
                          currentDocumentType
                        )}
                        onSave={handleSave}
                        summary={summary}
                        content={content}
                        calculatedData={calculatedUploadedDocuments}
                        onInvertClick={canInverseValue && onInverseRow}
                        onRowHover={setSelectedRow}
                        confirmState={groupConfirmState}
                        onConfirm={handleConfirmStateUpdate}
                        onResetConfirm={handleResetConfirm}
                        showMonth={[
                          DOCUMENT_TYPE_ENUM.INCOME_STATEMENT,
                          DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT,
                        ].includes(activeTab)}
                        isLastTable={index === documentTypeGroups.length - 1}
                        editingRows={editingRows}
                        setEditingRows={handleSetEditingRows}
                      />
                    );
                  }
                )}
              </div>
            </Panel>
          </PanelGroup>
        </div>
      </div>
      <div className="mb-[2rem]"></div>
    </div>
  );
}
