import iconEdit from "../../assets/icons/icon_edit_table.svg";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import Tooltip from "../../components/Tooltip";
import defaultAvatarImage from "../../assets/images/default_avatar.png";
import SortIconTable from "../../components/SortIconTable";
import { ROLES } from "../../constants";
import { _capitalize } from "chart.js/helpers";

export const mockData = [
  {
    id: 1,
    name: "Annette Black",
    role: ROLES.MANAGER,
    avatarName: {
      avatar: defaultAvatarImage,
      name: "Annette Black",
    },
    email: "1michael.mitc@example.com",
  },
  {
    id: 1,
    name: "TAnnette Black",
    role: ROLES.MANAGER,
    avatarName: {
      avatar: defaultAvatarImage,
      name: "TAnnette Black",
    },
    email: "2michael.mitc@example.com",
  },
  {
    id: 1,
    name: "KKKAnnette Black",
    role: "Team member",
    avatarName: {
      avatar: defaultAvatarImage,
      name: "KKKAnnette Black",
    },
    email: "4michael.mitc@example.com",
  },
];

export const USER_COLS = {
  NAME: "name",
  ROLE: "role",
  EMAIL: "email",
};

export const userCols = {
  minWidth: 320 + 240 + 120,
  columns: ({ handleEdit, handleSort, isAscCols, toggleDeleteModal }) => [
    {
      key: "avatarName",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[2.5rem] bg-primary-50 rounded-tl-[8px] min-w-[320px] sticky left-0"
          onClick={() => handleSort(USER_COLS.NAME)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center w-full border-r-card-on-surface">
            Member
            <SortIconTable isAsc={isAscCols[USER_COLS.NAME]} />
          </div>
        </th>
      ),
      renderRow: ({ avatarName }) => (
        <td className="bg-white !h-[51px] !p-0 min-w-[320px] flex justify-center items-center sticky left-0">
          <img
            src={defaultAvatarImage}
            alt="Profile"
            width={32}
            height={32}
            className="w-[32px] h-[32px] rounded-full cursor-pointer"
          />
          <div className="!px-[0.75rem] h-full align-middle !text-[0.8125rem] items-center flex justify-start text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full border-r-card-on-surface">
            {avatarName.name}
          </div>
        </td>
      ),
    },
    {
      key: "role",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[2.5rem] bg-primary-50 text-Gray-500 w-[240px]"
          onClick={() => handleSort(USER_COLS.ROLE)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center w-full border-r-card-on-surface">
            Role
            <SortIconTable isAsc={isAscCols[USER_COLS.ROLE]} />
          </div>
        </th>
      ),
      renderRow: ({ role }) => (
        <td className="bg-white !h-[51px] !p-0 w-[240px]">
          <div
            className={`h-[24px] p-[8px] flex justify-center items-center gap-[4px] rounded-[4px] ${
              role === ROLES.MANAGER
                ? "bg-[#DBE8FE] w-[73px]"
                : "bg-[#E2F7E1] w-[106px]"
            }`}
          >
            <span
              className={`text-[0.875rem] font-medium leading-[1.25rem] tracking-[-0.28px] ${
                role === ROLES.MANAGER ? "text-[#1E478A]" : "text-[#20481F] "
              }`}
            >
              {role === ROLES.MANAGER ? "Manager" : "Team member"}
            </span>
          </div>
        </td>
      ),
    },
    {
      key: "email",
      renderHeader: () => (
        <th
          className="!p-[0] !h-[2.5rem] bg-primary-50 text-Gray-500 w-[240px]"
          onClick={() => handleSort(USER_COLS.EMAIL)}
        >
          <div className="text-Gray-500 h-full !px-[0.75rem] text-[0.8125rem] font-[600] leading-[1rem] flex items-center w-full border-r-card-on-surface">
            Email
            <SortIconTable isAsc={isAscCols[USER_COLS.EMAIL]} />
          </div>
        </th>
      ),
      renderRow: ({ email }) => (
        <td className="bg-white !h-[51px] !p-0 w-[240px]">
          <div className="!px-[9.5px] !text-[0.8125rem] flex text-neutral-strong leading-[1.25rem] tracking-[-0.28px] font-[400] w-full">
            {email}
          </div>
        </td>
      ),
    },
    {
      key: "action",
      renderHeader: () => (
        <th className="!p-[0] !h-[2.5rem] min-w-[180px] text-Gray-500 text-[0.75rem] font-[600] leading-[1rem] bg-primary-50 rounded-tr-[8px] w-[112px] sticky right-0">
          <div className="flex justify-center">Actions</div>
        </th>
      ),
      renderRow: ({ index }) => (
        <td className="bg-white sticky right-0 min-w-[180px] !h-[51px] !py-0 !px-[0.75rem]">
          <div className="flex justify-center gap-[0.625rem] items-center">
            <Tooltip
              tooltip="Edit"
              tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[83px]"
            >
              <img
                src={iconEdit}
                className="w-[20px] cursor-pointer"
                alt="iconEdit"
                onClick={handleEdit}
              />
            </Tooltip>
            <Tooltip
              tooltip="Delete"
              tooltipClassname="flex justify-center items-center max-w-[240px] p-1.5 px-2.5 py-1 bg-white bg-opacity-80 rounded !max-w-[240px] !w-[85px] !ml-[-20px]"
              handleClick={() => {
                toggleDeleteModal();
              }}
            >
              <img
                src={iconTrash}
                className="w-[20px] cursor-pointer"
                alt="iconTrash"
              />
            </Tooltip>
          </div>
        </td>
      ),
    },
  ],
};
